import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';

import useMediaQuery from 'customHooks/useMediaQuery';
import Graphic from 'assets/sales-funnel-canvas.svg';
import Text from './text';

import { formatPercent, formatNumber } from '../helper';

function StagedCanvas({ data, loading }) {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isWideScreen = useMediaQuery('(min-width: 1280px)');

  const zoom = () => {
    if (isMobile) {
      return 0.5;
    }

    if (isWideScreen) {
      return 1.3;
    }

    return 1;
  };

  return (
    <Card className="relative flex justify-center items-center" style={{ height: isMobile ? 'unset' : '426px' }}>
      <div
        style={{
          backgroundImage: `url(${Graphic})`,
          backgroundRepeat: 'no-repeat',
          height: 300,
          backgroundPosition: 'center',
          backgroundSize: '100% 100%',
          width: 600,
          zoom: zoom(),
        }}
        className="relative top-0 left-0"
      >
        <div className="absolute flex flex-col justify-between w-24" style={{ left: 218, top: 68, height: 198 }}>
          <Text loading={loading} className="text-cf-16 font-bold text-white text-center">
            {data?.lead_count || 0}
          </Text>
          <Text loading={loading} className="text-cf-16 font-bold text-white text-center">
            {data?.acquisition_count || 0}
          </Text>
          <Text loading={loading} className="text-cf-16 font-bold text-white text-center">
            {data?.first_top_up_count || 0}
          </Text>
          <Text loading={loading} className="text-cf-16 font-bold text-white text-center">
            {data?.first_transaction_count || 0}
          </Text>
          <Text loading={loading} className="text-cf-16 font-bold text-white text-center">
            {data?.mau_blended_count || 0}
          </Text>
        </div>
        <div className="absolute flex flex-col justify-between" style={{ left: 434, top: 93, height: 149, width: 48 }}>
          <Text loading={loading} className="text-center">
            {formatPercent(data?.acquisition_conversion_rate)}
          </Text>
          <Text loading={loading} className="text-center">
            {formatPercent(data?.topup_conversion_rate)}
          </Text>
          <Text loading={loading} className="text-center">
            {formatPercent(data?.transaction_conversion_rate)}
          </Text>
          <Text loading={loading} className="text-center">
            {formatPercent(data?.mau_conversion_rate)}
          </Text>
        </div>
        <div className="absolute flex flex-col justify-between" style={{ left: 516, top: 71, height: 148, width: 72 }}>
          <Text loading={loading} className="text-center">
            {formatNumber(data?.average_acquisition_day)}
          </Text>
          <Text loading={loading} className="text-center">
            {formatNumber(data?.average_topup_day)}
          </Text>
          <Text loading={loading} className="text-center">
            {formatNumber(data?.average_transaction_day)}
          </Text>
          <Text loading={loading} className="text-center">
            {formatNumber(data?.average_mau_day)}
          </Text>
        </div>
      </div>
    </Card>
  );
}

StagedCanvas.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default StagedCanvas;
