export const AGENT_TYPE = [
  { key: '', label: 'All Type' },
  { key: 'agent', label: 'agent' },
  { key: 'lead', label: 'lead' },
];

export const GRANULARITY = [
  { key: 'monthly', label: 'Monthly' },
  { key: 'weekly', label: 'Weekly' },
  { key: 'daily', label: 'Daily' },
];

export const RANGE_TYPES = [
  { key: 'monthly', label: 'Monthly' },
  { key: 'quarterly', label: 'Quarterly' },
  { key: 'yearly', label: 'Yearly' },
];

export const MONTHS = [
  { key: '1', label: 'January' },
  { key: '2', label: 'February' },
  { key: '3', label: 'March' },
  { key: '4', label: 'April' },
  { key: '5', label: 'Mei' },
  { key: '6', label: 'June' },
  { key: '7', label: 'July' },
  { key: '8', label: 'August' },
  { key: '9', label: 'September' },
  { key: '10', label: 'October' },
  { key: '11', label: 'November' },
  { key: '12', label: 'December' },
];

export const QUARTERS = [
  { key: '1', label: 'Q1' },
  { key: '2', label: 'Q2' },
  { key: '3', label: 'Q3' },
  { key: '4', label: 'Q4' },
];

export const YEARS = new Array(10).fill().map((_, i) => {
  const currentDate = new Date().getFullYear();
  return { key: currentDate - i, label: `${currentDate - i}` };
});
