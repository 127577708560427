import React from 'react';
import PropTypes from 'prop-types';

export default function CustomizedLabel({ x, y, stroke, value }) {
  return (
    <text x={x} y={y} dy={-10} fill={stroke} fontSize={10} textAnchor="middle">
      {value}
    </text>
  );
}

CustomizedLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  stroke: PropTypes.string,
  value: PropTypes.number,
};

CustomizedLabel.defaultProps = {
  x: 0,
  y: 0,
  stroke: '',
  value: 0,
};
