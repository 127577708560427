import React from 'react'
import { Button } from 'components'
import { TableTitle } from 'components/table'

const getPositionAreaColumns = (goToDetail) => {
	return [
		{
			title: <TableTitle className="px-4">Area Preset Name</TableTitle>,
			dataIndex: 'name',
			key: 'name',
			width: '35%',
			render: (name) => (
				<div style={{ textTransform: 'uppercase' }} className="ml-4">
					{name}
				</div>
			),
		},
		{
			title: <TableTitle>Action</TableTitle>,
			dataIndex: 'id',
			key: 'id',
			width: 200,
			// align: 'center',
			render: (id, record) => (
				<Button
					size="small"
					// className="m-auto"
					onClick={() => goToDetail(id, record)}>
					VIEW DETAIL
				</Button>
			),
		},
	]
}

export default getPositionAreaColumns
