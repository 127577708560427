import React from 'react'
import { SquareImage, Button, Tag } from '../../../components'
import { COLOR_STATUS_MAPPED } from '../../../config/merchant-management'

export const columns = (onClickDetail) => [
	{
		title: <p className="font-bold text-cf-14 pt-6 pb-6 ml-4">Merchant Logo</p>,
		dataIndex: 'logo',
		key: 'logo',
		// align: 'center',
		render: (logo) => (
			<div className="ml-8">
				<SquareImage src={logo} />
			</div>
		),
	},
	{
		title: (
			<p className="font-bold text-cf-14 pt-6 pb-6 text-c4-gray">
				Merchant Name
			</p>
		),
		dataIndex: 'name',
		key: 'name',
	},
	{
		title: (
			<p className="font-bold text-cf-14 pt-6 pb-6 text-c4-gray">
				Account Owner
			</p>
		),
		dataIndex: 'code',
		key: 'code',
		render: (_, rec) => <>{`${rec.owner_first_name} ${rec.owner_last_name}`}</>,
	},
	{
		title: <p className="font-bold text-cf-14 pt-6 pb-6 text-c4-gray">Email</p>,
		dataIndex: 'owner_email',
		key: 'owner_email',
	},
	{
		title: (
			<p className="font-bold text-cf-14 pt-6 pb-6 text-c4-gray">Status</p>
		),
		dataIndex: 'is_active',
		key: 'is_active',
		render: (status) => (
			<Tag color={COLOR_STATUS_MAPPED[status]} style={{ paddingTop: 2 }}>
				{status ? 'Active' : 'Deactive'}
			</Tag>
		),
	},
	{
		title: (
			<p className="font-bold text-cf-14 pt-6 pb-6 text-c4-gray">Action</p>
		),
		dataIndex: 'id',
		key: 'action',
		width: 200,
		align: 'center',
		render: (id) => (
			<Button size="small" className="m-auto" onClick={() => onClickDetail(id)}>
				View Detail
			</Button>
		),
	},
]
