import React from 'react';
import PropTypes from 'prop-types';
import { Tree } from 'antd';

const { TreeNode } = Tree;

function PermissionTree({ treeData, title, checkedKeys, onChange }) {
  const renderTreeNodes = (data) =>
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode
            title={<p className="font-bold">{item.title}</p>}
            key={item.key}
            dataRef={item}
            style={{ pageBreakInside: 'avoid', marginLeft: -24, marginBottom: 10 }}
          >
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode title={item.title} key={item.key} />;
    });

  return (
    <div>
      <p className="font-bold text-3xl mb-2">{title}</p>
      {treeData?.length > 0 && (
        <Tree
          checkable
          defaultExpandAll
          checkedKeys={checkedKeys}
          expandedKeys={treeData.map((parent) => parent.key)}
          onCheck={onChange}
          switcherIcon={<span> </span>}
          style={{ columns: '3', columnGap: 40 }}
        >
          {renderTreeNodes(treeData)}
        </Tree>
      )}
    </div>
  );
}

PermissionTree.propTypes = {
  treeData: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  checkedKeys: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PermissionTree;
