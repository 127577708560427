import React from 'react';
import { Card } from 'antd';
import Line from 'components/Chart/LineChart';
import useCharts from 'customHooks/useCharts';
import { kpiVisualizationApi } from 'api/reports';
import ChartTitle from '../../../shared/ChartTitle';

function TransactionAmount() {
  const { data, loading, startDate, endDate } = useCharts({ callApi: kpiVisualizationApi.getTransactionAmount });

  return (
    <Card className="rounded-2xl">
      <ChartTitle title="Transaction Amount" startDate={startDate} endDate={endDate} />
      <Line
        data={data}
        dataKey="period"
        loading={loading}
        xLabel="Transaction Date"
        yLabel="Transaction Amount"
        lines={[
          { type: 'linear', dataKey: 'recharge_amount', stroke: '#009696', withLabelList: false },
          { type: 'linear', dataKey: 'transfer_amount', stroke: '#DB5555', withLabelList: false },
        ]}
        legendOptions={{ visible: true }}
      />
    </Card>
  );
}

export default TransactionAmount;
