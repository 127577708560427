import React from 'react';
import { Button } from 'components';
import { TableTitle } from 'components/table';
import moment from 'moment';
import Tag from 'components/Tag/v2';
import { VISITATION_STATUS_ASSIGNEE_OPTIONS } from 'config/visit-assignment';

const FORMAT_TIME = 'DD MMM YYYY';

export const columns = (viewDetailAction) => {
  return [
    {
      title: (
        <TableTitle>
          <div className="pl-4">Visitor Name</div>
        </TableTitle>
      ),
      dataIndex: 'visitor_name',
      key: 'visitor_name',
      render: (data) => <div className="pl-4">{data || '-'}</div>,
    },
    {
      title: <TableTitle>Position</TableTitle>,
      dataIndex: 'visitor_position',
      key: 'visitor_position',
    },
    {
      title: <TableTitle>Number of Assignment</TableTitle>,
      dataIndex: 'assignment_count',
      key: 'assignment_count',
      align: 'center',
      render: (data) => <div>{data || '-'}</div>,
    },
    {
      title: <TableTitle>Visitation Period</TableTitle>,
      dataIndex: 'visitation_last_period',
      key: 'visitation_last_period',
      render: (date) => <div>{date ? moment(date).format(FORMAT_TIME) : '-'}</div>,
    },
    {
      title: <TableTitle>Progress Visited</TableTitle>,
      dataIndex: 'assignment_visited_count',
      key: 'assignment_visited_count',
      align: 'center',
      render: (data, recoed) => (
        <div>
          {recoed?.assignment_visited_count || 0}/{recoed?.assignment_count || 0}
        </div>
      ),
    },
    {
      title: <TableTitle>Visitation Status</TableTitle>,
      dataIndex: 'visitation_status',
      key: 'visitation_status',
      align: 'center',
      render: (code) => {
        const status = VISITATION_STATUS_ASSIGNEE_OPTIONS.find((opt) => opt.key === code);
        return (
          <Tag key={code} bgColor={status?.bgColor} textColor={status?.textColor}>
            {status?.label || '-'}
          </Tag>
        );
      },
    },
    {
      title: <TableTitle>Action</TableTitle>,
      key: 'action',
      align: 'center',
      render: (id, record) => (
        <div className="flex w-180px justify-center m-auto">
          <Button size="small" className="m-auto" onClick={() => viewDetailAction(id, record)}>
            View Detail
          </Button>
        </div>
      ),
    },
  ];
};

export default columns;
