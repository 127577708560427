import Rollbar from 'rollbar';
import { PUBLIC_URL } from './url';

const isProduction = process.env.REACT_APP_ROLLBAR_ENV === 'production';

export const rollbarConfig = () => {
  return {
    accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
    server: {
      root: PUBLIC_URL,
      branch: isProduction ? 'production' : 'master',
    },
    environment: process.env.REACT_APP_ROLLBAR_ENV || 'staging',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      client: {
        javascript: {
          code_version: process.env.REACT_APP_GIT_SHA,
        },
      },
    },
  };
};

export const rollbar = new Rollbar(rollbarConfig());
