import AccountManagementList from 'pages/account-management/list';
import CreateAccountManagement from 'pages/account-management/create';
import AccountManagementDetail from 'pages/account-management/detail';
import AcquisitionReviewDetail from 'pages/acquisition-review/detail';
import AcquisitionReviewList from 'pages/acquisition-review/list';
import MerchantManagementDetail from 'pages/merchant-management/detail';
import MerchantManagement from 'pages/merchant-management/list';
import PositionManagement from 'pages/position-management';
import VisitAssignment from 'pages/visit-assignment';
import AreaPresetDetail from 'pages/position-management/area/detail';
import PositionDetailPage from 'pages/position-management/position/detail';
import OtherReport from 'pages/reports/other';
import VisitationReport from 'pages/reports/visitation-report';
import KpiVisualizationReport from 'pages/reports/kpi-visualization';
import SalesFunnelReport from 'pages/reports/sales-funnel';
import UserDetailPage from 'pages/user-management/detail2';
import UserManagement from 'pages/user-management/list2';
import WelcomeDashboard from 'pages/dashboard';
import VisitAssignmentLeadsDetail from 'pages/visit-assignment/leads/detail';

import { MENU } from 'config/menu';
import { PAGE_EVENTS } from 'config/eventNames';
import { PERMISSION_MENU as M, PERMISSION_TYPES as T } from 'config/permissions';
import {
  ACQUISITION_REVIEW,
  MERCHANT_MANAGEMENT,
  ACCOUNT_MANAGEMENT,
  POSITION_MANAGEMENT,
  REPORTING_TOOLS,
  USER_MANAGEMENT,
  USER_MANAGEMENT_DETAIL,
  VISIT_ASSIGNMENT,
} from 'config/feature';
import VisitAssignmentAssigneeDetail from 'pages/visit-assignment/assignee/detail';

export const privateRoutes = [
  { path: MENU.DASHBOARD, component: WelcomeDashboard, pageEvent: '' },
  {
    path: MENU.ACQUISITION_REVIEW,
    component: AcquisitionReviewList,
    accessType: { menu: M.ACQUISITION_REVIEW, type: T.READ },
    pageEvent: PAGE_EVENTS.ACQUISITION_REVIEW.LIST,
    config: ACQUISITION_REVIEW,
  },
  {
    path: `${MENU.ACQUISITION_REVIEW}/:id`,
    component: AcquisitionReviewDetail,
    accessType: { menu: M.ACQUISITION_REVIEW, type: T.READ },
    pageEvent: PAGE_EVENTS.ACQUISITION_REVIEW.DETAIL,
    config: ACQUISITION_REVIEW,
  },
  {
    path: MENU.MERCHANT_MANAGEMENT_LIST,
    component: MerchantManagement,
    accessType: { menu: M.MERCHANT_MANAGEMENT, type: T.READ },
    pageEvent: PAGE_EVENTS.MERCHANT_MANAGEMENT.LIST,
    config: MERCHANT_MANAGEMENT,
  },
  {
    path: `${MENU.MERCHANT_MANAGEMENT_LIST}/:id`,
    component: MerchantManagementDetail,
    accessType: { menu: M.MERCHANT_MANAGEMENT, type: T.READ },
    pageEvent: PAGE_EVENTS.MERCHANT_MANAGEMENT.DETAIL,
    config: MERCHANT_MANAGEMENT,
  },
  {
    path: MENU.USER_MANAGEMENT,
    component: UserManagement,
    accessType: { menu: M.USER_MANAGEMENT, type: T.READ },
    pageEvent: PAGE_EVENTS.USER_MANAGEMENT.LIST,
    config: USER_MANAGEMENT,
  },
  {
    path: `${MENU.USER_MANAGEMENT}/:id`,
    component: UserDetailPage,
    accessType: { menu: M.USER_MANAGEMENT, type: T.READ },
    pageEvent: PAGE_EVENTS.USER_MANAGEMENT.DETAIL,
    config: USER_MANAGEMENT_DETAIL,
  },
  {
    path: `${MENU.POSITION_MANAGEMENT}`,
    redirectPath: `${MENU.POSITION_MANAGEMENT}/position`,
    pageEvent: '',
    config: POSITION_MANAGEMENT,
  },
  {
    path: `${MENU.POSITION_MANAGEMENT}/:tab`,
    component: PositionManagement,
    accessType: { menu: M.POSITION_MANAGEMENT, type: T.READ },
    pageEvent: ({ tab }) => (tab === 'position' ? PAGE_EVENTS.POSITION_MANAGEMENT.LIST : PAGE_EVENTS.AREA_PRESET.LIST),
    config: POSITION_MANAGEMENT,
  },
  {
    path: `${MENU.POSITION_MANAGEMENT}/position/:id`,
    component: PositionDetailPage,
    accessType: { menu: M.POSITION_MANAGEMENT, type: T.READ },
    pageEvent: PAGE_EVENTS.POSITION_MANAGEMENT.DETAIL,
    config: POSITION_MANAGEMENT,
  },
  {
    path: `${MENU.POSITION_MANAGEMENT}/area-presets/:id`,
    component: AreaPresetDetail,
    accessType: { menu: M.POSITION_MANAGEMENT, type: T.READ },
    pageEvent: PAGE_EVENTS.AREA_PRESET.DETAIL,
    config: POSITION_MANAGEMENT,
  },
  {
    path: `${MENU.VISIT_ASSIGNMENT}`,
    component: VisitAssignment,
    accessType: { menu: M.ACQUISITION_REVIEW, type: T.READ }, // change this later
    pageEvent: PAGE_EVENTS.VISIT_ASSIGNMENT_LEADS.LIST,
    config: VISIT_ASSIGNMENT,
  },
  {
    path: `${MENU.VISIT_ASSIGNMENT}/:tab`,
    component: VisitAssignment,
    accessType: { menu: M.ACQUISITION_REVIEW, type: T.READ },
    pageEvent: ({ tab }) => (tab === 'leads' ? PAGE_EVENTS.VISIT_ASSIGNMENT_LEADS.LIST : PAGE_EVENTS.VISIT_ASSIGNMENT_ASSIGNEE.LIST),
    config: VISIT_ASSIGNMENT,
  },
  {
    path: `${MENU.VISIT_ASSIGNMENT}/leads/:id`,
    component: VisitAssignmentLeadsDetail,
    accessType: { menu: M.ACQUISITION_REVIEW, type: T.READ }, // change this later
    pageEvent: PAGE_EVENTS.VISIT_ASSIGNMENT_LEADS.LIST,
    config: VISIT_ASSIGNMENT,
  },
  {
    path: `${MENU.VISIT_ASSIGNMENT}/assignee/:visitorId`,
    component: VisitAssignmentAssigneeDetail,
    accessType: { menu: M.ACQUISITION_REVIEW, type: T.READ }, // change this later
    pageEvent: PAGE_EVENTS.VISIT_ASSIGNMENT_LEADS.LIST,
    config: VISIT_ASSIGNMENT,
  },
  {
    path: MENU.ACCOUNT_MANAGEMENT,
    component: AccountManagementList,
    accessType: { menu: M.ACCOUNT_MANAGEMENT, type: T.READ },
    pageEvent: PAGE_EVENTS.ACCOUNT_MANAGEMENT.LIST,
    config: ACCOUNT_MANAGEMENT,
  },
  {
    path: `${MENU.ACCOUNT_MANAGEMENT}/role/create`,
    component: CreateAccountManagement,
    accessType: { menu: M.ACCOUNT_MANAGEMENT, type: T.READ },
    pageEvent: PAGE_EVENTS.ACCOUNT_MANAGEMENT.CREATE,
    config: ACCOUNT_MANAGEMENT,
  },
  {
    path: `${MENU.ACCOUNT_MANAGEMENT}/role/edit/:id`,
    component: CreateAccountManagement,
    accessType: { menu: M.ACCOUNT_MANAGEMENT, type: T.READ },
    config: ACCOUNT_MANAGEMENT,
  },
  {
    path: `${MENU.ACCOUNT_MANAGEMENT}/role/:id`,
    component: AccountManagementDetail,
    accessType: { menu: M.ACCOUNT_MANAGEMENT, type: T.READ },
    pageEvent: PAGE_EVENTS.ACCOUNT_MANAGEMENT.DETAIL,
    config: ACCOUNT_MANAGEMENT,
  },
  {
    path: `${MENU.OTHER_REPORTS}`,
    component: OtherReport,
    accessType: { menu: M.REPORTING_TOOLS, type: T.READ },
    pageEvent: PAGE_EVENTS.OTHER_REPORTS.LIST,
    config: REPORTING_TOOLS,
  },
  {
    path: `${MENU.SALES_FUNNEL}`,
    component: SalesFunnelReport,
    accessType: { menu: M.REPORTING_TOOLS, type: T.READ },
    pageEvent: PAGE_EVENTS.SALES_FUNNEL.LIST,
    config: REPORTING_TOOLS,
  },
  {
    path: `${MENU.VISITATION_REPORTS}`,
    component: VisitationReport,
    accessType: { menu: M.REPORTING_TOOLS, type: T.READ },
    pageEvent: PAGE_EVENTS.VISITATION_REPORT,
    config: REPORTING_TOOLS,
  },
  {
    path: `${MENU.KPI_VISUALIZATION}`,
    redirectPath: `${MENU.KPI_VISUALIZATION}/transaction`,
    pageEvent: '',
    config: REPORTING_TOOLS,
  },
  {
    path: `${MENU.KPI_VISUALIZATION}/:tab`,
    component: KpiVisualizationReport,
    accessType: { menu: M.REPORTING_TOOLS, type: T.READ },
    pageEvent: ({ tab }) => (tab === 'transaction' ? PAGE_EVENTS.KPI_VISUALIZATION.TRANSACTION : PAGE_EVENTS.KPI_VISUALIZATION.VISITATION),
    config: REPORTING_TOOLS,
  },
];

export default privateRoutes;
