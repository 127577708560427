import React, { useState, useEffect, useMemo } from 'react'
import { withRouter } from 'react-router-dom'

import { Table } from 'components'
import Layout from 'layout/main'
import InputSearchQuery from 'components/InputSearchQuery'
import SelectSearchQuery from 'components/SelectSearchQuery'

import { AcquisitionReviewApi } from 'api/acquisition-review'
import { getQueryStringForPagination } from 'utils/query'
import { ACQUISITION_STATUS } from 'config/acquisition'
import MENU from 'config/menu'

import { columns } from './columns'

const PER_PAGE = 10
const AcquisitionReview = ({ history, location }) => {
	const [loading, setLoading] = useState(false)
	const [data, setData] = useState([])
	const [metadata, setMetadata] = useState({})

	const query = useMemo(() => getQueryStringForPagination(location.search), [
		location.search,
	])

	const goToDetailPage = (roleId) => {
		history.push(`${MENU.ACQUISITION_REVIEW}/${roleId}`)
	}

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)
			try {
				const { data } = await AcquisitionReviewApi.get({
					per_page: PER_PAGE,
					...query,
				})
				setData([...data.data])
				setMetadata(data.metadata)
				setLoading(false)
			} catch (error) {
				console.error(error)
				setLoading(false)
			}
		}

		fetchData()
	}, [query])

	return (
		<Layout title="Acquisition Review">
			<div className="app-content">
				<div className="flex mb-10 mt-4">
					<InputSearchQuery className="md:w-328px md:mr-5 md:mb-0 mb-5" />
					<SelectSearchQuery
						className="md:w-328px w-full md:mr-5 md:mb-0"
						options={ACQUISITION_STATUS}
						fieldName="status"
					/>
				</div>
				<Table
					loading={loading}
					columns={columns(goToDetailPage)}
					rowKey="id"
					dataSource={data}
					count={metadata.total_count}
					history={history}
					location={location}
					urlPush={MENU.ACQUISITION_REVIEW}
				/>
			</div>
		</Layout>
	)
}

export default withRouter(AcquisitionReview)
