import React from 'react'
import { Tag as TagAntd } from 'antd'
// import { COLOR_STATUS_MAPPED } from 'config/merchant-management';


function Tag({ color, style, ...props }) {
    return (
        <TagAntd
            color={color}
            style={{
                height: 26,
                width: 100,
                fontWeight: 700,
                borderRadius: 50,
                textAlign: 'center',
                ...style
            }} {...props} />
    )
}

export default Tag