import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Form, Modal, Input, Select } from 'antd';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import { Button } from 'components';

import { positionsApi } from 'api/positions';
import AreaFormItem from '../../shared/area-form-item';

const { TextArea } = Input;

const EditPositionModal = Form.create()(({ position, visible, close, form, onExpelButtonClicked, reloadParent }) => {
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const sendError = useRollbarNotifier();

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, formData) => {
      if (!err) {
        setLoading(true);

        const payload = {
          position_id: formData.position_id,
          reason: formData.reason,
          label: formData.label,
          user_email: formData.user_email,
          role_id: formData.role_id?.key || formData.role_id,
          area_search_type: formData.search_area_type,
          area_id: formData.search_area_value?.key || '',
          area_name: formData.search_area_value?.label || '',
          area_territories: formData.search_area_territories.map((loc) => loc.value),
        };

        try {
          await positionsApi.updatePosition(position.position_id, payload);
          setLoading(false);

          close();
          reloadParent();
          Modal.success({
            title: 'Update Position Success',
            onOk: () => {
              reloadParent();
            },
          });
        } catch (error) {
          setLoading(false);
          sendError(`Error when submit EditPositionModal`, error);
        }
      }
    });
  };

  return (
    <Modal
      title="Edit Position"
      visible={visible}
      onCancel={close}
      width={960}
      destroyOnClose
      footer={
        <div className="flex-end">
          <Button onClick={close}>Cancel</Button>
          <Button type="primary" htmlType="submit" loading={loading} onClick={handleSubmit}>
            Save Changes
          </Button>
        </div>
      }
    >
      <Form>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item label="Territory ID">
              {getFieldDecorator('position_id', {
                rules: [],
                initialValue: position.position_id,
              })(<Input disabled />)}
            </Form.Item>

            <Form.Item label="Position Label">
              {getFieldDecorator('label', {
                rules: [
                  { required: true, message: 'Please input position label' },
                  {
                    pattern: '^[a-zA-Z0-9\\s-]+$',
                    message: 'Please input valid position label',
                  },
                ],
                initialValue: position.position_label,
              })(<Input placeholder="Position Label" />)}
            </Form.Item>

            <Form.Item label="Role">
              {getFieldDecorator('role_id', {
                rules: [],
                initialValue: {
                  key: position.role_id,
                  label: position.role_name,
                },
              })(<Select disabled labelInValue />)}
            </Form.Item>

            <Form.Item label="User Email" className={position.user_email ? 'mb-0' : ''}>
              {getFieldDecorator('user_email', {
                rules: [],
                initialValue: position.user_email,
              })(<Input disabled />)}
            </Form.Item>

            {position.user_email && (
              <Button size="small" className="mb-4" onClick={onExpelButtonClicked}>
                Expel
              </Button>
            )}

            <Form.Item label="Date Created">
              {getFieldDecorator('created_date_formatted', {
                rules: [],
                initialValue: position.created_date_formatted,
              })(<Input disabled />)}
            </Form.Item>

            <Form.Item label="Created By">
              {getFieldDecorator('created_by', {
                rules: [],
                initialValue: position.created_by,
              })(<Input disabled />)}
            </Form.Item>
          </Col>

          <Col span={12}>
            <AreaFormItem
              initialValues={{
                search_area_type: {
                  key: position.area_search_type,
                  label: 'Area Preset',
                },
                search_area_value: {
                  key: position.area_id,
                  label: position.area_name,
                },
                search_area_territories: position.area_territories,
              }}
              apiForAvailability={positionsApi.getPositionTerritoryAvailability}
              form={form}
            />

            <Form.Item label="Reason">
              {getFieldDecorator('reason', {
                rules: [],
              })(<TextArea rows={4} />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});

export default withRouter(EditPositionModal);
