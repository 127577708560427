import React from 'react';

import TabsPage from 'layout/tabs-page';
import { MENU } from 'config/menu';

import KpiTransaction from './transaction';
import KpiVisitation from './visitation';

function KpiVisualizationTabs() {
  const tabs = [
    {
      key: 'transaction',
      title: 'Transaction',
      content: <KpiTransaction />,
    },
    {
      key: 'visitation',
      title: 'Visitation',
      content: <KpiVisitation />,
    },
  ];
  return <TabsPage pageTitle="KPI Visualization" urlPush={MENU.KPI_VISUALIZATION} tabs={tabs} preserveQueryParams />;
}

export default KpiVisualizationTabs;
