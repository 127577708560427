/**
 * Event Names for Google Analytic usePageTracking Hooks.
 */
export const PAGE_EVENTS = {
  ACQUISITION_REVIEW: {
    LIST: 'page:acquisition_review',
    DETAIL: 'page:acquisition_review:detail',
  },
  MERCHANT_MANAGEMENT: {
    LIST: 'page:merchant_management',
    DETAIL: 'page:merchant_management:detail',
  },
  ACCOUNT_MANAGEMENT: {
    LIST: 'page:role_management',
    DETAIL: 'page:role_management:detail',
    CREATE: 'page:role_management:create',
  },
  USER_MANAGEMENT: {
    LIST: 'page:user_management',
    DETAIL: 'page:user_management:detail',
  },
  POSITION_MANAGEMENT: {
    LIST: 'page:position_management',
    DETAIL: 'page:position_management:detail',
  },
  AREA_PRESET: {
    LIST: 'page:area_preset_management',
    DETAIL: 'page:area_preset_management:detail',
  },
  VISIT_ASSIGNMENT_LEADS: {
    LIST: 'page:visit_assignment_leads',
    DETAIL: 'page:visit_assignment_leads:detail',
  },
  VISIT_ASSIGNMENT_ASSIGNEE: {
    LIST: 'page:visit_assignment_assignee',
    DETAIL: 'page:visit_assignment_assignee:detail',
  },
  OTHER_REPORTS: {
    LIST: 'page:other_reports',
  },
  SALES_FUNNEL: {
    LIST: 'page:sales_funnel',
  },
  VISITATION_REPORT: {
    LIST: 'page:visitation_visualization',
  },
  KPI_VISUALIZATION: {
    VISITATION: 'page:kpi_visualization:visitation',
    TRANSACTION: 'page:kpi_visualization:transaction',
  },
};

export default PAGE_EVENTS;
