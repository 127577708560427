import moment from 'moment';

export const getQuarterRanges = ({ q, year }) => {
  const startDate = moment(year, 'YYYY').quarter(q).startOf('quarter');
  const endDate = moment(year, 'YYYY').quarter(q).endOf('quarter');
  return [startDate, endDate];
};

export const getMonthRanges = ({ month, year }) => {
  const startDate = moment({ month: +month - 1, year: +year }).startOf('month');
  const endDate = moment({ month: +month - 1, year: +year }).endOf('month');
  return [startDate, endDate];
};

export const getYearRanges = ({ year = '2023' }) => {
  const startDate = moment({ year: +year }).startOf('year');
  const endDate = moment({ year: +year }).endOf('year');
  return [startDate, endDate];
};

export function isLessthan24hr(reviewedDate) {
  const currentDate = moment();
  const dateToCheck = moment(reviewedDate);
  const hoursDifference = currentDate.diff(dateToCheck, 'hours');
  return hoursDifference < 24;
}
