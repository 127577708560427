import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Modal } from 'antd'
import { Button, ListTableView } from 'components'
import { ArrowDown } from 'assets'
import { usersApi } from 'api/users'
import SearchSelect from 'components/search-select'
import { getCookies } from 'utils/cookies'
import { CONFIG_COOKIES } from 'config/cookies'
import ReasonMergeUser from './reason-merge-user'

// const MERCHANT_ID = 'd82cbe2e-bac7-4a1b-be4c-dc6be229e41c'

const MergeUser = ({ user, visible, setVisible, form }) => {
	const [loading, setLoading] = useState(false)
	const [userList, setUserList] = useState([])
	const [selectedUser, setSelectedUser] = useState({
		id: '-',
		name: '-',
		email: '-',
		phone: '-',
		role: '-',
	})

	const [showReasonModal, setShowReasonModal] = useState(false)
	const { getFieldDecorator } = form

	const resetState = () => {
		setSelectedUser({ id: '-', name: '-', email: '-', phone: '-', role: '-' })
		setLoading(false)
		setVisible(false)
	}

	const goToReason = (e) => {
		e.preventDefault()
		form.validateFields(async (err) => {
			if (err) return
			setShowReasonModal(true)
			setLoading(false)
			setVisible(false)
		})
	}

	const searchUser = async (keyword) => {
		setLoading(true)
		const resp = await usersApi.get({
			merchant_id: getCookies(CONFIG_COOKIES.MERCHANT_ID),
			search: keyword,
		})
		setUserList([...resp.data.data])
		setLoading(false)
		const searchResults = [...resp.data.data]
		return searchResults.map((dt, index) => ({
			label: dt.email,
			text: (
				<div>
					<p>{dt.name}</p>
					<p className="italic text-gray-500">{dt.email}</p>
				</div>
			),
			value: index,
		}))
	}

	const handleChange = (e) => {
		setSelectedUser(userList[e.key])
	}

	return (
		<>
			<Modal
				width={520}
				destroyOnClose
				maskClosable={false}
				title={<p className="font-bold text-cf-16">Merge Account</p>}
				footer={null}
				visible={visible}
				onCancel={() => {
					setVisible(false)
					setSelectedUser({
						id: '-',
						name: '-',
						email: '-',
						phone: '-',
						role: '-',
					})
				}}>
				<Form className="master-field__form" onSubmit={goToReason}>
					<div className="border p-5">
						<p className="font-bold text-cf-16 mb-2">Merge this account</p>
						<ListTableView
							data={{
								'User ID': user.id || '-',
								'Full Name': user.name || '-',
								Email: user.email || '-',
								'Phone Number': user.phone || '-',
								Role: user.role || '-',
							}}
						/>
					</div>

					<ArrowDown className="flex justify-center m-5" />
					<div className="border p-5">
						<p className="text-cf-16 font-bold mb-5">With this account</p>
						<Form.Item label="Search User ID, Name, Email, Phone Number">
							{getFieldDecorator('new_user', {
								rules: [{ required: true, message: 'Please input User' }],
							})(
								<SearchSelect
									wordLimit={3}
									placeholder="Search"
									onChange={handleChange}
									optionLabelProp="label"
									api={searchUser}
								/>,
							)}
						</Form.Item>
						<ListTableView
							data={{
								'User ID': selectedUser?.id || '-',
								'Full Name': selectedUser?.name || '-',
								Email: selectedUser.email || '-',
								'Phone Number': selectedUser?.phone || '-',
								Role: selectedUser?.role || '-',
							}}
						/>
					</div>
					<div className="flex mt-5">
						<Button className="mr-3 ml-auto" onClick={resetState}>
							Cancel
						</Button>
						<Button type="primary" htmlType="submit" loading={loading}>
							Merge Account
						</Button>
					</div>
				</Form>
			</Modal>
			<ReasonMergeUser
				visible={showReasonModal}
				close={() => {
					setShowReasonModal(false)
					resetState()
				}}
				user={user}
				newUser={selectedUser}
			/>
		</>
	)
}

const WrappedDetail = Form.create({
	name: 'mergeUser',
})(MergeUser)

export default withRouter(WrappedDetail)
