import React from 'react'

function Icon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24">
			<path
				fill="#333"
				fillRule="evenodd"
				d="M8.667 19a1.02 1.02 0 01-.71-.285L2.294 13.19a.96.96 0 010-1.382 1.021 1.021 0 011.417 0l4.957 4.833L19.288 6.287a1.018 1.018 0 011.417 0 .957.957 0 010 1.382L9.377 18.715a1.02 1.02 0 01-.71.285z"
				clipRule="evenodd"
			/>
		</svg>
	)
}

export default Icon
