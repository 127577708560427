import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { visitAssignmentApi } from 'api/visit-assignment';
import { getQueryString } from 'utils/query';

const PAGE_LIMIT = 10;

function useVisitAssignmentAssignee() {
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(1);
  const location = useLocation();

  const fetchData = async () => {
    setLoading(true);
    const query = { ...getQueryString(location.search) };
    const res = await visitAssignmentApi.assigneeList({
      per_page: PAGE_LIMIT,
      page: query.page,
      search: query.search,
      start_date: query?.start_filter,
      end_date: query?.end_filter,
      acquisition_status: query?.acquisition_status,
      visitation_status: query?.visitation_status,
      role_code: query?.role_code,
      // location_code: string
    });

    setDataList(res?.data?.data || []);
    setCount(res?.data?.count || 0);
    setCurrentPage(query.page);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [location.search]);

  return { dataList, loading, currentPage, count };
}

export default useVisitAssignmentAssignee;
