import React, { useState, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import qs from 'qs';
import { Table } from 'components';

import HistoryCard from 'components/Molecules/HistoryCard/history-card';
import { AcquisitionReviewApi } from 'api/acquisition-review';
import { getQueryStringForPagination } from 'utils/query';
import { MENU } from 'config/menu';

import { columns } from './columns';

const PAGE_SIZE = 10;
function HistoryLogs({ location, history, match, lastUpdate }) {
  const [loading, setLoading] = useState(false);
  const [historyLogs, setHistoryLogs] = useState([]);

  const query = getQueryStringForPagination(location.search);
  const { historyPage } = query;
  const [totalData, setTotalData] = useState(null);
  const acquisitionId = useMemo(() => match.params.id, [match.params]);
  const sendError = useRollbarNotifier();

  const onChangePage = (pageNum) => {
    const pageQuery = { ...query, historyPage: pageNum };
    if (pageNum === 1) delete pageQuery.historyPage;
    history.push(`${MENU.ACQUISITION_REVIEW}/${acquisitionId}?${qs.stringify(pageQuery)}`);
  };

  useEffect(() => {
    const fetchHistory = async () => {
      setLoading(true);
      try {
        const res = await AcquisitionReviewApi.getHistory(acquisitionId, {
          page: historyPage,
          per_page: PAGE_SIZE,
        });
        setHistoryLogs(res.data.data);
        setTotalData(res.data.metadata.total_count);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        sendError(`Error when fetch acquisition detail history list options`, error);
      }
    };
    fetchHistory();
  }, [acquisitionId, historyPage, lastUpdate]);

  return (
    <HistoryCard>
      <Table
        loading={loading}
        dataSource={historyLogs}
        columns={columns()}
        rowKey="updated_date_utc"
        withoutPagination
        pagination={{
          current: +historyPage || 1,
          total: totalData,
          pageSize: PAGE_SIZE,
          onChange: onChangePage,
        }}
      />
    </HistoryCard>
  );
}

HistoryLogs.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  lastUpdate: PropTypes.string.isRequired,
};

export default withRouter(HistoryLogs);
