import React from 'react';
import useCharts from 'customHooks/useCharts';
import { toTitleCase } from 'utils/formatter/string';
import { visitationReportApi } from 'api/reports';
import SummaryCard from '../../shared/SummaryCard';

function SummarySection() {
  const { data: summary, loading } = useCharts({
    callApi: async (params) => {
      if (!params?.sales_id) return null;
      const res = await visitationReportApi.getSummary(params);
      return res;
    },
  });

  return (
    <SummaryCard loading={loading}>
      <SummaryCard.Col withoutBorder>
        <p className="font-bold text-cf-16 mb-2 text-cf-gray">{toTitleCase(summary?.sales_name) || '-'}</p>
        <p className="text-cf-12 mb-2">{summary?.sales_code?.toUpperCase() || '-'}</p>
        <p className="text-cf-12">{toTitleCase(summary?.sales_phone) || '-'}</p>
      </SummaryCard.Col>
    </SummaryCard>
  );
}

export default SummarySection;
