import React from 'react';
import { Card } from 'antd';
import AreaChart from 'components/Chart/AreaChart';
import useCharts from 'customHooks/useCharts';
import { kpiVisitationApi } from 'api/reports';
import ChartTitle from '../../../shared/ChartTitle';

function AverageVisitationDistance() {
  const { data, loading, startDate, endDate } = useCharts({ callApi: kpiVisitationApi.getVisitationDistance });

  return (
    <Card className="rounded-2xl">
      <ChartTitle title="Average Visitation Distance" startDate={startDate} endDate={endDate} />
      <AreaChart
        data={data}
        dataKey="period"
        loading={loading}
        xLabel="Visitation Date"
        yLabel="Total Visit"
        lines={[{ dataKey: 'distance_meter', stroke: '#009696', fill: '#009696', withLabelList: true }]}
        legendOptions={{ visible: false }}
      />
    </Card>
  );
}

export default AverageVisitationDistance;
