import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

function Text({ children, loading, className, style }) {
  if (loading)
    return (
      <div className="flex justify-center items-center">
        <div className={className} style={style}>
          <Spin size="small" />
        </div>
      </div>
    );

  return (
    <div className="flex justify-center items-center">
      <p className={className} style={style}>
        {loading ? <Spin size="small" /> : children}
      </p>
    </div>
  );
}

Text.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

Text.defaultProps = {
  loading: false,
  className: '',
  style: {},
};

export default Text;
