import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Input } from 'antd'
import { usersApi } from '../../../api/users'

const { TextArea } = Input

const DeleteUserConfirmationModal = ({
	visible,
	setVisible,
	user,
	history,
}) => {
	const [reasonValue, setReasonValue] = useState('')
	const [loading, setLoading] = useState(false)

	const handleDelete = async () => {
		setLoading(true)
		const res = await usersApi.delete(user.id, {
			reason: reasonValue,
		})

		// TODO: show error
		if (!res) return

		Modal.success({
			title: 'Success',
			content: `Account has been Deleted successfully`,
			onOk: () => {
				history.push('/user-management')
				setReasonValue('')
				setVisible(false)
			},
		})

		setLoading(false)
	}

	return (
		<Modal
			loading={loading}
			destroyOnClose
			onOk={handleDelete}
			title={<span style={{ fontWeight: 700 }}>Delete User</span>}
			visible={visible}
			onCancel={() => {
				setVisible(false)
				setReasonValue('')
			}}
			okText="Delete"
			okButtonProps={{ disabled: !reasonValue }}
			cancelText="Cancel"
			centered>
			<p style={{ fontSize: 16, textAlign: 'center' }}>
				Are you sure want to delete this user?
			</p>
			<p style={{ fontSize: 14 }}>Reason</p>
			<TextArea
				rows={4}
				value={reasonValue}
				onChange={(e) => setReasonValue(e.target.value)}
			/>
		</Modal>
	)
}

export default withRouter(DeleteUserConfirmationModal)
