import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Icon, Select, Spin } from 'antd';
import { generateUrlQueryParams, parseUrlQueryParams } from 'utils/query';
import { capitalize } from 'utils/formatter/string';

import './style.css';

const { Option } = Select;

/**
 *
 * @param {*} fieldName the name of the field/key
 * @param {*} options option list could be an array or promise that return array
 * @returns select component that store in query parameter in client browser
 */
function SelectSearchQuery({ fieldName = 'filter', options = [], location, history, className, initialValue }) {
  const [renderOptions, setRenderOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const value = parseUrlQueryParams(location.search)[fieldName];

  const updateSearchQuery = (val) => {
    history.push({
      search: generateUrlQueryParams({
        ...parseUrlQueryParams(location.search),
        page: 1,
        [fieldName]: val,
      }),
    });
  };

  useEffect(() => {
    const getOptions = async () => {
      if (Array.isArray(options)) {
        return setRenderOptions([...options]);
      }

      setLoading(true);
      const newOptions = await options();
      setLoading(false);

      if (!newOptions || newOptions.length === 0) {
        return setRenderOptions([]);
      }

      return setRenderOptions([...newOptions]);
    };

    getOptions();
  }, [options]);

  return (
    <div className={`${className || ''}`}>
      <Select
        allowClear
        style={{ height: 48 }}
        className={`selectSearchQuery text-cf-14 ${className || ''}`}
        size="large"
        suffix={<Icon type="search" />}
        value={value || initialValue || ''}
        onChange={updateSearchQuery}
        loading={loading}
        notFoundContent={loading ? <Spin size="small" /> : null}
      >
        {renderOptions.map((opt) => (
          <Option key={opt.key} value={opt.key}>
            {capitalize(opt.label)}
          </Option>
        ))}
      </Select>
    </div>
  );
}

SelectSearchQuery.propTypes = {
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  className: PropTypes.string,
  initialValue: PropTypes.string,
};

SelectSearchQuery.defaultProps = {
  className: '',
  initialValue: null,
};

export default withRouter(SelectSearchQuery);
