/* eslint-disable */
import { Geofazz } from './geofazz'

export default class GeofazzBuilder {
	initialState = {
		used: false,
		query: '',
		condition: '',
		returns: '',
	}

	initialHierarchies = []

	constructor() {
		this.province = { ...this.initialState }
		this.regency = { ...this.initialState }
		this.district = { ...this.initialState }
		this.village = { ...this.initialState }

		this.hierarchies = this.initialHierarchies
	}

	/**
	 *
	 * @param arg       [codes, in, except]
	 * @param codes     {array}
	 * @param returns   {array}
	 *
	 * @return {GeofazzBuilder}
	 */
	setProvince(arg = '', codes = [], returns = []) {
		if (this.province.used) {
			throw Error('Method setProvince() or setProvinces() already used!')
		}

		this.province.used = true
		this.province.query = 'province'

		if (codes.length > 0 && arg) {
			codes = Geofazz.mapCodeToString(codes)

			this.province.condition += `(${arg}:[${codes}])`
		}

		if (returns.length > 0) {
			this.province.returns = Geofazz.mapReturnsToString(returns)
		}

		this.hierarchies.push(this.province)

		return this
	}

	/**
	 *
	 * @param arg       [codes, in, except]
	 * @param codes     {array}
	 * @param returns   {array}
	 *
	 * @return {GeofazzBuilder}
	 */
	setProvinces(arg = '', codes = [], returns = []) {
		if (this.province.used) {
			throw Error('Method setProvince() or setProvinces() already used!')
		}

		this.province.used = true
		this.province.query = 'provinces'

		if (codes.length > 0 && arg) {
			codes = Geofazz.mapCodeToString(codes)

			this.province.condition += `(${arg}:[${codes}])`
		}

		if (returns.length > 0) {
			this.province.returns = Geofazz.mapReturnsToString(returns)
		}

		this.hierarchies.push(this.province)

		return this
	}

	/**
	 *
	 * @param arg       [codes, in, except]
	 * @param codes     {array}
	 * @param returns   {array}
	 *
	 * @return {GeofazzBuilder}
	 */
	setRegency(arg = '', codes = [], returns = []) {
		if (this.regency.used) {
			throw Error('Method setRegency() or setRegencies() already used!')
		}

		this.regency.used = true
		this.regency.query = 'regency'

		if (codes.length > 0 && arg) {
			codes = Geofazz.mapCodeToString(codes)

			this.regency.condition += `(${arg}:[${codes}])`
		}

		if (returns.length > 0) {
			this.regency.returns = Geofazz.mapReturnsToString(returns)
		}

		this.hierarchies.push(this.regency)

		return this
	}

	/**
	 *
	 * @param arg       [codes, in, except]
	 * @param codes     {array}
	 * @param returns   {array}
	 *
	 * @return {GeofazzBuilder}
	 */
	setRegencies(arg = '', codes = [], returns = []) {
		if (this.regency.used) {
			throw Error('Method setRegency() or setRegencies() already used!')
		}

		this.regency.used = true
		this.regency.query = 'regencies'

		if (codes.length > 0 && arg) {
			codes = Geofazz.mapCodeToString(codes)

			this.regency.condition += `(${arg}:[${codes}])`
		}

		if (returns.length > 0) {
			this.regency.returns = Geofazz.mapReturnsToString(returns)
		}

		this.hierarchies.push(this.regency)

		return this
	}

	/**
	 *
	 * @param arg       [codes, in, except]
	 * @param codes     {array}
	 * @param returns   {array}
	 *
	 * @return {GeofazzBuilder}
	 */
	setDistrict(arg = '', codes = [], returns = []) {
		if (this.district.used) {
			throw Error('Method setDistrict() or setDistricts() already used!')
		}

		this.district.used = true
		this.district.query = 'district'

		if (codes.length > 0 && arg) {
			codes = Geofazz.mapCodeToString(codes)

			this.district.condition += `(${arg}:[${codes}])`
		}

		if (returns.length > 0) {
			this.district.returns = Geofazz.mapReturnsToString(returns)
		}

		this.hierarchies.push(this.district)

		return this
	}

	/**
	 *
	 * @param arg       [codes, in, except]
	 * @param codes     {array}
	 * @param returns   {array}
	 *
	 * @return {GeofazzBuilder}
	 */
	setDistricts(arg = '', codes = [], returns = []) {
		if (this.district.used) {
			throw Error('Method setDistrict() or setDistricts() already used!')
		}

		this.district.used = true
		this.district.query = 'districts'

		if (codes.length > 0 && arg) {
			codes = Geofazz.mapCodeToString(codes)

			this.district.condition += `(${arg}:[${codes}])`
		}

		if (returns.length > 0) {
			this.district.returns = Geofazz.mapReturnsToString(returns)
		}

		this.hierarchies.push(this.district)

		return this
	}

	/**
	 *
	 * @param arg       [codes, in, except]
	 * @param codes     {array}
	 * @param returns   {array}
	 *
	 * @return {GeofazzBuilder}
	 */
	setVillage(arg = '', codes = [], returns = []) {
		if (this.village.used) {
			throw Error('Method setVillage() or setVillages() already used!')
		}

		this.village.used = true
		this.village.query = 'village'

		if (codes.length > 0 && arg) {
			codes = Geofazz.mapCodeToString(codes)

			this.village.condition += `(${arg}:[${codes}])`
		}

		if (returns.length > 0) {
			this.village.returns = Geofazz.mapReturnsToString(returns)
		}

		this.hierarchies.push(this.village)

		return this
	}

	/**
	 *
	 * @param arg       [codes, in, except]
	 * @param codes     {array}
	 * @param returns   {array}
	 *
	 * @return {GeofazzBuilder}
	 */
	setVillages(arg = '', codes = [], returns = []) {
		if (this.village.used) {
			throw Error('Method setVillage() or setVillages() already used!')
		}

		this.village.used = true
		this.village.query = 'villages'

		if (codes.length > 0 && arg) {
			codes = Geofazz.mapCodeToString(codes)

			this.village.condition += `(${arg}:[${codes}])`
		}

		if (returns.length > 0) {
			this.village.returns = Geofazz.mapReturnsToString(returns)
		}

		this.hierarchies.push(this.village)
		return this
	}

	/**
	 *
	 * @return {string}
	 */
	build() {
		let payload = ''

		if (this.hierarchies.length > 0) {
			payload = this.buildHierarchy()
		}

		return Geofazz.buildScope(payload)
	}

	buildHierarchy(index = 0) {
		if (index >= this.hierarchies.length) {
			return ''
		}

		const obj = this.hierarchies[index]

		let query = ''

		if (obj.used) {
			query += `${obj.query}${obj.condition}`

			query += '{'

			query += `${Geofazz.mapReturnsToString(obj.returns)} `

			query += this.buildHierarchy(index + 1)

			query += '}'
		}

		return query
	}
}
