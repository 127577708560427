import React, { useEffect, useState } from 'react';
import { MENU } from 'config/menu';
import moment from 'moment';

import { Card } from 'components';
import Breadcrumb from 'components/breadcrumb';
import { capitalize } from 'utils/formatter/string';
import Layout from 'layout/main';
import { visitAssignmentApi } from 'api/visit-assignment';
import { useParams } from 'react-router-dom';
import { VISITATION_STATUS_ASSIGNEE_OPTIONS } from 'config/visit-assignment';
import Tag from 'components/Tag/v2';
import VisitationHistory from './VisitationHistory';

function VisitAssignmentAssigneeDetail() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { visitorId } = useParams();

  const status = VISITATION_STATUS_ASSIGNEE_OPTIONS.find((opt) => opt.key === data?.visitation_status) || {};

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await visitAssignmentApi.detailAssignmentByVisitor(visitorId);
      setData(res.data);
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <Layout title="Visit Assignment">
      <Breadcrumb
        data={[
          {
            link: `${MENU.VISIT_ASSIGNMENT}/leads`,
            title: 'Visit Assignment',
          },
          {
            link: `${MENU.VISIT_ASSIGNMENT}/assignee`,
            title: 'Visit Assignee',
          },
          {
            link: null,
            title: <b>Detail Assignee</b>,
          },
        ]}
      />
      <div className="app-content__details">
        <Card loading={loading} bordered={false} className="card">
          <div className="grid grid-cols-5 py-10 px-6">
            <div>
              <div className="text-cf-gray">Visitor Name</div>
              <div className="text-2xl font-bold text-cf-gray mt-4">{capitalize(data?.visitor_name)}</div>
            </div>
            <div>
              <div className="text-cf-gray">Position</div>
              <div className="text-2xl font-bold text-cf-gray mt-4">{data?.visitor_position}</div>
            </div>
            <div>
              <div className="text-cf-gray">Visitation Period</div>
              <div className="text-2xl font-bold text-cf-gray mt-4">
                {data?.visitation_first_period ? moment(data?.visitation_first_period || '').format('DD MMM') : ''} -{' '}
                {data?.visitation_last_period ? moment(data?.visitation_last_period || '').format('DD MMM') : ''}
              </div>
            </div>
            <div>
              <div className="text-cf-gray">Progress Visited</div>
              <div className="text-2xl font-bold text-cf-gray mt-4">
                {data?.assignment_visited_count || 0}/{data?.assignment_count || 0}
              </div>
            </div>
            <div>
              <div className="text-cf-gray">Visitation Status</div>
              <div className="text-2xl font-bold text-cf-gray mt-4 w-min">
                <Tag bgColor={status?.bgColor} textColor={status?.textColor}>
                  {status?.label || '-'}
                </Tag>
              </div>
            </div>
          </div>
        </Card>

        <VisitationHistory />
      </div>
    </Layout>
  );
}

export default VisitAssignmentAssigneeDetail;
