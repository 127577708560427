import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select } from 'antd';
import { Button } from 'components';
import { positionsApi } from 'api/positions';
import AreaFormItem from '../../shared/area-form-item';

function CreatePositionForm({ form, roles = [], onSubmit, setVisible, isLoading }) {
  const { getFieldDecorator, setFields } = form;

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, formData) => {
      const reqBody = { ...formData };
      reqBody.area_id = reqBody?.search_area_value?.key || '';
      reqBody.area_name = reqBody?.search_area_value?.label;
      reqBody.area_search_type = reqBody.search_area_type;
      reqBody.area_territories = reqBody.search_area_territories.map((loc) => loc.value);

      if (!err) {
        onSubmit(reqBody);
      }
    });
  };

  const resetAreaForm = () => {
    setFields({
      search_area_territories: [],
      search_area_value: null,
      search_area_type: null,
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label="Position Label">
        {getFieldDecorator('label', {
          rules: [
            { required: true, message: 'Please input position label' },
            {
              pattern: '^[a-zA-Z0-9\\s-]+$',
              message: 'Please input valid position label',
            },
          ],
        })(<Input placeholder="Position Label" />)}
      </Form.Item>

      <Form.Item label="Role">
        {getFieldDecorator('role_id', {
          rules: [{ required: true, message: 'Please select role' }],
        })(
          <Select
            showSearch
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            placeholder="Search Role"
            style={{ width: '100%' }}
            onChange={resetAreaForm}
          >
            {roles?.map((role) => (
              <Select.Option key={role.code} value={role.id}>
                {role.name}
              </Select.Option>
            ))}
          </Select>,
        )}
      </Form.Item>

      <AreaFormItem apiForAvailability={positionsApi.getPositionTerritoryAvailability} form={form} />

      <Form.Item className="mt-10 -mb-1">
        <div className="flex">
          <Button className="mr-3 ml-auto" onClick={() => setVisible(false)}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Create New Position
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}

CreatePositionForm.propTypes = {
  form: PropTypes.object.isRequired,
  roles: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  setVisible: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

CreatePositionForm.defaultProps = {
  roles: [],
};

export default Form.create()(CreatePositionForm);
