import React from 'react';
import { Card } from 'antd';
import Bar from 'components/Chart/BarChart';
import { kpiVisitationApi } from 'api/reports';
import useCharts from 'customHooks/useCharts';
import ChartTitle from '../../../shared/ChartTitle';

function CategorizedDailyVisitation() {
  const { data, loading, startDate, endDate } = useCharts({ callApi: kpiVisitationApi.getVisitationCategorized });

  return (
    <Card className="rounded-2xl">
      <ChartTitle title="Categorized Daily Visitation" startDate={startDate} endDate={endDate} />
      <Bar
        data={data}
        dataKey="period"
        loading={loading}
        xLabel="Visitation Date"
        yLabel="Total Visit"
        bars={[
          { type: 'linear', dataKey: 'activation', fill: '#001E3A' },
          { type: 'linear', dataKey: 'retention', fill: '#6A56EB' },
          { type: 'linear', dataKey: 'no_category', fill: '#3B8EF3' },
          { type: 'linear', dataKey: 'regular', fill: '#DB5555' },
          { type: 'linear', dataKey: 'education', fill: '#F2C900' },
          { type: 'linear', dataKey: 'soft_collection', fill: '#009696' },
        ]}
        legendOptions={{ visible: true }}
      />
    </Card>
  );
}

export default CategorizedDailyVisitation;
