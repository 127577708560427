import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { Form, Modal, Select, Radio, DatePicker } from 'antd'
import { Button, ListTableView } from 'components'
import { CONFIG_COOKIES } from 'config/cookies'
import { getCookies } from 'utils/cookies'
import { usersApi } from 'api/users'
import PositionFormItem from '../position-form-item'

const { Option } = Select
const PROMOTE_NOW = 'PROMOTE_NOW'
const SCHEDULE_DATE = 'SCHEDULE_DATE'

const PromoteUser = ({ user, visible, form, close, reloadParent }) => {
	const [loading, setLoading] = useState(false)
	const { getFieldDecorator, setFields, getFieldsValue } = form

	const [radioValue, setRadioValue] = useState(PROMOTE_NOW)
	const merchantId = getCookies(CONFIG_COOKIES.MERCHANT_ID)

	const onClose = () => {
		setLoading(false)
		close()
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		form.validateFields(async (err, values) => {
			if (err) return

			try {
				const reqBody = {
					// role_id: values.role_id,
					position_id: values.position.key,
					scheduled_at: values.scheduled_at
						? values.scheduled_at.format('YYYY-MM-DD')
						: '',
				}

				setLoading(true)
				await usersApi.promoteUser(user.id, reqBody)
				setLoading(false)
				close()

				Modal.success({
					title: 'Success',
					content:
						radioValue === SCHEDULE_DATE
							? 'This account will be promoted according to the schedule'
							: `User has been Promoted successfully`,
					onOk: () => reloadParent(),
				})
			} catch (error) {
				console.error(error)
				setLoading(false)
			}
		})
	}

	return (
		<>
			<Modal
				width={520}
				destroyOnClose
				maskClosable={false}
				title={<p className="font-bold text-cf-16">Promote</p>}
				footer={null}
				visible={visible}
				onCancel={onClose}>
				<div className="border-b -mt-4 pb-8 mb-4">
					<ListTableView
						data={{
							'User ID': user.id || '-',
							'Full Name': user.name || '-',
							Email: user.email || '-',
							'Phone Number': user.phone || '-',
							Role: user.role || '-',
						}}
					/>
				</div>
				<Form className="master-field__form" onSubmit={handleSubmit}>
					<p className="text-cf-16 font-bold mb-2 cf-lightGray">Merchant</p>
					<Form.Item>
						{getFieldDecorator('merchant', {
							rules: [{ required: true, message: 'Please input User' }],
							initialValue: merchantId,
						})(
							<Select size="large" disabled>
								<Option value={merchantId}>{merchantId}</Option>
							</Select>,
						)}
					</Form.Item>
					{/* <p className="text-cf-14 font-bold mt-5 mb-2 cf-lightGray">
						New Role
					</p>
					<Form.Item>
						{getFieldDecorator('role_id', {
							rules: [{ required: true, message: 'Please Select Role ID' }],
							initialValue: user?.role_id,
						})(
							<Select size="large" loading={roleListLoading || loading}>
								{roles?.map((role) => (
									<Select.Option key={role.code} value={role.id}>
										{toTitleCase(role.name)}
									</Select.Option>
								))}
							</Select>,
						)}
					</Form.Item> */}

					<PositionFormItem
						size="large"
						setFields={setFields}
						getFieldDecorator={getFieldDecorator}
						data={getFieldsValue()}
						initValue={
							user?.position_id && {
								key: user?.position_id,
								label: user?.position_label,
							}
						}
						isPositionRequired
					/>

					<Radio.Group
						className="mt-9"
						onChange={(e) => setRadioValue(e.target.value)}
						value={radioValue}>
						<Radio className="block" value={PROMOTE_NOW}>
							<span className="text-cf-gray text-cf-14">Promote Now</span>
						</Radio>
						<Radio className="block" value={SCHEDULE_DATE}>
							<span className="text-cf-gray text-cf-14">
								Promote by scheduling the date
							</span>
						</Radio>
					</Radio.Group>

					{radioValue === SCHEDULE_DATE && (
						<Form.Item>
							{getFieldDecorator('scheduled_at', {
								rules: [{ required: true, message: 'Please select date' }],
							})(
								<DatePicker
									size="large"
									className="w-full"
									format="DD/MM/YYYY"
									disabledDate={(current) => current.isBefore(moment())}
								/>,
							)}
						</Form.Item>
					)}

					<div className="flex mt-5">
						<Button className="mr-3 ml-auto" onClick={onClose}>
							Cancel
						</Button>
						<Button type="primary" htmlType="submit" loading={loading}>
							Promote
						</Button>
					</div>
				</Form>
			</Modal>
		</>
	)
}

const WrappedDetail = Form.create({ name: 'promoteUser' })(PromoteUser)
export default withRouter(WrappedDetail)
