import { METHODS, API_URIS, API_BASE_URL, apiCall } from './config';

export const positionsApi = {
  createPosition: (data) => apiCall(METHODS.POST, API_BASE_URL, '/v1/positions', data),
  listPositions: (data) => apiCall(METHODS.GET, API_BASE_URL, '/v1/positions', data),
  listPositionEvents: (positionId, data) => apiCall(METHODS.GET, API_BASE_URL, `/v1/positions/${positionId}/events`, data),
  listPositionsForCreateUser: (data) => apiCall(METHODS.GET, API_BASE_URL, '/v1/positions/create-user', data),
  getPosition: (id, data) => apiCall(METHODS.GET, API_BASE_URL, `/v1/positions/${id}`, data),
  updatePosition: (id, data) => apiCall(METHODS.POST, API_BASE_URL, `/v1/positions/${id}`, data),
  deletePosition: (id, data) => apiCall(METHODS.POST, API_BASE_URL, `/v1/positions/${id}/delete`, data),
  getHistoryDetail: (id, historyId) => apiCall(METHODS.GET, API_URIS.AUTH, `v1/title/history/${id}/${historyId}`),
  getUserhistoryDetail: (id, historyId) => apiCall(METHODS.GET, API_URIS.AUTH, `v1/title_user/history/${id}/${historyId}`),
  getPositionTerritoryAvailability: (data) => apiCall(METHODS.POST, API_BASE_URL, '/v1/positions/territories/availability', data),
  expelUserFromPosition: (data) => apiCall(METHODS.POST, API_BASE_URL, '/v1/positions/expel-user', data),
  searchPosition: (search) => apiCall(METHODS.GET, API_BASE_URL, '/v1/positions/accessible', { position_name: search }),
  switchPosition: (id1, id2) => apiCall(METHODS.POST, API_BASE_URL, '/v1/positions/switch', { position_id_1: id1, position_id_2: id2 }),
};

export default positionsApi;
