import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spin, Tree } from 'antd';

import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import { GeoApiV2 } from 'api/geo-location-v2';
import { calculateLocationCodes, filterChildrenByGeofazzParams } from 'utils/others/geofazz';
import CheckedTitle from './CheckedTitle';

const { TreeNode } = Tree;

function TerritoriesTreeSection({ areaTerritories, loading: parentLoading }) {
  const [loading, setLoading] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const sendError = useRollbarNotifier();

  const geofazzParams = calculateLocationCodes(areaTerritories);

  const onLoadData = (treeNode) => {
    return new Promise((resolve) => {
      if (treeNode.props.children) {
        resolve();
        return;
      }

      const fetchChildren = async () => {
        try {
          const res = await GeoApiV2.getChildrenByParentId(treeNode.props.dataRef.id);
          const children = filterChildrenByGeofazzParams(
            res.data.data,
            treeNode.props.dataRef.type,
            treeNode.props.dataRef.id,
            geofazzParams,
          );

          // eslint-disable-next-line no-param-reassign
          treeNode.props.dataRef.child = children.map((parent) => ({
            ...parent,
            child: filterChildrenByGeofazzParams(parent.child, parent.type, parent.id, geofazzParams),
          }));

          setTreeData([...treeData]);
          resolve();
        } catch (error) {
          sendError('Error fetching onLoadData at territoryTreeSection form item', error);
        }
      };

      fetchChildren();
    });
  };

  const renderTreeNodes = (data) => {
    return data.map((item) => {
      if (item.child) {
        return (
          <TreeNode title={<CheckedTitle title={item.name} />} key={item.id} dataRef={item}>
            {renderTreeNodes(item.child)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.id} title={<CheckedTitle title={item.name} />} isLeaf={item.type === 'Village'} dataRef={item} />;
    });
  };

  useEffect(() => {
    if (areaTerritories.length === 0) return;

    const fetchData = async () => {
      // Fetch geofazz data
      setLoading(true);
      const res = await GeoApiV2.getLocationByCodes({ codes: geofazzParams.provinceCodes });
      const newTreeState = (res?.data?.data || []).map((parent) => ({
        ...parent,
        child: filterChildrenByGeofazzParams(parent.child, parent.type, parent.id, geofazzParams),
      }));
      setTreeData(newTreeState);
      setLoading(false);
    };

    fetchData();
  }, [areaTerritories]);

  return (
    <>
      <h2 className="md:mt-0 mt-5">Territory</h2>
      <div className="overflow-y-scroll" style={{ minHeight: 340, maxHeight: 'calc(100vh - 350px)' }}>
        {(parentLoading || loading) && <Spin className="mx-auto p-8" />}
        {!loading && !parentLoading && (
          <Tree defaultExpandedKeys={geofazzParams.provinceCodes} loadData={onLoadData}>
            {renderTreeNodes(treeData)}
          </Tree>
        )}
      </div>
    </>
  );
}
TerritoriesTreeSection.propTypes = {
  areaTerritories: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TerritoriesTreeSection;
