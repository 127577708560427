import React from 'react';
import Layout from 'layout/main';
import { Button } from 'components';
import { PUBLIC_URL } from 'config/url';

export default function ErrorFallback() {
  const reloadToHomePage = () => {
    window.location.href = PUBLIC_URL;
  };

  return (
    <Layout title="Dashboard">
      <div className="flex justify-center items-center mt-52 flex-col">
        Oops... Something went wrong
        <Button className="mt-20" type="primary" onClick={reloadToHomePage}>
          Go To Home Page
        </Button>
      </div>
    </Layout>
  );
}
