import React from 'react';
import moment from 'moment';
import { capitalize } from 'utils/formatter/string';

export const columns = () => [
  {
    title: <p className="font-bold text-cf-gray">Timestamp</p>,
    dataIndex: 'updated_date_utc',
    key: 'updated_date_utc',
    width: 200,
    render: (text) => moment(text).format('DD MMMM YYYY (HH:mm)'),
  },
  {
    title: <p className="font-bold text-cf-gray">Action</p>,
    dataIndex: 'Reason',
    key: 'Reason',
    render: (text) => capitalize(text) || '-',
  },
  {
    title: <p className="font-bold text-cf-gray">Description</p>,
    dataIndex: 'description',
    key: 'description',
    render: (text) => capitalize(text) || '-',
  },
  {
    title: <p className="font-bold text-cf-gray">Updated by</p>,
    dataIndex: 'updated_by',
    key: 'updated_by',
    render: (text, record) => (text ? capitalize(text) : capitalize(record?.created_by || '-')),
  },
];

export default columns;
