import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';

function EmptyChart({ aspect }) {
  return (
    <div className="relative h-0 w-full" style={{ paddingBottom: `${(1 / aspect) * 100}%` }}>
      <div className="absolute top-0 right-0 left-0 bottom-0 flex justify-center items-center text-8xl text-gray-200 border-l border-b m-20">
        <div className="text-center -mt-10">
          <Icon type="area-chart" />
          <p className="text-lg">No Data</p>
        </div>
      </div>
    </div>
  );
}

EmptyChart.propTypes = {
  aspect: PropTypes.number.isRequired,
};

export default EmptyChart;
