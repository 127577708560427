import React from 'react';
import { Row, Col } from 'antd';

import SummarySection from './SummarySection';
import FilterQuerySection from '../../shared/FilterQuerySection';

// Chart
import TransactionAmount from './charts/TransactionAmount';
import TransactionCount from './charts/TransactionCount';
import TransactionMauCount from './charts/TransactionMauCount';
import TransactionContributionMargin from './charts/TransactionContributionMargin';
import TransactionAmountRepayment from './charts/TransactionAmountRepayment';

function KpiVisualizationTransaction() {
  return (
    <div>
      <FilterQuerySection />
      <SummarySection />
      <Row gutter={[16, 16]}>
        <Col span={24} lg={12}>
          <TransactionAmount />
        </Col>
        <Col span={24} lg={12}>
          <TransactionCount />
        </Col>
        <Col span={24} lg={12}>
          <TransactionMauCount />
        </Col>
        <Col span={24} lg={12}>
          <TransactionAmountRepayment />
        </Col>
        <Col span={24} lg={12}>
          <TransactionContributionMargin />
        </Col>
      </Row>
    </div>
  );
}

export default KpiVisualizationTransaction;
