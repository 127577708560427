import React, { useState, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';

import InputSearchQuery from 'components/InputSearchQuery';
import { Button, Table } from 'components';
import { AccountApi } from 'api/account';
import { getCookies } from 'utils/cookies';
import { CONFIG_COOKIES } from 'config/cookies';
import { MENU } from 'config/menu';
import { getQueryStringForPagination } from 'utils/query';
import Layout from 'layout/main';

import { columns } from './columns';

const PER_PAGE = 10;

function RoleManagement({ history, location }) {
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [metadata, setMetadata] = useState({});
  const sendError = useRollbarNotifier();

  const query = useMemo(() => getQueryStringForPagination(location.search), [location.search]);
  const params = useMemo(
    () => ({
      merchant_id: getCookies(CONFIG_COOKIES.MERCHANT_ID),
      per_page: PER_PAGE,
      asc: true,
      search: '',
    }),
    [],
  );

  const goToDetailPage = (roleId) => {
    history.push(`${MENU.ACCOUNT_MANAGEMENT}/role/${roleId}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data } = await AccountApi.get({
          ...params,
          ...query,
        });
        setDataList([...data.data]);
        setMetadata(data.metadata);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        sendError('Error fetching RoleManagement List', error);
      }
    };

    fetchData();
  }, [params, query]);

  return (
    <Layout title="Role Management">
      <div className="app-content">
        <div className="flex justify-between mb-10 mt-4">
          <InputSearchQuery className="md:w-328px md:mr-5 md:mb-0 mb-5" />
          <Button
            className="md:w-328px w-full md:mr-0 md:mb-0"
            style={{ width: 180 }}
            type="primary"
            onClick={() => {
              history.push(`${MENU.ACCOUNT_MANAGEMENT}/role/create`);
            }}
            icon="plus-square"
          >
            <span className="ml-2">Create New Role</span>
          </Button>
        </div>
        <Table
          loading={loading}
          columns={columns(goToDetailPage)}
          rowKey="id"
          dataSource={dataList}
          count={metadata.total_count}
          history={history}
          location={location}
          urlPush={MENU.ACCOUNT_MANAGEMENT}
        />
      </div>
    </Layout>
  );
}

RoleManagement.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(RoleManagement);
