import React from 'react';
import { flattenArray } from 'utils/array';
import { POSITION_TYPE } from 'config/users';

export const rawResponseToTreeObject = (obj) => {
  return {
    level: obj.level,
    type: obj.type,
    id: obj.id,
    pId: obj.parent_id,
    title: obj.name,
    label: obj.name,
    value: obj.id,
    key: obj.id,
    available: false,
    isLeaf: obj.type === POSITION_TYPE.VILLAGE,
    children: obj.child ? obj.child.map(rawResponseToTreeObject) : [],
  };
};

export const assignChildToParent = (dataTree, parentId, newChildren) => {
  return dataTree.map((node) => {
    if (node.key === parentId) {
      return {
        ...node,
        children: newChildren,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: assignChildToParent(node.children, parentId, newChildren),
      };
    }
    return node;
  });
};

export const checkAvailability = async (dataTree, territoryAvailabilityAPICall) => {
  const allTerritoriesIds = flattenArray(dataTree).map((x) => x.id);
  const res = await territoryAvailabilityAPICall(allTerritoriesIds);
  const territoryAvailable = res?.data?.territory_available || [];

  const checkEveryBranch = (nodes) => {
    if (nodes.length === 0) return [];

    return nodes.map((node) => {
      const children = node.children || [];
      return {
        ...node,
        available: territoryAvailable[node?.id],
        children: checkEveryBranch(children),
      };
    });
  };

  return checkEveryBranch(dataTree);
};

export const renderTreeData = ({ treeData, withAvailability }) => {
  return treeData?.map((node) => ({
    ...node,
    title: (
      <div className="flex-space-between">
        <div>{node.label}</div>
        {withAvailability && (
          <div className="small-text" style={{ color: node.available ? 'green' : 'red' }}>
            {node.available ? 'Available' : 'Filled'}
          </div>
        )}
      </div>
    ),
    children: node?.children ? renderTreeData({ treeData: node?.children || [], withAvailability }) : [],
  }));
};

export const SEARCH_AREA_TYPES = 'search_area_type';
export const SEARCH_AREA_VALUE = 'search_area_value';
export const SEARCH_AREA_TERRITORIES = 'search_area_territories';
