import ReactGA from 'react-ga4';
import { CONFIG_COOKIES } from 'config/cookies';
import { getCookies } from 'utils/cookies';
import { isProduction } from 'utils/access-right';

function useGAEvents() {
  const merchantId = getCookies(CONFIG_COOKIES.MERCHANT_ID);

  const downloadOtherReport = () => {
    if (!isProduction) return;
    const eventName = 'btn:other_reports:download_other_report';
    const eventParams = {
      label: 'Download other report',
      merchant_id: merchantId,
    };
    ReactGA.event(eventName, eventParams);
  };

  const GAEvents = { downloadOtherReport };

  return GAEvents;
}

export default useGAEvents;
