import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { DatePicker } from 'antd';
import { generateUrlQueryParams, parseUrlQueryParams } from 'utils/query';
import './SelectRangePickerQuery.scss';

const { RangePicker } = DatePicker;
const DATE_FORMAT = 'YYYY-MM-DD';

function SelectRangePickerQuery({ location, history, className = '', initialValue, ranges, disabledDate }) {
  const [value, setValue] = useState(initialValue);
  const { start_filter: startFilter, end_filter: endFilter } = useMemo(() => parseUrlQueryParams(location.search), [location.search]);

  const updateSearchQuery = (momentArr, stingArr) => {
    const startDate = stingArr[0];
    const endDate = stingArr[1];
    history.push({
      search: generateUrlQueryParams({
        ...parseUrlQueryParams(location.search),
        start_filter: startDate,
        end_filter: endDate,
        page: 1,
      }),
    });
  };

  useEffect(() => {
    const start = moment(startFilter, DATE_FORMAT);
    const end = moment(endFilter, DATE_FORMAT);

    if (start.isValid() && end.isValid()) {
      return setValue([start, end]);
    }

    return setValue(initialValue);
  }, [endFilter, startFilter]);

  return (
    <div className={`${className || ''}`}>
      <RangePicker
        value={value}
        onChange={updateSearchQuery}
        size="large"
        className="w-full h-48px text-cf-14"
        disabledDate={disabledDate}
        ranges={ranges}
      />
    </div>
  );
}

SelectRangePickerQuery.propTypes = {
  className: PropTypes.string,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  initialValue: PropTypes.array.isRequired,
  ranges: PropTypes.object,
  disabledDate: PropTypes.any,
};

SelectRangePickerQuery.defaultProps = {
  className: '',
  ranges: null,
  disabledDate: null,
};

export default withRouter(SelectRangePickerQuery);
