import React from 'react';
import PropTypes from 'prop-types';
import { toTitleCase } from 'utils/formatter/string';

export default function DefaultLegend({ payload }) {
  return (
    <ul className="ml-24 mt-10">
      {payload.map((entry) => (
        <li key={`item-${entry.value}`} className="flex items-center">
          <div style={{ background: entry.color }} className="h-3 w-20" />
          <p className="ml-5">{toTitleCase(entry.value)}</p>
        </li>
      ))}
    </ul>
  );
}

DefaultLegend.propTypes = { payload: PropTypes.array.isRequired };
