import React from 'react'
import moment from 'moment'
import { Button } from 'components'
import { toTitleCase } from 'utils/formatter/string'

const Title = ({ children, className }) => (
	<p className={`font-bold text-cf-14 pt-6 pb-6 text-c4-gray ${className}`}>
		{children}
	</p>
)

const FORMAT_TIME = 'DD MMM YYYY'

export const columns = (onClickDetail) => [
	{
		title: <Title className="ml-3">Agent Name</Title>,
		dataIndex: 'name',
		key: 'name',
		render: (text) => <div className="ml-3">{toTitleCase(text)}</div>,
	},
	{
		title: <Title>Business Name</Title>,
		dataIndex: 'business_name',
		key: 'business_name',
		render: (text) => toTitleCase(text) || '-',
	},
	{
		title: <Title>Submitted Date</Title>,
		dataIndex: 'created_date_utc',
		key: 'created_date_utc',
		render: (text) => <div>{moment(text).format(FORMAT_TIME)}</div>,
	},
	{
		title: <Title>Updated Date</Title>,
		dataIndex: 'updated_date_utc',
		key: 'updated_date_utc',
		render: (text) => <div>{moment(text).format(FORMAT_TIME)}</div>,
	},
	{
		title: <Title>Location</Title>,
		dataIndex: 'location',
		key: 'location',
		render: (text) => toTitleCase(text) || '-',
	},
	{
		title: <Title>Status</Title>,
		dataIndex: 'status',
		key: 'status',
		render: (text) =>
			toTitleCase(text === 'inreview' ? 'In Review' : text) || '-',
	},
	{
		title: <Title>Review By</Title>,
		dataIndex: 'reviewed_by',
		key: 'reviewed_by',
		render: (text) => toTitleCase(text.split('/')[0]) || '-', // CAN-982 Acquisition Review : User of 'Review By' is too long
	},
	// {
	// 	title: <Title>Business Type</Title>,
	// 	dataIndex: 'business_type',
	// 	key: 'business_type',
	// 	render: (text) => toTitleCase(text) || '-',
	// },
	// {
	// 	title: <Title>Phone</Title>,
	// 	dataIndex: 'phone',
	// 	key: 'phone',
	// 	render: (text) => <>{text || '-'}</>,
	// },
	// {
	// 	title: <Title>Type</Title>,
	// 	dataIndex: 'type',
	// 	key: 'type',
	// 	render: (text) => toTitleCase(text) || '-',
	// },
	{
		title: <Title>Action</Title>,
		dataIndex: 'id',
		key: 'action',
		align: 'center',
		render: (id) => (
			<Button
				size="small"
				className="mx-auto"
				onClick={() => onClickDetail(id)}>
				View Detail
			</Button>
		),
	},
]
