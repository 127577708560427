import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Form, Modal, Input } from 'antd';
import { Button } from 'components';
import { areasApi } from 'api/areas';
import { POSITION_TYPE } from 'config/users';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import AreaFormItem from '../../shared/area-form-item';

const { TextArea } = Input;

/**
 * EditAreaPresetModal component for editing an area preset.
 * NOTES: All area preset only can be edited with national type
 * @param areaPresetDetail - The details of the area preset.
 * @param visible - Flag indicating whether the modal is visible.
 * @param close - Function to close the modal.
 * @param form - The form object for form validation and field handling.
 * @param onSuccess - Function to be called on successful update.
 * @returns The rendered EditAreaPresetModal component.
 */
function EditAreaPresetModal({ areaPresetDetail, visible, close, form, onSuccess }) {
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const sendError = useRollbarNotifier();

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);

        const reqBody = {
          name: values.name,
          description: values.reason,
          territories: values.search_area_territories.map((loc) => loc.value),
        };
        try {
          await areasApi.updateAreaPreset(areaPresetDetail?.id, reqBody);
          setLoading(false);
          close();
          Modal.success({
            title: 'Update Area Preset Success',
            onOk: onSuccess,
          });
        } catch (error) {
          setLoading(false);
          sendError(`Error when handle submit EditAreaPresetModal`, error);
        }
      }
    });
  };

  return (
    <Modal
      destroyOnClose
      title="Edit Area"
      footer={
        <div className="flex-end">
          <Button onClick={close}>Cancel</Button>
          <Button type="primary" htmlType="submit" loading={loading} onClick={handleSubmit}>
            Save Changes
          </Button>
        </div>
      }
      visible={visible}
      onCancel={close}
    >
      <Form>
        <Form.Item label="Area Name">
          {getFieldDecorator('name', {
            rules: [],
            initialValue: areaPresetDetail?.name,
          })(<Input />)}
        </Form.Item>
        <AreaFormItem
          initialValues={{
            search_area_type: {
              key: POSITION_TYPE.NATIONAL,
              label: 'National',
            },
            search_area_territories: areaPresetDetail?.territories,
          }}
          form={form}
          withoutRole
          preventChangeType
        />
        <Form.Item label="Reason">
          {getFieldDecorator('reason', {
            rules: [],
          })(<TextArea rows={4} />)}
        </Form.Item>
      </Form>
    </Modal>
  );
}

EditAreaPresetModal.propTypes = {
  form: PropTypes.object.isRequired,
  areaPresetDetail: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default withRouter(Form.create()(EditAreaPresetModal));
