import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'qs';

import { Table } from 'components';
import HistoryCard from 'components/Molecules/HistoryCard/history-card';
import { AccountApi } from 'api/account';
import { MENU } from 'config/menu';
import { CONFIG_COOKIES } from 'config/cookies';
import { getCookies } from 'utils/cookies';
import { getQueryStringForPagination } from 'utils/query';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import { columns } from './columns';

const PAGE_SIZE = 10;

function HistoryLogs({ accountId, location, history }) {
  const [loading, setLoading] = useState(false);
  const [roleLogs, setRoleLogs] = useState([]);

  const query = getQueryStringForPagination(location.search);
  const { historyPage } = query;
  const [totalData, setTotalData] = useState(null);
  const sendError = useRollbarNotifier();

  const onChangePage = (pageNum) => {
    const pageQuery = { ...query, historyPage: pageNum };
    if (pageNum === 1) delete pageQuery.historyPage;
    history.push(`${MENU.ACCOUNT_MANAGEMENT}/role/${accountId}?${qs.stringify(pageQuery)}`);
  };

  useEffect(() => {
    const fetchHistory = async () => {
      setLoading(true);
      try {
        const res = await AccountApi.getHistory({
          merchant_id: getCookies(CONFIG_COOKIES.MERCHANT_ID),
          role_id: accountId,
          page: historyPage,
          per_page: PAGE_SIZE,
        });
        setRoleLogs(res.data.data);
        setTotalData(res.data.metadata.total_count);
        setLoading(false);
      } catch (error) {
        sendError(`Error when fetch get history role management`, error);

        setLoading(false);
      }
    };
    fetchHistory();
  }, [accountId, historyPage]);

  return (
    <HistoryCard>
      <Table
        loading={loading}
        dataSource={roleLogs}
        columns={columns()}
        rowKey="updated_date_utc"
        withoutPagination
        pagination={{
          current: +historyPage || 1,
          total: totalData,
          pageSize: PAGE_SIZE,
          onChange: onChangePage,
        }}
      />
    </HistoryCard>
  );
}

HistoryLogs.propTypes = {
  accountId: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(HistoryLogs);
