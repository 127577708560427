import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// Components
import { Col, Row } from 'antd';
import Layout from 'layout/main';
import { Button, Card } from 'components';
import ListTableView from 'components/Atoms/ListTableView';
import Breadcrumb from 'components/breadcrumb';

// API
import { positionsApi } from 'api/positions';
import { usersApi } from 'api/users';

import { MENU } from 'config/menu';
import useMediaQuery from 'customHooks/useMediaQuery';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';

// Local Components
import DeletePositionConfirmationModal from './delete-position-modal';
import EditPositionModal from './edit-position-modal';
import ExpelModal from './expel-modal';
import PositionEventsCard from './position-events-card';
import TerritoriesTreeSection from '../../shared/territoriesTreeSection/index';

const GREAT_NUMBER = 999;
function PositionDetailPage({ match }) {
  const [position, setPosition] = useState({});
  const [roles, setRoles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [showEditFormModal, setShowEditFormModal] = useState(false);
  const [showExpelModal, setShowExpelModal] = useState(false);
  const sendError = useRollbarNotifier();
  const positionId = match.params.id;

  // responsive layout
  const isMobile = useMediaQuery('(max-width: 768px)');

  const [lastPageReload, setLastPageReload] = useState(null);
  const reload = () => setLastPageReload(new Date());

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch roles
        if (roles == null) {
          const rolesRes = await usersApi.getRoles({ per_page: GREAT_NUMBER });
          setRoles(rolesRes.data.data);
        }

        // Fetch position detail
        const res = await positionsApi.getPosition(positionId, {});
        const positionDetail = res.data;
        setPosition(positionDetail);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        sendError(`Error when fetchData PositionDetailPage`, error);
      }
    };

    fetchData();
  }, [positionId, lastPageReload]);

  return (
    <Layout title="Position Management">
      <Breadcrumb
        data={[
          {
            link: `${MENU.POSITION_MANAGEMENT}/position`,
            title: 'Position',
          },
          {
            link: null,
            title: <b>Detail Information</b>,
          },
        ]}
      />
      <div className="app-content__details">
        <Card loading={false} className="card" bordered={false}>
          <div className="mb-1">Territory ID</div>
          <div className="title mb-1">{position.position_id}</div>
        </Card>

        <Card loading={false} bordered={false} className="card">
          <Row type="flex">
            <Col span={isMobile ? 24 : 8}>
              <ListTableView
                loading={loading}
                data={{
                  'Full Name ': position.user_name || '-',
                  'Position Label ': position.position_label,
                  'Role ': position.role_name,
                  'User Email ': position.user_email || '-',
                  'Date Created ': position.created_date_formatted,
                  'Created by ': position.created_by,
                }}
              />
            </Col>
            <Col className="md:border-l md:mt-0 mt-10 md:border-t-0 border-t md:pl-10 pl:0" span={isMobile ? 24 : 14}>
              <TerritoriesTreeSection loading={loading} areaTerritories={position?.area_territories || []} />
            </Col>
          </Row>
        </Card>

        <Card bodyStyle={{ padding: '12px 24px' }} className="p-0">
          <div className="flex">
            <Button loading={loading} onClick={() => setShowEditFormModal(true)} style={{ marginRight: 8, width: isMobile ? 140 : 145 }}>
              Edit
            </Button>
            <Button
              loading={loading}
              type="danger"
              onClick={() => setDeleteConfirmationModal(true)}
              style={{ width: isMobile ? 140 : 145, marginLeft: 'auto' }}
            >
              Delete
            </Button>
          </div>
        </Card>
        <PositionEventsCard positionId={positionId} />
      </div>

      <DeletePositionConfirmationModal
        position={position}
        visible={deleteConfirmationModal}
        close={() => setDeleteConfirmationModal(false)}
      />

      <ExpelModal position={position} visible={showExpelModal} close={() => setShowExpelModal(false)} reloadParent={reload} />

      <EditPositionModal
        onExpelButtonClicked={() => {
          setShowEditFormModal(false);
          setShowExpelModal(true);
        }}
        position={position}
        roles={roles}
        visible={showEditFormModal}
        close={() => setShowEditFormModal(false)}
        reloadParent={reload}
      />
    </Layout>
  );
}

export default withRouter(PositionDetailPage);

PositionDetailPage.propTypes = {
  match: PropTypes.object.isRequired,
};
