import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import qs from 'qs'
import { Table } from 'components'

import HistoryCard from '../../../components/Molecules/HistoryCard/history-card'
import { MerchantApi } from '../../../api/merchant'
import { columns } from './columns'

import { getQueryStringForPagination } from '../../../utils/query'
import { MENU } from '../../../config/menu'

const PAGE_SIZE = 10

function HistoryLogs({ merchantId, location, history, lastUpdate }) {
	const [loading, setLoading] = useState(false)
	const [merchantLogs, setMerchantLogs] = useState([])

	// pagination
	const query = getQueryStringForPagination(location.search)
	const { historyPage } = query
	const [totalData, setTotalData] = useState(null)

	const onChangePage = (pageNum) => {
		const pageQuery = { ...query, historyPage: pageNum }
		if (pageNum === 1) delete pageQuery.historyPage
		history.push(
			`${MENU.MERCHANT_MANAGEMENT_LIST}/${merchantId}?${qs.stringify(
				pageQuery,
			)}`,
		)
	}

	useEffect(() => {
		const fetchHistory = async () => {
			setLoading(true)
			try {
				const res = await MerchantApi.getHistory({
					merchant_id: merchantId,
					page: historyPage,
					per_page: PAGE_SIZE,
				})
				setMerchantLogs(res.data.data)
				setTotalData(res.data.metadata.total_count)
				setLoading(false)
			} catch (error) {
				console.error(error)
			}
		}
		fetchHistory()
	}, [merchantId, historyPage, lastUpdate])

	return (
		<HistoryCard>
			<Table
				loading={loading}
				dataSource={merchantLogs}
				columns={columns()}
				rowKey="updated_date_utc"
				withoutPagination
				pagination={{
					current: +historyPage || 1,
					total: totalData,
					pageSize: PAGE_SIZE,
					onChange: onChangePage,
				}}
			/>
		</HistoryCard>
	)
}

export default withRouter(HistoryLogs)
