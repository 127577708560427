import * as React from 'react'

const IcPlus = (props) => (
	<svg
		width={24}
		height={24}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18.667 11H13V5.333C13 4.6 12.656 4 12 4s-1 .6-1 1.333V11H5.333C4.6 11 4 11.267 4 12s.6 1 1.333 1H11v5.667C11 19.4 11.267 20 12 20s1-.6 1-1.333V13h5.667C19.4 13 20 12.733 20 12s-.6-1-1.333-1Z"
			fill="#fff"
		/>
	</svg>
)

export default IcPlus
