import React from 'react';
import PropTypes from 'prop-types';

export default function ChartTitle({ title, startDate, endDate }) {
  const getMonthRange = () => {
    const startDateStr = startDate?.format('MMMM YYYY');
    const endDateStr = endDate?.format('MMMM YYYY');

    if (startDateStr === endDateStr) {
      return startDateStr;
    }

    return `${startDate.format('MMMM YYYY')} - ${endDate.format('MMMM YYYY')}`;
  };

  return (
    <div>
      <p className="font-bold text-cf-16 mb-2 text-cf-gray">{title}</p>
      <p className="text-cf-12 mb-10 text-cf-lightGray">{getMonthRange()}</p>
    </div>
  );
}

ChartTitle.propTypes = {
  title: PropTypes.string.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
};
