import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Select, Form, Modal, Input, Radio, message } from 'antd';
import { Button, SearchVillage } from 'components';
import { usersApi } from 'api/users';
import { getCookies } from 'utils/cookies';
import { CONFIG_COOKIES } from 'config/cookies';
import { EMAIL_PATTERN, NAME_PATTERN, PHONE_PATTERN } from 'utils/string-pattern';
import PositionFormItem from '../position-form-item';

const EditUserModal = Form.create()(({ user, roles, visible, close, form, reloadParent }) => {
  const [loading, setLoading] = useState(false);
  const [assignRoleBy, setAssignRoleBy] = useState('role');
  const [selectedPosition, setSelectedPosition] = useState(null);

  useEffect(() => {
    if (!visible) return;
    setAssignRoleBy((prev) => (user.position_id ? 'position' : prev));
    setSelectedPosition({ role_id: user.role_id });
  }, [user.position_id, visible]);

  const { getFieldDecorator, setFields, getFieldsValue } = form;

  const isUserHasRoleId = !!user.role_id;
  const isUserHasPosition = (() => {
    if (!user.position_id) return false;
    return true;
  })();
  const isNotAllowedToEditPosition = isUserHasRoleId && isUserHasPosition;

  const onPositionChange = (dt) => {
    setSelectedPosition(dt);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (err) return;

      const payload = {
        merchant_id: getCookies(CONFIG_COOKIES.MERCHANT_ID),
        email: values.email.toLowerCase(),
        name: values.name,
        phone: values.phone,
        position_id: (() => {
          if (isNotAllowedToEditPosition) return null;
          if (assignRoleBy === 'position') return values?.position?.key;
          return null;
        })(),
        role_id: assignRoleBy === 'position' ? selectedPosition?.role_id : values.role_id,
        location: values.location.label,
        location_code: values.location.key,
      };

      if (assignRoleBy === 'position' && !isNotAllowedToEditPosition && !payload.position_id) {
        message.error('Please select position');
        return;
      }

      try {
        setLoading(true);
        const res = await usersApi.update(user.id, payload);
        setLoading(false);
        if (!res) return;
        close();

        Modal.success({
          title: 'Success',
          content: `${user.name} successfully edited!`,
          onOk: () => reloadParent(),
        });
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    });
  };

  return (
    <Modal width={520} destroyOnClose title="Edit User" footer={null} visible={visible} onCancel={close}>
      {isNotAllowedToEditPosition && (
        <div
          className="border p-5 mb-6"
          style={{
            background: '#FDF6DC',
            borderColor: '#F2C900',
            borderRadius: 4,
          }}
        >
          User masih memiliki posisi, silahkan expel user terlebih dahulu di Position Management menu untuk mengubah role
        </div>
      )}
      <Form onSubmit={handleSubmit}>
        <Form.Item label="Email">
          {getFieldDecorator('email', {
            rules: [
              { required: true, message: 'Please input email' },
              {
                pattern: EMAIL_PATTERN,
                message: 'Please input a valid Google email',
              },
            ],
            initialValue: user.email,
          })(<Input placeholder="Email Address" />)}
        </Form.Item>

        <Form.Item label="Full Name">
          {getFieldDecorator('name', {
            rules: [
              { required: true, message: 'Please input name' },
              {
                pattern: NAME_PATTERN,
                message: 'Please input valid name',
              },
            ],
            initialValue: user.name,
          })(<Input placeholder="Full Name" />)}
        </Form.Item>

        <Form.Item label="Phone Number">
          {getFieldDecorator('phone', {
            rules: [
              {
                pattern: PHONE_PATTERN,
                message: 'Please input valid phone number',
              },
            ],
            initialValue: user.phone,
          })(<Input type="phone" placeholder="Phone Number" />)}
        </Form.Item>

        <Form.Item label="Location">
          {getFieldDecorator('location', {
            rules: [{ required: true, message: 'Please input location' }],
            initialValue: user.location_code ? { key: user.location_code, label: user.location } : undefined,
          })(<SearchVillage />)}
        </Form.Item>

        <h2 className="my-2 text-gray-600">Assign Role By</h2>
        <Radio.Group className="mb-5" buttonStyle="solid" value={assignRoleBy} onChange={({ target }) => setAssignRoleBy(target.value)}>
          <Radio.Button value="position">Position</Radio.Button>
          <Radio.Button value="role">Role</Radio.Button>
        </Radio.Group>

        {assignRoleBy === 'position' && (
          <PositionFormItem
            withTitle={false}
            preventEditWithInitValue
            setFields={setFields}
            getFieldDecorator={getFieldDecorator}
            data={getFieldsValue()}
            initValue={{
              key: user.position_id,
              label: user.position_label || '',
            }}
            onChangePosition={onPositionChange}
            // isPositionRequired={assignRoleBy === 'position'}
          />
        )}
        {assignRoleBy === 'role' && (
          <Form.Item label="Role">
            {getFieldDecorator('role_id', {
              rules: [{ required: true, message: 'Please select role' }],
              initialValue: user.role_id,
            })(
              <Select disabled={isNotAllowedToEditPosition} placeholder="Search Role" style={{ width: '100%' }}>
                {roles &&
                  roles.map((role) => (
                    <Select.Option key={role.code} value={role.id}>
                      {role.name}
                    </Select.Option>
                  ))}
              </Select>,
            )}
          </Form.Item>
        )}

        <div className="flex mt-5">
          <Button className="mr-3 ml-auto" onClick={close}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save Changes
          </Button>
        </div>
      </Form>
    </Modal>
  );
});

export default withRouter(EditUserModal);
