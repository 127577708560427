import { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import { getQuarterRanges, getMonthRanges, getYearRanges } from 'utils/date';
import { queryStringToObject } from 'utils/query';
import { salesFunnelApi } from 'api/reports';

const DATE_FORMAT = 'YYYY-MM-DD';

function useSalesFunnel({ defaultQueries }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const sendError = useRollbarNotifier();

  const query = useMemo(() => ({ ...defaultQueries, ...queryStringToObject(history.location.search) }), [history.location.search]);

  const getDateRanges = () => {
    const { type, month, quarter, year } = query;
    switch (type) {
      case 'monthly':
        return getMonthRanges({ month, year });
      case 'quarterly':
        return getQuarterRanges({ q: quarter, year });
      case 'yearly':
        return getYearRanges({ year });
      default:
        return [moment().startOf('month'), moment().endOf('month')];
    }
  };

  useEffect(() => {
    const [startDate, endDate] = getDateRanges();
    const payload = {
      start_date: startDate.format(DATE_FORMAT),
      end_date: endDate.format(DATE_FORMAT),
    };

    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await salesFunnelApi.getSalesFunnel(payload, { signal: controller.signal });
        setData(res.data.data || res.data);
        setLoading(false);
      } catch (error) {
        setData({});

        if (error.code === 'ERR_CANCELED') {
          setLoading(true);
        } else {
          setLoading(false);
          sendError(`Error when fetching getVisitationFunnel hooks`, error);
        }
      }
    };

    fetchData();

    return () => controller.abort();
  }, [history.location.search]);

  return { data, loading };
}

export default useSalesFunnel;
