import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Input, notification } from 'antd'

import { MerchantApi } from 'api/merchant'

const { TextArea } = Input

function DeleteMercantConfirmation({ visible, close, merchant, history }) {
	const [reasonValue, setReasonValue] = useState('')
	const [loading, setLoading] = useState(false)

	const handleDelete = async () => {
		setLoading(true)
		const res = await MerchantApi.delete({
			merchant_id: merchant.id,
			reason: reasonValue,
		})
		if (!res || !res.data) return
		notification.success({
			message: 'Success',
			description: 'Merchant has been Deleted successfully',
		})
		setReasonValue('')
		close()
		history.push('/merchant-management')
		setLoading(false)
	}
	return (
		<Modal
			loading={loading}
			destroyOnClose
			maskClosable={false}
			onOk={handleDelete}
			title={<span style={{ fontWeight: 700 }}>Delete Merchant</span>}
			visible={visible}
			onCancel={() => {
				close()
				setReasonValue('')
			}}
			okText="Delete"
			okButtonProps={{ disabled: !reasonValue }}
			cancelText="Cancel"
			centered>
			<p style={{ fontSize: 16, textAlign: 'center' }}>
				Are you sure want to delete this Merchant?
			</p>
			<p className="text-cf-14 mt-8 mb-4">
				Reason<span style={{ color: '#ac2f24' }}> *</span>
			</p>
			<TextArea
				rows={4}
				value={reasonValue}
				onChange={(e) => setReasonValue(e.target.value)}
			/>
		</Modal>
	)
}

export default withRouter(DeleteMercantConfirmation)
