import React from 'react';
import { Button as ButtonAntd } from 'antd';
import PropTypes from 'prop-types';

// BUTTON TYPE
// 1. primary
// 2. default
// 3. danger
// 4. danger-outlined

const SMALL_SIZE_STYLE = {
  width: 98,
  height: 34,
  fontSize: 12,
};

const getInitStyle = (type, isDisabled, size) => {
  let BASE_STYLE = {
    padding: `5px 10px`,
    borderRadius: 4,
    fontWeight: 700,
    fontSize: 14,
    height: 48,
    width: 145,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  };

  if (size === 'small') BASE_STYLE = { ...BASE_STYLE, ...SMALL_SIZE_STYLE };

  const disabledStyle = {
    ...BASE_STYLE,
    border: '1px solid #E0E0E0',
    color: '#757575',
    backgroundColor: '#E0E0E0',
    cursor: 'not-allowed',
  };

  const primaryStyle = {
    ...BASE_STYLE,
    border: '1px solid #1AA39F',
    color: '#fff',
    backgroundColor: '#1AA39F',
  };

  const dangerStyle = {
    ...BASE_STYLE,
    border: '1px solid #AC2C24',
    color: '#fff',
    backgroundColor: '#AC2C24',
  };

  const dangerOutlinedStyle = {
    ...BASE_STYLE,
    border: '1px solid #AC2C24',
    color: '#AC2C24',
    backgroundColor: '#fff',
  };

  const warningStyle = {
    ...BASE_STYLE,
    border: '1px solid #FFA500',
    color: '#fff',
    backgroundColor: '#FFA500',
  };

  switch (true) {
    case isDisabled:
      return disabledStyle;
    case type === 'primary':
      return primaryStyle;
    case type === 'danger':
      return dangerStyle;
    case type === 'warning':
      return warningStyle;
    case type === 'danger-outlined':
      return dangerOutlinedStyle;
    default:
      return BASE_STYLE;
  }
};

function Button({
  type = 'default',
  className = '',
  style = {},
  disabled = false,
  loading,
  size = 'default',
  children,
  onClick = () => {},
  htmlType = 'button',
}) {
  const initialStyle = getInitStyle(type, disabled, size);
  return (
    <ButtonAntd
      onClick={onClick}
      type={type}
      className={`${className}`}
      style={{ ...initialStyle, ...style }}
      loading={loading}
      disabled={disabled}
      htmlType={htmlType}
    >
      <div className={`flex align-middle justify-center ${loading ? 'w-3/5' : 'w-full'}`}>{children}</div>
    </ButtonAntd>
  );
}

Button.propTypes = {
  type: PropTypes.oneOf(['primary', 'default', 'danger', 'danger-outlined', 'warning']),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  style: PropTypes.object,
  size: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  htmlType: PropTypes.string,
};

Button.defaultProps = {
  type: 'default',
  className: '',
  style: {},
  disabled: false,
  loading: false,
  size: 'default',
  children: '',
  onClick: () => {},
  htmlType: 'button',
};

export default Button;
