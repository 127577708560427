import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

function CountDownBefore24Hrs({ reviewedDate }) {
  const [countdown, setCountdown] = useState(null);

  useEffect(() => {
    const calculateCountdown = () => {
      if (reviewedDate) {
        const maxDate = moment(reviewedDate).add(24, 'hour');
        const currentDateTime = moment();

        const differenceInSeconds = maxDate.diff(currentDateTime, 'seconds');

        if (differenceInSeconds > 0 && differenceInSeconds <= 24 * 60 * 60) {
          const duration = moment.duration(differenceInSeconds, 'seconds');
          const hours = duration.hours().toString().padStart(2, '0');
          const minutes = duration.minutes().toString().padStart(2, '0');
          const seconds = duration.seconds().toString().padStart(2, '0');

          setCountdown(`${hours}:${minutes}:${seconds}`);
        } else {
          setCountdown('0'); // Reset countdown if not within the 24-hour range
        }
      }
    };

    const interval = setInterval(calculateCountdown, 1000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [reviewedDate]);

  return <p>{`Time left: ${countdown}`}</p>;
}

CountDownBefore24Hrs.propTypes = {
  reviewedDate: PropTypes.string.isRequired,
};

export default CountDownBefore24Hrs;
