import React, { useState } from 'react'

import { withRouter } from 'react-router-dom'
import { Card } from 'antd'
import IconArrowUp from '../../../assets/ic-arrow-up'
import './history-card.scss'

function HistoryCard({ children }) {
	const [showLogs, setShowLogs] = useState(true)
	const [arrowUpDelay, setArrowUpDelay] = useState(false)
	const [arrowUp, setArrowUp] = useState(false)

	const scrollToBottom = () => {
		window.scrollTo({
			left: 0,
			top: document.body.scrollHeight,
			behavior: 'smooth',
		})
	}

	const onClickArrow = () => {
		setArrowUp(!arrowUp)
		setTimeout(() => {
			setArrowUpDelay(!arrowUpDelay)
			setShowLogs(!showLogs)
			if (showLogs) return
			scrollToBottom()
		}, 300)
	}

	return (
		<Card
			bordered={false}
			className="card lastEvent"
			bodyStyle={{ padding: '24px' }}
			style={{ marginBottom: 0 }}>
			<div style={{ display: 'flex', marginBottom: arrowUpDelay ? 0 : 30 }}>
				<p className="titleBig">Last Event</p>
				<div className="activity" onClick={onClickArrow}>
					<p>{arrowUp ? 'Hide activity' : 'Show activity'}</p>
					<div className={arrowUp ? '' : 'rotate90'}>
						<IconArrowUp />
					</div>
				</div>
			</div>
			{showLogs && !arrowUpDelay && (
				<div className="app-table">
					<div className="app-table__table-container">{children}</div>
				</div>
			)}
		</Card>
	)
}

export default withRouter(HistoryCard)
