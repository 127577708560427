import React, { useState, useEffect } from 'react';
import { Select, Spin } from 'antd';

import { debounce } from '../../utils/others';

const SearchSelect = React.forwardRef(({ isEffect, effect, api, wordLimit = 1, onChange = () => {}, ...props }, ref) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    isEffect && setData([]);
  }, [effect, isEffect]);

  const [fetching, setFetching] = useState(false);
  const fetchData = async (value) => {
    if (!value || (value && value.length < wordLimit)) return;
    setData([]);
    setFetching(true);
    const res = await api(value);
    setData(res);
    setFetching(false);
  };
  const debounceFetchData = debounce(fetchData, 1000);

  const onSelectChange = (val, opt) => {
    const selectedData =
      data.find((option) => {
        if (!option?.value) return false;
        return val?.key === option?.value;
      }) || {};

    // Send all data object instead of only { key, value }
    onChange({ ...val, ...selectedData }, opt);
  };

  return (
    <Select
      showSearch
      defaultActiveFirstOption={false}
      labelInValue
      placeholder="Search..."
      notFoundContent={fetching ? <Spin size="small" /> : <div>No data</div>}
      filterOption={false}
      onSearch={debounceFetchData}
      style={{ width: '100%' }}
      onChange={onSelectChange}
      {...props}
      ref={ref}
    >
      {data.map((d) => (
        <Select.Option {...d} key={d.value} label={d.label || d.text}>
          {d.text}
        </Select.Option>
      ))}
    </Select>
  );
});

export default SearchSelect;
