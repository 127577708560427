import React from 'react';
import { Card, Row, Col } from 'antd';
import ListTableView from 'components/Atoms/ListTableView';
import Layout from 'layout/main';
import Breadcrumb, { BREADCRUMB_ITEMS } from 'components/breadcrumb';
import { Button } from 'components';
import useMediaQuery from 'customHooks/useMediaQuery';

import DeleteModal from './delete-modal';
import EditModal from './edit-area-preset-modal';
import TerritoriesTreeSection from '../../shared/territoriesTreeSection/index';

import useAreaPresetDetail from './states';
import './detail.scss';

function AreaPresetDetail() {
  const isMobile = useMediaQuery('(max-width: 768px)');

  const {
    // Props
    areaPresetDetail,
    isLoading,
    showDeleteModal,
    setShowDeleteModal,
    deleteAreaPreset,
    showEditModal,
    setShowEditModal,
    reload,
  } = useAreaPresetDetail();

  return (
    <Layout title="Area Preset Detail">
      <Breadcrumb items={[BREADCRUMB_ITEMS.PM, BREADCRUMB_ITEMS.AREA_PRESET_DETAIL]} />
      <div className="app-content__details position-management-detail">
        <Card loading={isLoading}>
          <div>
            <div className="position-management-detail__info">
              <Row type="flex" align="middle" justify="space-between">
                <Col span={isMobile ? 24 : 8}>
                  <div className="title mb-4">Area Preset Information</div>
                  <ListTableView
                    loading={isLoading}
                    data={{
                      'Area Preset Name ': areaPresetDetail?.data?.name || '-',
                    }}
                  />
                </Col>
              </Row>
            </div>
            <div className="position-management-detail__info">
              <TerritoriesTreeSection areaTerritories={areaPresetDetail?.data?.territories || []} loading={isLoading} />
            </div>
          </div>
        </Card>
        <Card className="-mt-1 p-0" bodyStyle={{ padding: '12px 24px' }} loading={isLoading}>
          <div className="flex">
            <Button loading={isLoading} onClick={() => setShowEditModal(true)} style={{ marginRight: 8, width: isMobile ? 140 : 145 }}>
              Edit
            </Button>
            <Button
              type="danger"
              className="ml-auto"
              loading={isLoading}
              onClick={() => setShowDeleteModal(true)}
              style={{ width: isMobile ? 140 : 145 }}
            >
              Delete
            </Button>
          </div>
        </Card>
      </div>
      <DeleteModal
        visible={showDeleteModal}
        close={() => setShowDeleteModal(false)}
        payload={areaPresetDetail?.data}
        onOk={deleteAreaPreset}
      />
      <EditModal
        visible={showEditModal}
        close={() => setShowEditModal(false)}
        areaPresetDetail={areaPresetDetail?.data}
        onSuccess={reload}
      />
    </Layout>
  );
}

export default AreaPresetDetail;
