export const PERMISSION_MENU = {
  ACQUISITION_REVIEW: 'acquisition_review',
  USER_MANAGEMENT: 'user_management',
  POSITION_MANAGEMENT: 'position_management',
  REPORTING_TOOLS: 'reporting_tools',
  ACCOUNT_MANAGEMENT: 'account_management',
  MERCHANT_MANAGEMENT: 'merchant_management',
  VISIT_ASSIGNMENT: 'visitation_assignment',
};

export const PERMISSION_TYPES = {
  READ: 'read',
  CREATE: 'create',
  UPDATE: 'update',
  SWITCH: 'switch',
  EXPEL: 'expel',
  DELETE: 'delete',
  SUSPEND: 'Suspend',
  REJECT_APPROVE: 'Reject/Approve',
  UNDO_REVIEW: 'Undo Review',
};
