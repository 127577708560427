import React, { useState } from 'react';
import { Modal, Form, Input, DatePicker } from 'antd';
import { Button } from 'components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { visitAssignmentApi } from 'api/visit-assignment';
import SelectSearchVisitor from './SelectSearchVisitor';

function EditAssignmentButton({ form, initialValues, onSuccess }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getFieldDecorator, resetFields } = form;

  const close = () => {
    setOpen(false);
    resetFields();
  };

  const submit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (err) return;
      const payload = {
        assignment_id: initialValues?.assignment_id, // uuid of lead
        assignment_date: values.assignment_date.format('YYYY-MM-DD'), // string (ex: 2024-05-01)
        note: values.note,
        visitor_id: values.visitor_name.key,
      };
      setLoading(true);
      await visitAssignmentApi.updateAssignee(payload);
      setLoading(false);
      onSuccess();
      close();
    });
  };

  return (
    <>
      <Button size="small" type="primary" onClick={() => setOpen(true)}>
        Edit
      </Button>
      <Modal destroyOnClose visible={open} onCancel={close} maskClosable={false} footer={null}>
        <h2 className="font-bold text-3xl mb-12">Edit Visit Assignment</h2>

        <Form className="" onSubmit={submit}>
          <Form.Item
            label={<span className="font-bold">Select Assignment Date</span>}
            labelCol={{
              xs: { span: 24 },
              sm: { span: 16 },
            }}
            labelAlign="left"
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 12 },
            }}
          >
            {getFieldDecorator('assignment_date', {
              rules: [{ required: true, message: 'Please Select Assignment Date' }],
              initialValue: moment(initialValues?.assignment_date || '', 'YYYY-MM-DD'),
            })(
              <DatePicker
                size="large"
                className="w-full mt-2"
                format="DD MMM YYYY"
                disabledDate={(current) => current.isBefore(moment())}
                allowClear={false}
              />,
            )}
          </Form.Item>
          <Form.Item label={<span className="font-bold">Visitor Name</span>}>
            {getFieldDecorator('visitor_name', {
              rules: [{ required: true, message: 'Please input Visitor Name' }],
              initialValue: { key: initialValues?.visitor_id, label: initialValues.visitor_name || '' },
            })(<SelectSearchVisitor />)}
          </Form.Item>
          <Form.Item label={<span className="font-bold">Notes</span>}>
            {getFieldDecorator('note', {
              rules: [{ required: false, message: 'You can add notes' }],
              initialValue: initialValues?.note || undefined,
            })(<Input.TextArea size="large" placeholder="Type here" rows={5} className="mt-2" />)}
          </Form.Item>

          <Form.Item>
            <div className="flex w-full mt-4 justify-start">
              <Button
                className="border-cf-gray text-cf-gray"
                size="large"
                style={{ marginRight: '0.5em', borderRadius: 12 }}
                onClick={close}
              >
                Cancel
              </Button>
              <Button size="large" type="primary" htmlType="submit" loading={loading} style={{ borderRadius: 12 }}>
                Save Change
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

EditAssignmentButton.propTypes = {
  form: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default Form.create({ name: 'Edit Visitation' })(EditAssignmentButton);
