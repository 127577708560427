import React from 'react';
import { Button } from 'components';
import { TableTitle } from 'components/table';
import moment from 'moment';
import { ACQUISITION_OPTIONS, VISITATION_STATUS_OPTIONS } from 'config/visit-assignment';
import Tag from 'components/Tag/v2';

const FORMAT_TIME = 'DD MMM YYYY';

export const columns = (viewDetailAction) => {
  return [
    {
      title: (
        <TableTitle className="px-4">
          <div className="">Business Name (Leads)</div>
        </TableTitle>
      ),
      dataIndex: 'acquisition_business_name',
      key: 'acquisition_business_name',
      render: (data) => <div className="pl-4">{data || '-'}</div>,
    },
    {
      title: <TableTitle>Visitor</TableTitle>,
      dataIndex: 'visitor_name',
      key: 'visitor_name',
    },
    {
      title: <TableTitle>Assignment Date</TableTitle>,
      dataIndex: 'assignment_date',
      key: 'assignment_date',
      render: (date) => <div>{date ? moment(date).format(FORMAT_TIME) : '-'}</div>,
    },
    {
      title: <TableTitle>Visitation Date</TableTitle>,
      dataIndex: 'visitation_date',
      key: 'visitation_date',
      render: (date) => <div>{date ? moment(date).format(FORMAT_TIME) : '-'}</div>,
    },
    {
      title: <TableTitle>Location</TableTitle>,
      dataIndex: 'acquisition_location',
      key: 'acquisition_location',
      render: (data) => <div className="">{data || '-'}</div>,
    },
    {
      title: <TableTitle>Number of Visit</TableTitle>,
      dataIndex: 'visitation_count',
      key: 'visitation_count',
      align: 'center',
      render: (data) => <div>{data || '0'}</div>,
    },
    {
      title: <TableTitle>Acquisition Status</TableTitle>,
      dataIndex: 'acquisition_status',
      key: 'acquisition_status',
      align: 'center',
      render: (code) => {
        const status = ACQUISITION_OPTIONS.find((opt) => opt.key === code);
        return (
          <Tag key={code} bgColor={status?.bgColor} textColor={status?.textColor}>
            {status?.label || ''}
          </Tag>
        );
      },
    },
    {
      title: <TableTitle>Visitation Status</TableTitle>,
      dataIndex: 'visitation_status',
      key: 'visitation_status',
      align: 'center',
      render: (code) => {
        const status = VISITATION_STATUS_OPTIONS.find((opt) => opt.key === code);
        return (
          <Tag key={code} bgColor={status?.bgColor} textColor={status?.textColor}>
            {status.label}
          </Tag>
        );
      },
    },
    {
      title: <TableTitle>Action</TableTitle>,
      key: 'action',
      align: 'center',
      render: (id, record) => (
        <div className="flex w-180px justify-center m-auto">
          <Button size="small" className="m-auto text-cf-gray border-cf-gray" onClick={() => viewDetailAction(id, record)}>
            View Detail
          </Button>
        </div>
      ),
    },
  ];
};

export default columns;
