import React, { useEffect, useState } from 'react'
import qs from 'qs'
import { withRouter } from 'react-router-dom'

import HistoryCard from 'components/Molecules/HistoryCard/history-card'
import { Table } from 'components'

import { usersApi } from 'api/users'
import { MENU } from 'config/menu'
import { getQueryStringForPagination } from 'utils/query'
import { columns } from './columns'

const PAGE_SIZE = 10
function HistoryLogs({ userId, location, history, lastUpdate }) {
	const [loading, setLoading] = useState(false)
	const [userLogs, setUserLogs] = useState([])

	// pagination
	const query = getQueryStringForPagination(location.search)
	const { historyPage } = query
	const [totalData, setTotalData] = useState(null)

	const onChangePage = (pageNum) => {
		const pageQuery = { ...query, historyPage: pageNum }
		delete pageQuery.page
		if (pageNum === 1) delete pageQuery.historyPage
		history.push(`${MENU.USER_MANAGEMENT}/${userId}?${qs.stringify(pageQuery)}`)
	}

	useEffect(() => {
		const fetchHistory = async () => {
			setLoading(true)
			try {
				const res = await usersApi.listEvents(userId, {
					page: historyPage,
					per_page: PAGE_SIZE,
				})
				setUserLogs(res.data.data)
				setTotalData(res.data.metadata.total_count)
				setLoading(false)
			} catch (error) {
				console.error(error)
			}
		}
		fetchHistory()
	}, [userId, historyPage, lastUpdate])

	return (
		<HistoryCard>
			<Table
				loading={loading}
				dataSource={userLogs}
				columns={columns()}
				rowKey="updated_date_utc"
				withoutPagination
				pagination={{
					current: +historyPage || 1,
					total: totalData,
					pageSize: PAGE_SIZE,
					onChange: onChangePage,
				}}
			/>
		</HistoryCard>
	)
}

export default withRouter(HistoryLogs)
