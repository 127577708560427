import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Modal, Input, notification } from 'antd';

import { AccountApi } from 'api/account';
import { getCookies } from 'utils/cookies';
import { CONFIG_COOKIES } from 'config/cookies';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import { MENU } from 'config/menu';

const { TextArea } = Input;

function DeleteAccountConfirmation({ visible, close, role, history }) {
  const [reasonValue, setReasonValue] = useState('');
  const [loading, setLoading] = useState(false);
  const sendError = useRollbarNotifier();

  const handleDelete = async () => {
    try {
      setLoading(true);
      const res = await AccountApi.delete({
        role_id: role.id,
        merchant_id: getCookies(CONFIG_COOKIES.MERCHANT_ID),
        reason: reasonValue,
      });
      if (!res || !res.data) return;
      notification.success({
        message: 'Success',
        description: 'Role has been deleted successfully',
      });
      setReasonValue('');
      close();
      history.push(MENU.ACCOUNT_MANAGEMENT);
      setLoading(false);
    } catch (error) {
      sendError(`Error when delete role`, error);
    }
  };
  return (
    <Modal
      loading={loading}
      destroyOnClose
      maskClosable={false}
      onOk={handleDelete}
      title={<span style={{ fontWeight: 700 }}>Delete Role</span>}
      visible={visible}
      onCancel={() => {
        close();
        setReasonValue('');
      }}
      okText="Delete"
      okButtonProps={{ disabled: !reasonValue }}
      cancelText="Cancel"
      centered
    >
      <p style={{ fontSize: 16, textAlign: 'center' }}>Are you sure want to delete this Role?</p>
      <p style={{ fontSize: 14 }}>Reason</p>
      <TextArea rows={4} value={reasonValue} onChange={(e) => setReasonValue(e.target.value)} />
    </Modal>
  );
}

DeleteAccountConfirmation.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  role: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(DeleteAccountConfirmation);
