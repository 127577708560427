import React from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';

import { CONFIG_COOKIES } from 'config/cookies';
import { getCookies } from 'utils/cookies';

function ProfileAvatar({ size = 40 }) {
  const userImage = getCookies(CONFIG_COOKIES.USER_IMG_URL);

  if (!userImage) return <Avatar size={size} icon="user" />;
  return (
    <div className="mr-2">
      <img
        style={{ borderRadius: '50%', width: size }}
        src={getCookies(CONFIG_COOKIES.USER_IMG_URL)}
        alt={getCookies(CONFIG_COOKIES.FULLNAME)}
      />
    </div>
  );
}

ProfileAvatar.propTypes = {
  size: PropTypes.number,
};
ProfileAvatar.defaultProps = {
  size: 40,
};

export default ProfileAvatar;
