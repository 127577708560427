import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from 'customHooks/useMediaQuery';
import { Col } from 'antd';

function Pane({ disabled = false, title, desc, srcImg, onClick }) {
  const isMobile = useMediaQuery('(max-width: 768px)');

  const containerStyle = {
    background: disabled ? '#E0E0E0' : '#FFFFFF',
    height: isMobile ? 'auto' : 173,
    cursor: disabled ? 'not-allowed' : 'pointer',
  };

  const shadowStyle = disabled ? '' : 'shadow-md hover:shadow-xl';

  return (
    <Col md={12} lg={24} xl={12}>
      <div
        aria-hidden="true"
        onClick={onClick}
        onKeyPress={onClick}
        className={`flex items-center mb-8 rounded-2xl p-5 lg:p-10 ease-linear ${shadowStyle}`}
        style={containerStyle}
      >
        <div className="flex ml-5 mr-8 my-auto">
          <img className="inline-block cursor-pointer" src={srcImg} alt="canfazz-welcome-dashboard" />
        </div>
        <div className="lg:w-3/4 w-3/5">
          <p style={{ fontWeight: 700, fontSize: 24, lineHeight: '36px' }}>{title}</p>
          <p style={{ fontWeight: 400, fontSize: 14, lineHeight: '24px' }}>{desc}</p>
        </div>
      </div>
    </Col>
  );
}

Pane.propTypes = {
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  srcImg: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

Pane.defaultProps = {
  disabled: false,
};

export default Pane;
