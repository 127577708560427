import React from 'react'

export default function Text({ children, size = '' }) {
	return (
		<p
			className="text-white"
			style={{
				fontWeight: size === 'small' ? 400 : 700,
				fontSize: size === 'small' ? 16 : 24,
				lineHeight: '36px',
			}}>
			{children}
		</p>
	)
}
