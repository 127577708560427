import React from 'react'
import { Card } from 'antd'
import useMediaQuery from 'customHooks/useMediaQuery'

export default function CardMobile(props) {
	const isMobile = useMediaQuery('(max-width: 768px)')

	return (
		<Card bodyStyle={{ padding: isMobile ? 12 : '12px 24px' }} {...props} />
	)
}
