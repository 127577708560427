import { useRollbar } from '@rollbar/react';
import { useCallback } from 'react';

/**
 * Custom hook for catching and handling errors and sending them to Rollbar.
 * @returns {function} A function for catching and handling errors.
 */
const useRollbarNotifier = () => {
  const rollbar = useRollbar();

  /**
   * Catch and handle errors, sending them to Rollbar.
   * @param {string} msg - A description or message for the error.
   * @param {Error} error - The error to be caught and handled.
   * @param {Object} [additionalContext] - Additional contextual information to send to Rollbar.
   * @returns {Error} The original error for further handling, if needed.
   */
  const sendError = useCallback(
    (msg, error, additionalContext = {}) => {
      // Exclude Axios errors from being sent to Rollbar
      if (error.isAxiosError) {
        return error;
      }

      rollbar.error(msg, error, additionalContext);
      return error;
    },
    [rollbar],
  );

  return sendError;
};

export default useRollbarNotifier;
