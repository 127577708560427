import React from 'react';
import moment from 'moment';
import { Col, Row } from 'antd';

import { SearchQueryByApi, SelectSearchQuery, SelectMonthPickerQuery } from 'components';
import { usersApi } from 'api/users';
import { positionsApi } from 'api/positions';
import { GRANULARITY } from 'config/report';

const DEFAULT_QUERIES = { granularity: 'daily', monthPicker: [moment(), moment()] };
function FilterQuerySection() {
  const getPositionOptions = async (search) => {
    const res = await positionsApi.searchPosition(search);
    return (
      res?.data?.data?.map((dt) => ({
        key: dt.id,
        label: dt.label,
      })) || []
    );
  };

  const getSalesOptions = async (search) => {
    const res = await usersApi.searchSales(search);
    return (
      res?.data?.data?.map((dt) => ({
        key: dt.id,
        label: dt.name,
      })) || []
    );
  };

  return (
    <div className="mb-5">
      <Row gutter={[16, 12]}>
        <Col span={24} lg={4}>
          <SelectSearchQuery className="w-full" options={GRANULARITY} fieldName="granularity" initialValue={DEFAULT_QUERIES.granularity} />
        </Col>
        <Col span={24} lg={4}>
          <SelectMonthPickerQuery initialValue={DEFAULT_QUERIES.monthPicker} />
        </Col>
        <Col span={24} lg={4}>
          <SearchQueryByApi placeholder="Search Position" getOptions={getPositionOptions} fieldName="position_id" />
        </Col>
        <Col span={24} lg={4}>
          <SearchQueryByApi placeholder="Search Sales Name" getOptions={getSalesOptions} fieldName="sales_id" />
        </Col>
      </Row>
    </div>
  );
}

export default FilterQuerySection;
