import { notification } from 'antd';
import { omit } from 'lodash';

import { clearCookies, getCookies } from 'utils/cookies';
import { MENU } from 'config/menu';

import { CONFIG_COOKIES } from 'config/cookies';
import { PUBLIC_URL } from 'config/url';
import { toTitleCase } from 'utils/formatter/string';
import { rollbar } from 'config/rollbar';

const getConfig = (config) => {
  const modifiedConfig = { ...config };
  const token = getCookies(CONFIG_COOKIES.TOKEN);
  if (token) {
    modifiedConfig.headers = {
      Authorization: `Bearer ${token}`,
      ...modifiedConfig.headers,
    };
  }

  const { url } = modifiedConfig;
  if (!url.includes('geofazz')) {
    const merchantId = getCookies(CONFIG_COOKIES.MERCHANT_ID);
    if (merchantId) {
      modifiedConfig.headers = {
        MerchantID: merchantId,
        ...modifiedConfig.headers,
      };
    }
  }

  return modifiedConfig;
};

export const preRequestInterceptor = (config) => getConfig(config);

export const errorInterceptor = (err) => {
  const { response, code } = err;

  if (code === 'ERR_CANCELED') {
    return Promise.reject(err);
  }

  if (response) {
    const url = response?.config?.url || '';
    if (response.status === 401 && url.includes('login')) {
      notification.error({
        message: 'Error',
        description: toTitleCase(response?.data?.message || response?.message || 'Something went wrong'),
      });
      clearCookies();
    } else if (response.status === 401) {
      notification.error({
        message: 'Error',
        description: 'Token expired, please login again',
      });
      window.setTimeout(() => {
        clearCookies();
        window.location.href = `${PUBLIC_URL}${MENU.LOGIN}?isExpired=true`;
      }, 1500);
    } else if (response.status === 403 && response.data.code === 'user_has_been_merged') {
      notification.error({
        message: 'Error',
        description: `${response.data.message}.`,
      });
    } else if (response.status === 403) {
      notification.error({
        message: 'Error',
        description: 'Unauthorized Action!',
      });
    } else if (response.status === 404 && !!response.data.message && typeof response.data.message === 'string') {
      notification.error({
        message: 'Error',
        description: toTitleCase(response.data.message),
      });
    } else if (response.status === 404) {
      notification.error({
        message: 'Error',
        description: 'Request not found!',
      });
    } else if (response.status === 500) {
      notification.error({
        message: 'Error',
        description: response.data.error || 'Something went wrong!',
      });
    } else if (response.status === 422) {
      let description = response.data ? response.data.message || response.data.error : 'Request can not be processed';
      description = description.split('_').join(' ');
      notification.error({
        message: 'Error',
        description,
      });
    } else {
      const description = response.data ? response.data.message || response.data.error : 'Unknown error';
      notification.error({
        message: 'Error',
        description,
      });
    }
  } else {
    notification.error({
      message: 'Network Error',
      description: 'This could be server network issue or dropped internet connection',
    });
  }

  // Send only 5xx error to the rollbar
  if (response.status >= 500 && response.status < 600) {
    rollbar.error(`Server error when hitting ${err.config.url}`, err, omit(err.config, 'headers'));
  }

  return Promise.reject(err);
};
