import React from 'react';
import { useHistory } from 'react-router-dom';
import { InputSearchQuery, SelectRangePickerQuery, SelectSearchQuery, Table } from 'components';
import { Col, Row } from 'antd';
import { getQuarterRanges } from 'utils/date';
import moment from 'moment';
import { VISITATION_STATUS_ASSIGNEE_OPTIONS } from 'config/visit-assignment';

import useGetRoles from 'customHooks/useGetRoles';
import { MENU } from 'config/menu';

import { columns } from './columns';
import useVisitAssignmentAssignee from './state';

function VisitAssignmentAssignee() {
  const { dataList, loading } = useVisitAssignmentAssignee();
  const { roles } = useGetRoles();
  const history = useHistory();

  return (
    <div>
      <div className="mb-10 mt-4">
        <Row gutter={16}>
          <Col span={6}>
            <InputSearchQuery placeholder="Search Visitor/Business Name" className="md:mr-5 md:mb-0 mb-5" />
          </Col>
          <Col span={4}>
            <SelectRangePickerQuery
              disabledDate={(curr) => curr && curr > moment().endOf('day')}
              ranges={{
                Today: [moment(), moment()],
                'This Week': [moment().startOf('week'), moment().endOf('week')],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'This Year': [moment().startOf('year'), moment().endOf('year')],
                Q1: getQuarterRanges({ q: 1, year: moment().format('YYYY') }),
                Q2: getQuarterRanges({ q: 2, year: moment().format('YYYY') }),
                Q3: getQuarterRanges({ q: 3, year: moment().format('YYYY') }),
                Q4: getQuarterRanges({ q: 4, year: moment().format('YYYY') }),
              }}
              initialValue={[moment().startOf('month'), moment().endOf('month')]}
            />
          </Col>
          <Col span={4}>
            <SelectSearchQuery
              className="w-full md:mr-5 md:mb-0 mb-2"
              options={[{ key: '', label: 'All Position' }].concat((roles || []).map((r) => ({ key: r.name, label: r.name })))}
              fieldName="role_code"
              allText="All Position"
            />
          </Col>
          <Col span={4}>
            <SelectSearchQuery
              className="w-full md:mr-5 md:mb-0 mb-2"
              options={[{ key: '', label: 'All Visitation Status' }].concat(
                (VISITATION_STATUS_ASSIGNEE_OPTIONS || []).map((opt) => ({ key: opt.key, label: opt.label })),
              )}
              fieldName="visitation_status"
              allText="All Status"
            />
          </Col>
        </Row>
      </div>

      <Table
        columns={columns((row) => history.push(`${MENU.VISIT_ASSIGNMENT}/assignee/${row.visitor_id}`))}
        dataSource={dataList || []}
        count={dataList.length}
        urlPush={`${MENU.VISIT_ASSIGNMENT}/assignee`}
        loading={loading}
        paginationType="next-prev"
      />
    </div>
  );
}

export default VisitAssignmentAssignee;
