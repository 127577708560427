import React, { useState } from 'react';
import { Icon, Modal, Form, Input, DatePicker } from 'antd';
import { Button } from 'components';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { visitAssignmentApi } from 'api/visit-assignment';
import SelectSearchVisitor from './SelectSearchVisitor';

function AddAssignmentButton({ form, onSuccess }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getFieldDecorator, resetFields } = form;
  const params = useParams();

  const close = () => {
    setOpen(false);
    resetFields();
  };

  const submit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (err) return;
      const payload = {
        acquisition_id: params?.id, // uuid of lead
        assignment_date: values.assignment_date.format('YYYY-MM-DD'), // string (ex: 2024-05-01)
        note: values.note,
        visitor_id: values.visitor_name.key,
      };
      setLoading(true);
      await visitAssignmentApi.createAssignment(payload);
      setLoading(false);
      onSuccess();
      close();
    });
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} style={{ width: 190 }} size="large" type="primary">
        <div className="flex gap-4 items-center justify-center w-full ">
          <Icon type="plus-circle" theme="filled" />
          Add New Assignment
        </div>
      </Button>

      <Modal destroyOnClose visible={open} onCancel={close} maskClosable={false} footer={null}>
        <h2 className="font-bold text-3xl mb-12">Add Visit Assignment</h2>

        <Form className="" onSubmit={submit}>
          <Form.Item
            label={<span className="font-bold">Select Assignment Date</span>}
            labelCol={{
              xs: { span: 24 },
              sm: { span: 16 },
            }}
            labelAlign="left"
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 12 },
            }}
          >
            {getFieldDecorator('assignment_date', {
              rules: [{ required: true, message: 'Please Select Assignment Date' }],
              initialValue: moment(),
            })(
              <DatePicker
                size="large"
                className="w-full mt-2"
                format="DD MMM YYYY"
                disabledDate={(current) => current.isBefore(moment())}
                allowClear={false}
              />,
            )}
          </Form.Item>

          <Form.Item label={<span className="font-bold">Visitor Name</span>}>
            {getFieldDecorator('visitor_name', {
              rules: [{ required: true, message: 'Please input Visitor Name' }],
            })(<SelectSearchVisitor />)}
          </Form.Item>

          <Form.Item label={<span className="font-bold">Notes</span>}>
            {getFieldDecorator('note', {
              rules: [{ required: false, message: 'You can add notes' }],
            })(<Input.TextArea size="large" placeholder="Type here" rows={5} className="mt-2" />)}
          </Form.Item>

          <Form.Item>
            <div className="flex w-full mt-4 justify-start">
              <Button
                className="border-cf-gray text-cf-gray"
                size="large"
                style={{ marginRight: '0.5em', borderRadius: 12 }}
                onClick={close}
              >
                Cancel
              </Button>
              <Button size="large" type="primary" htmlType="submit" loading={loading} style={{ borderRadius: 12 }}>
                Assign
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

AddAssignmentButton.propTypes = {
  form: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default Form.create({ name: 'Add Visitation' })(AddAssignmentButton);
