import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { notification } from 'antd';
import { positionsApi } from 'api/positions';
import { usersApi } from 'api/users';
import { getQueryString } from 'utils/query';
import { MENU } from 'config/menu';

const PAGE_LIMIT = 10;
const GREAT_NUMBER = 999;

function usePositionListPage() {
  const [positions, setPositions] = useState([]);
  const [roles, setRoles] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPositions, setTotalPositions] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [showCreatePositionModal, setShowCreatePositionModal] = useState(false);
  const [switchUserModal, setSwitchUserModal] = useState({ visible: false, position: null });
  const [showExpelModal, setShowExpelModal] = useState({ visible: false, position: null });

  const history = useHistory();
  const location = useLocation();

  const viewDetailAction = (id, record) => {
    history.push(`${MENU.POSITION_MANAGEMENT}/position/${record.position_id}`);
  };

  const onClickExpelIcon = (record) => {
    setShowExpelModal({ visible: true, position: record });
  };

  const onClickSwitchIcon = (record) => {
    setSwitchUserModal({ visible: true, position: record });
  };

  const fetchData = async () => {
    setIsLoading(true);
    const query = { ...getQueryString(location.search) };

    try {
      if (roles == null) {
        const rolesRes = await usersApi.getRoles({ per_page: GREAT_NUMBER });
        setRoles(rolesRes.data.data);
      }

      setCurrentPage(query.page);
      setSearchValue(query.search);

      const positionsRes = await positionsApi.listPositions({
        per_page: PAGE_LIMIT,
        page: query.page,
        search: query.search,
        role: query.role,
      });

      setPositions(
        positionsRes.data.data.map((d, i) => {
          return { ...d, key: i };
        }),
      );
      setTotalPositions(positionsRes.data.metadata.total_count);

      setIsLoading(false);
    } catch (ex) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [location.search]);

  const submitCreatePosition = async (formData) => {
    setIsLoading(true);

    try {
      await positionsApi.createPosition(formData);
      setIsLoading(false);
      setShowCreatePositionModal(false);
      fetchData();
      notification.success({ message: 'Create New Position Success' });
    } catch (ex) {
      setIsLoading(false);
    }
  };

  return {
    positions,
    totalPositions,
    roles,
    searchValue,
    currentPage,
    isLoading,
    showCreatePositionModal,
    setSearchValue,
    setShowCreatePositionModal,
    submitCreatePosition,
    viewDetailAction,
    onClickSwitchIcon,
    switchUserModal,
    setSwitchUserModal,
    onClickExpelIcon,
    showExpelModal,
    setShowExpelModal,
    fetchData,
  };
}

export default usePositionListPage;
