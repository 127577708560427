import React from 'react';
import PropTypes from 'prop-types';
import { toTitleCase } from 'utils/formatter/string';

export default function DefaultLegend({ payload }) {
  return (
    <ul className="flex mb-16 -mt-5">
      {payload.map((entry) => (
        <li key={`item-${entry.value}`} className="flex items-center mr-5">
          <div style={{ background: entry.color }} className="h-5 w-5 rounded-full" />
          <p className="ml-2 text-cf-12">{toTitleCase(entry.value)}</p>
        </li>
      ))}
    </ul>
  );
}

DefaultLegend.propTypes = { payload: PropTypes.array.isRequired };
