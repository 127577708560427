import { checkIsTreeNodeHaveAllChildren } from 'utils/others/geofazz';

const addParentId = (geofazzParams) =>
  Object.entries(geofazzParams)
    .map(([key, value]) => {
      return {
        [key]: value.map((code) => {
          return {
            code,
            pId: code.split('.').slice(0, -1).join('.'),
          };
        }),
      };
    })
    .reduce((acc, cur) => ({ ...acc, ...cur }), {});

const addChildren = (geofazzParamsWithPid) => {
  const result = Object.entries(geofazzParamsWithPid)
    .map(([key, value]) => {
      return {
        [key]: value.map(({ code, pId }) => {
          let children = [];
          if (key === 'provinceCodes') {
            children = geofazzParamsWithPid.regencyCodes.filter((reg) => reg.pId === code);
          } else if (key === 'regencyCodes') {
            children = geofazzParamsWithPid.districtCodes.filter((reg) => reg.pId === code);
          } else if (key === 'districtCodes') {
            children = geofazzParamsWithPid.villagesCodes.filter((reg) => reg.pId === code);
          } else {
            children = null;
          }

          return {
            code,
            pId,
            children,
          };
        }),
      };
    })
    .reduce((acc, cur) => ({ ...acc, ...cur }), {});

  return result;
};

const getGeoFazzKey = (type) => {
  if (type === 'Province') return 'provinceCodes';
  if (type === 'Regency') return 'regencyCodes';
  if (type === 'District') return 'districtCodes';
  if (type === 'Village') return 'villagesCodes';
  return '';
};

/**
 * THE LOCATION FOR AREA PRESET IS FILTERED!. So we need to filter the dataTree.
 * Only locations that exist in the geofazzParams object will be allowed
 *
 * @param initialDataTree initial antd dataTree object that contain all of the areas
 * @param geofazzParams - is the object return by calculateLocationCodes utils function.
 *
 * initial dataTree
 * @returns new antd dataTree filtered by geofazzParams object
 */
export const filterDataTreeAreaPreset = (initialDataTree, geofazzParams) => {
  const geofazzParamsWithPid = addParentId(geofazzParams);
  const geofazzParamsWithChildren = addChildren(geofazzParamsWithPid);

  const resultDataTree = (dataTree) => {
    if (dataTree.length === 0) return [];
    return dataTree.map((branch) => {
      const geoFazzKey = getGeoFazzKey(branch.type);

      const isTreeNodeHaveAllChildren = checkIsTreeNodeHaveAllChildren(branch.type, branch.id, geofazzParams);

      const allowedChildrenCodes = (() => {
        const allowedChildGeofazz = geofazzParamsWithChildren[geoFazzKey];
        if (allowedChildGeofazz.length === 0) return [];
        const find = allowedChildGeofazz.find((loc) => loc?.code === branch.id);
        const result = find?.children?.map((child) => child.code) || [];
        return result;
      })();

      const children = isTreeNodeHaveAllChildren
        ? branch?.children
        : branch?.children?.filter((child) => allowedChildrenCodes.includes(child?.id));

      return {
        ...branch,
        children: resultDataTree(children),
      };
    });
  };

  const res = resultDataTree(initialDataTree);
  return res;
};

/**
 * Filters the children tree based on the given parameters for area preset.
 * @param childrenTree - The tree of children nodes to filter.
 * @param geofazzParams - is the object return by calculateLocationCodes utils function.
 * @param parentId - The ID of the parent location.
 * @param parentType - The type of the parent location.
 * @returns The filtered children for antd dataTree.
 */
export const filterChildrenTreeAreaPreset = (childrenTree, geofazzParams, parentId, parentType) => {
  const isAllowedAllChildren = checkIsTreeNodeHaveAllChildren(parentType, parentId, geofazzParams);

  if (isAllowedAllChildren) {
    return childrenTree;
  }

  return childrenTree.filter((child) => {
    const geoFazzKey = getGeoFazzKey(child.type);
    const allowed = geofazzParams[geoFazzKey].includes(child.id);
    return allowed;
  });
};
