import React from 'react'

function ArrowDown(props) {
	return (
		<div {...props}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="40"
				height="54"
				fill="none"
				viewBox="0 0 40 54">
				<path
					fill="#333"
					fillRule="evenodd"
					d="M31 0H9v33H.082L20 54 39.92 33H31V0z"
					clipRule="evenodd"
				/>
			</svg>
		</div>
	)
}

export default ArrowDown
