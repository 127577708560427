import { METHODS, API_BASE_URL, apiCall } from './config'

export const areasApi = {
	searchAreaPresets: (data) =>
		apiCall(METHODS.GET, API_BASE_URL, '/v1/area-presets/search', { ...data }),
	listAreaPresets: (data) =>
		apiCall(METHODS.GET, API_BASE_URL, '/v1/area-presets', data),
	areaPresetDetail: (id) =>
		apiCall(METHODS.GET, API_BASE_URL, `/v1/area-presets/${id}`),
	deleteAreaPreset: (id) =>
		apiCall(METHODS.POST, API_BASE_URL, `/v1/area-presets/${id}/delete`),
	updateAreaPreset: (id, payload) =>
		apiCall(METHODS.POST, API_BASE_URL, `/v1/area-presets/${id}`, payload),
}
