import { POSITION_TYPE } from 'config/users';

/**
 * Convert location code (string[]) into geofazzParams object.
 
 * @param locs array of location ids string[]
 * @returns geofazzParamsObject {provinceCodes: locId[], regencyCodes:locId[],
 * districtCodes: locId[], villagesCodes: locId[]}
 */
export const calculateLocationCodes = (locs = []) => {
  const provinceCodes = [];
  const regencyCodes = [];
  const districtCodes = [];
  const villagesCodes = [];

  locs.map((loc) => {
    const locSplitted = loc.split('.');
    if (!provinceCodes.includes(locSplitted[0])) provinceCodes.push(locSplitted[0]);
    if (locSplitted.length >= 2) {
      const regencyCode = `${locSplitted[0]}.${locSplitted[1]}`;
      if (!regencyCodes.includes(regencyCode)) regencyCodes.push(regencyCode);
    }
    if (locSplitted.length >= 3) {
      const districtCode = `${locSplitted[0]}.${locSplitted[1]}.${locSplitted[2]}`;
      if (!districtCodes.includes(districtCode)) districtCodes.push(districtCode);
    }
    if (locSplitted.length >= 4) {
      const villageCode = `${locSplitted[0]}.${locSplitted[1]}.${locSplitted[2]}.${locSplitted[3]}`;
      if (!villagesCodes.includes(villageCode)) villagesCodes.push(villageCode);
    }
    return loc;
  });
  return {
    provinceCodes,
    regencyCodes,
    districtCodes,
    villagesCodes,
  };
};

/**
 * Check if the specific parent location has all children or not
 * by geoFazzParams object
 *
 * @param parentType string of POSITION_TYPE ('area_preset',
 * 'nasional', 'regency', etc)
 * @param parentId string of location ID. (Parent code)
 * @param geofazzParams object of geoFazzParams
 * @returns boolean
 */
export const checkIsTreeNodeHaveAllChildren = (parentType, parentId, geofazzParams) => {
  let isHaveAllChildren = false;
  if (parentType === POSITION_TYPE.PROVINCE) {
    if (geofazzParams.regencyCodes.length === 0) {
      isHaveAllChildren = true;
    } else {
      isHaveAllChildren = !geofazzParams.regencyCodes.map((i) => i.split('.').slice(0, -1).join('.')).includes(parentId);
    }
  }

  if (parentType === POSITION_TYPE.REGENCY) {
    if (geofazzParams.districtCodes.length === 0) {
      isHaveAllChildren = true;
    } else {
      isHaveAllChildren = !geofazzParams.districtCodes.map((i) => i.split('.').slice(0, -1).join('.')).includes(parentId);
    }
  }

  if (parentType === POSITION_TYPE.DISTRICT) {
    if (geofazzParams.villagesCodes.length === 0) {
      isHaveAllChildren = true;
    } else {
      isHaveAllChildren = !geofazzParams.villagesCodes.map((i) => i.split('.').slice(0, -1).join('.')).includes(parentId);
    }
  }

  return isHaveAllChildren;
};

/**
 * Only all location that exist on geofazzParams will bo allowed.
 *
 * @param allChildren array of location object that return by location API
 * @param parentType string of POSITION_TYPE ('area_preset',
 * 'nasional', 'regency', etc)
 * @param parentId string of location ID. (Parent code)
 * @param geofazzParams object of geoFazzParams
 * @returns filtered location object by geofazz params
 */
export const filterChildrenByGeofazzParams = (allChildren = [], parentType = '', parentId = '', geofazzParams = {}) => {
  const isTreeNodeHaveAllChildren = checkIsTreeNodeHaveAllChildren(parentType, parentId, geofazzParams);
  if (isTreeNodeHaveAllChildren) {
    return allChildren;
  }

  let childStrainer = [];
  if (parentType === POSITION_TYPE.PROVINCE) childStrainer = geofazzParams.regencyCodes;
  if (parentType === POSITION_TYPE.REGENCY) childStrainer = geofazzParams.districtCodes;
  if (parentType === POSITION_TYPE.DISTRICT) childStrainer = geofazzParams.villagesCodes;

  const children = allChildren.filter((i) => childStrainer.includes(i.id));
  return children;
};
