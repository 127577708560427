import React from 'react';
import { Card } from 'antd';
import Line from 'components/Chart/LineChart';
import useCharts from 'customHooks/useCharts';
import { kpiVisualizationApi } from 'api/reports';
import ChartTitle from '../../../shared/ChartTitle';

function TransactionCount() {
  const { data, loading, startDate, endDate } = useCharts({ callApi: kpiVisualizationApi.getTransactionMauCount });

  return (
    <Card className="rounded-2xl">
      <ChartTitle title="Number of Offline Agent MAU200 Blended" startDate={startDate} endDate={endDate} />
      <Line
        data={data}
        dataKey="period"
        loading={loading}
        xLabel="MAU Date"
        yLabel="Agent Count"
        lines={[{ type: 'linear', dataKey: 'mau_count', stroke: '#DB5555', withLabelList: false }]}
        legendOptions={{ visible: true }}
      />
    </Card>
  );
}

export default TransactionCount;
