import React from 'react';
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';

function CheckedTitle({ title }) {
  return <Checkbox checked>{title}</Checkbox>;
}

CheckedTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CheckedTitle;
