import React from 'react';
import { kpiVisitationApi } from 'api/reports';
import { Card } from 'antd';
import Line from 'components/Chart/LineChart';
import useCharts from 'customHooks/useCharts';
import ChartTitle from '../../../shared/ChartTitle';

function VisitationCount() {
  const { data, loading, startDate, endDate } = useCharts({ callApi: kpiVisitationApi.getVisitationCount });

  return (
    <Card className="rounded-2xl">
      <ChartTitle title="Visitation Count" startDate={startDate} endDate={endDate} />
      <Line
        data={data}
        dataKey="period"
        loading={loading}
        xLabel="Visitation Date"
        yLabel="Total Visit"
        lines={[{ type: 'linear', dataKey: 'visitation_count', stroke: '#009696', withLabelList: true }]}
        legendOptions={{ visible: false }}
      />
    </Card>
  );
}

export default VisitationCount;
