import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Input } from 'antd'
import { Button } from 'components'
import { positionsApi } from 'api/positions'

const { TextArea } = Input

const ExpelModal = ({ visible, position, reloadParent, close }) => {
	const [reasonValue, setReasonValue] = useState('')
	const [loading, setLoading] = useState(false)

	const handleDelete = async () => {
		setLoading(true)

		try {
			await positionsApi.expelUserFromPosition({
				position_id: position.position_id || '',
				user_email: position.user_email || '',
				reason: reasonValue || '',
			})
			close()
			setReasonValue('')
			reloadParent()
			Modal.success({
				title: 'User has been expel successfully',
			})
		} catch (err) {
			setLoading(false)
			console.error(err)
		}

		setLoading(false)
	}

	return (
		<Modal
			loading={loading}
			destroyOnClose
			title={<span style={{ fontWeight: 700 }}>Expel User</span>}
			visible={visible}
			onCancel={() => {
				close()
				setReasonValue('')
			}}
			footer={
				<div className="flex-end">
					<Button
						style={{ marginRight: '0.5em' }}
						onClick={() => {
							close()
							setReasonValue('')
						}}>
						Cancel
					</Button>
					<Button
						type="danger"
						htmlType="submit"
						loading={loading}
						disabled={!reasonValue}
						onClick={handleDelete}>
						Expel
					</Button>
				</div>
			}
			centered>
			<p style={{ fontSize: 16, textAlign: 'center' }}>
				Are you sure want Expel user from this position?
			</p>
			<p className="text-cf-14 mt-8 mb-4">
				Reason<span style={{ color: '#ac2f24' }}> *</span>
			</p>
			<TextArea
				rows={4}
				value={reasonValue}
				onChange={(e) => setReasonValue(e.target.value)}
			/>
		</Modal>
	)
}

export default withRouter(ExpelModal)
