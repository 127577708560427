import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { Row, Col, Card, Icon, Divider, Tooltip, notification } from 'antd';

import { Button, AllText, Breadcrumb, Tag } from 'components';
import { COLOR_STATUS_MAPPED } from 'config/users';
import { MENU } from 'config/menu';
import { getCookies } from 'utils/cookies';
import { CONFIG_COOKIES } from 'config/cookies';
import Layout from 'layout/main';
import ListTableView from 'components/Atoms/ListTableView';
import { formatDate } from 'utils/helpers';
import { toTitleCase } from 'utils/formatter/string';
import { usersApi } from 'api/users';
import useMediaQuery from 'customHooks/useMediaQuery';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';

import DeleteUserConfirmationModal from './delete-user-confirmation-modal';
import SuspendUserConfirmationModal from '../modal/suspend-user-confirmation';
import PromoteUserModal from '../modal/promote-user';
import EditUserModal from './edit-user-modal';
import MergeUserModal from '../modal/merge-user';
import UserEventsCard from './user-events-card';

const { Label, TitleText } = AllText;
const GREAT_NUMBER = 999;

function UserDetailPage({ match }) {
  const [user, setUser] = useState({});
  const [roles, setRoles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [suspendUserConfirmation, setSuspendConfirmationModal] = useState(false);
  const [showMergeUserModal, setShowMergeUserModal] = useState(false);
  const [showPromoteUserModal, setShowPromoteUserModal] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [showEditFormModal, setEditFormModal] = useState(false);

  const [lastUpdate, setLastUpdate] = useState('');
  const userId = match.params.id;
  const isWillBePromoted = moment(user.promoted_date).isAfter(moment());
  const sendError = useRollbarNotifier();

  // responsive layout
  const isMobile = useMediaQuery('(max-width: 768px)');

  const reload = () => setLastUpdate(new Date());

  const onCopyUserCode = (userCode) => {
    navigator.clipboard.writeText(userCode);
    notification.success({
      message: 'Success',
      description: `User code ${userCode} copied!`,
    });
  };

  useEffect(() => {
    if (showEditFormModal) return;

    const fetchData = async () => {
      setLoading(true);
      try {
        if (roles == null) {
          const rolesRes = await usersApi.getRoles({ per_page: GREAT_NUMBER });
          setRoles(rolesRes.data.data);
        }

        const res = await usersApi.getDetail(userId, {
          merchant_id: getCookies(CONFIG_COOKIES.MERCHANT_ID),
        });
        setUser(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        sendError(`Error when fetch user detail`, error);
      }
    };

    fetchData();
  }, [userId, lastUpdate]);

  return (
    <Layout title="User Management">
      <Breadcrumb
        data={[
          {
            link: MENU.USER_MANAGEMENT,
            title: 'User List',
          },
          {
            link: null,
            title: <b>Detail Information</b>,
          },
        ]}
      />
      <div className="app-content__details">
        <Card loading={false} className="card" bordered={false}>
          <div className="title mb-4">User Information</div>
          <Row gutter={40}>
            <Col span={isMobile ? 24 : 6}>
              <Label containerClassName="mt-0">User ID</Label>
              <TitleText>{user?.id}</TitleText>

              <Label>Full Name</Label>
              <TitleText>{user?.name}</TitleText>
            </Col>
            <Col className="md:border-l" span={isMobile ? 24 : 6}>
              <Label containerClassName="mt-0">Role</Label>
              <TitleText>{user?.role}</TitleText>

              <Label>Position</Label>
              <TitleText>{user?.position_label}</TitleText>
            </Col>
            <Col className="md:border-l" span={isMobile ? 24 : 6}>
              <Label containerClassName="mt-0">User Code</Label>

              <div className="flex">
                <p>{user.code}</p>
                <Tooltip title="Click to copy user code">
                  <Icon type="copy" className="icon ml-3" onClick={() => onCopyUserCode(user.code)} />
                </Tooltip>
              </div>

              <Label>Status</Label>
              <Tag color={COLOR_STATUS_MAPPED[user?.status]} className="mt-2">
                {toTitleCase(user?.status || '-')}
              </Tag>
            </Col>
          </Row>

          {isWillBePromoted && (
            <div className=" flex items-center h-48px px-4 rounded-lg mt-6 -mb-3 bg-cf-lightBlue text-cf-blue" style={{ maxWidth: 668 }}>
              <Icon type="info-circle" className="mr-3" />
              This user will be promoted as
              <span className="font-bold mx-2">{user?.user_next_role}</span>
              at
              <span className="font-bold mx-2">{moment(user?.promoted_date).format('DD MMMM YYYY')}</span>
            </div>
          )}
        </Card>

        <Card loading={false} bordered={false} className="card">
          <Row gutter={40}>
            <Col className="md:border-r" span={isMobile ? 24 : 12}>
              <div className="title mb-4">Detail</div>
              <ListTableView
                loading={loading}
                data={{
                  'Email ': user.email,
                  'Phone Number ': user.phone || '-',
                  'Alamat Lengkap': user.address,
                  'Registration Date': user.created_date_utc && formatDate(user.created_date_utc),
                  'Last Active Date': user.last_active_date && formatDate(user.last_active_date),
                }}
              />
            </Col>
          </Row>
        </Card>

        <Card loading={false} bordered={false}>
          <div className="flex">
            <Button
              size={isMobile ? 'small' : 'large'}
              className="md:mr-3 mr-auto"
              style={{ width: isMobile ? 'calc(33% - 5px)' : 145 }}
              loading={loading}
              onClick={() => setEditFormModal(true)}
            >
              Edit
            </Button>
            <Button
              style={{ width: isMobile ? 'calc(33% - 5px)' : 145 }}
              loading={loading}
              onClick={() => setShowMergeUserModal(true)}
              size={isMobile ? 'small' : 'large'}
              className="md:mx-3 mx-auto"
            >
              Merge Account
            </Button>
            <Button
              style={{ width: isMobile ? 'calc(33% - 5px)' : 145 }}
              loading={loading}
              onClick={() => setShowPromoteUserModal(true)}
              size={isMobile ? 'small' : 'large'}
              className="md:mx-3 ml-auto"
            >
              Promote
            </Button>
            {isMobile && <Divider className="m-2" />}
            <Button
              style={{ width: isMobile ? 'calc(50% - 4px)' : 145 }}
              type="danger-outlined"
              loading={loading}
              onClick={() => setSuspendConfirmationModal(true)}
              className="md:ml-auto mr-3"
              size={isMobile ? 'small' : 'large'}
            >
              {user.status === 'active' ? 'Suspend' : 'Unsuspend'}
            </Button>
            <Button
              style={{ width: isMobile ? 'calc(50% - 4px)' : 145 }}
              loading={loading}
              type="danger"
              onClick={() => setDeleteConfirmationModal(true)}
              size={isMobile ? 'small' : 'large'}
              className="md:ml-3 ml-auto"
            >
              Delete
            </Button>
          </div>
        </Card>
        <UserEventsCard userId={userId} lastUpdate={lastUpdate} />
      </div>

      <SuspendUserConfirmationModal user={user} visible={suspendUserConfirmation} setVisible={setSuspendConfirmationModal} />
      <MergeUserModal user={user} visible={showMergeUserModal} setVisible={setShowMergeUserModal} />
      <PromoteUserModal user={user} visible={showPromoteUserModal} close={() => setShowPromoteUserModal(false)} reloadParent={reload} />
      <DeleteUserConfirmationModal user={user} visible={deleteConfirmationModal} setVisible={setDeleteConfirmationModal} />
      <EditUserModal user={user} roles={roles} visible={showEditFormModal} close={() => setEditFormModal(false)} reloadParent={reload} />
    </Layout>
  );
}

UserDetailPage.propTypes = {
  match: PropTypes.string.isRequired,
};
export default withRouter(UserDetailPage);
