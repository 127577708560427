import { METHODS, API_URIS, API_BASE_URL, apiCall } from './config'

const formSubUrl = 'v1/form'
const fieldSubUrl = 'v1/fields'
const assetSubUrl = 'v1/assets'
const optionSubUrl = 'v1/options'

export const formsApi = {
	// FORM
	getList: (data = {}) => apiCall(METHODS.GET, API_BASE_URL, 'v1/forms', data),
	
	createForm: (data) => apiCall(METHODS.POST, API_URIS.CMS, formSubUrl, data),
	getForm: ({ id, type }) =>
		apiCall(METHODS.GET, API_URIS.CMS, `${formSubUrl}/latest/${type}/${id}`),
	publishForm: (id) =>
		apiCall(METHODS.PUT, API_URIS.CMS, `${formSubUrl}/publish/${id}`),

	// FIELDS
	getFields: (data = {}) =>
		apiCall(METHODS.GET, API_URIS.CMS, fieldSubUrl, data),
	createField: (data) => apiCall(METHODS.POST, API_URIS.CMS, fieldSubUrl, data),
	editField: (data) =>
		apiCall(METHODS.PUT, API_URIS.CMS, `${fieldSubUrl}/${data.id}`, data),
	deleteField: (id) =>
		apiCall(METHODS.DELETE, API_URIS.CMS, `${fieldSubUrl}/${id}`),
	// ASSETS
	getAssets: (data = {}) =>
		apiCall(METHODS.GET, API_URIS.CMS, assetSubUrl, data),
	createAsset: (data) => apiCall(METHODS.POST, API_URIS.CMS, assetSubUrl, data),
	editAsset: (data) =>
		apiCall(METHODS.PUT, API_URIS.CMS, `${assetSubUrl}/${data.id}`, data),
	deleteAsset: (id) =>
		apiCall(METHODS.DELETE, API_URIS.CMS, `${assetSubUrl}/${id}`),
	// OPTIONS
	getOptions: (data = {}) =>
		apiCall(METHODS.GET, API_URIS.CMS, optionSubUrl, data),
	createOptions: (data) =>
		apiCall(METHODS.POST, API_URIS.CMS, optionSubUrl, data),
	editOptions: (data) =>
		apiCall(METHODS.PUT, API_URIS.CMS, `${optionSubUrl}/${data.id}`, data),
	deleteOptions: (id) =>
		apiCall(METHODS.DELETE, API_URIS.CMS, `${optionSubUrl}/${id}`),
	// OTHERS
	uploadContent: (file) => {
		const formData = new FormData()
		formData.append('file', file)
		return apiCall(
			METHODS.POST,
			API_BASE_URL,
			'v1/files',
			formData,
			{},
			{ 'Content-Type': 'multipart/form-data' },
		)
	},
}
