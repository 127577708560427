import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Input } from 'antd'
import { positionsApi } from 'api/positions'
import MENU from 'config/menu'

const { TextArea } = Input

const DeletePositionConfirmationModal = ({
	visible,
	position,
	history,
	close,
}) => {
	const [reasonValue, setReasonValue] = useState('')
	const [loading, setLoading] = useState(false)

	const handleDelete = async () => {
		setLoading(true)

		try {
			await positionsApi.deletePosition(position.position_id, {
				reason: reasonValue,
			})
			Modal.success({
				title: 'Success',
				content: 'Position deleted',
				onOk: () => {
					setReasonValue('')
					history.push(`${MENU.POSITION_MANAGEMENT}/position`)
					close()
				},
			})
		} catch (err) {
			// Intentionally empty
		}

		setLoading(false)
	}

	return (
		<Modal
			loading={loading}
			destroyOnClose
			onOk={handleDelete}
			title={<span style={{ fontWeight: 700 }}>Delete Position</span>}
			visible={visible}
			onCancel={() => {
				close()
				setReasonValue('')
			}}
			okText="Delete"
			okButtonProps={{ disabled: !reasonValue }}
			cancelText="Cancel"
			centered>
			<p style={{ fontSize: 16, textAlign: 'center' }}>
				Are you sure want to delete this position?
			</p>
			<p style={{ fontSize: 14 }}>Reason</p>
			<TextArea
				rows={4}
				value={reasonValue}
				onChange={(e) => setReasonValue(e.target.value)}
			/>
		</Modal>
	)
}

export default withRouter(DeletePositionConfirmationModal)
