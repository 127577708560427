import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { SquareImage, Button, Tag } from 'components';
import { Card, Divider } from 'antd';
import { COLOR_STATUS_MAPPED } from 'config/merchant-management';
import { MENU } from 'config/menu';
import useMediaQuery from 'customHooks/useMediaQuery';

import Layout from 'layout/main';
import Breadcrumb from 'components/breadcrumb';
import ListTableView from 'components/Atoms/ListTableView';
import { MerchantApi } from 'api/merchant';
import { toTitleCase } from 'utils/formatter/string';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';

import DeactivateMerchantModal from '../modals/toggle-activate-merchant';
import DeleteMerchantModal from '../modals/delete-mercant-confirmation';
import EditFormModal from '../modals/edit-form';
import ChangeOwnerModal from '../modals/change-owner';

import HistoryLogs from './history-logs';

import './detail.scss';

function DetailMerchantManagement({ match }) {
  const [lastUpdate, setLastUpdate] = useState('');
  const [merchant, setMerchantData] = useState({});
  const [loading, setLoading] = useState(false);
  const sendError = useRollbarNotifier();

  // Modals
  const [toggleActivateModal, setActiveToggleConfirmation] = useState(false);
  const [deleteConfirmationModal, setDeleteConfirmation] = useState(false);
  const [showEditFormModal, setEditFormModal] = useState(false);
  const [showChangeOwnerModal, setShowChangeOwnerModal] = useState(false);
  // responsive layout
  const isMobile = useMediaQuery('(max-width: 768px)');

  const merchantId = match.params.id;
  const merchantOwnerName = useMemo(() => toTitleCase(`${merchant.owner_first_name} ${merchant.owner_last_name}`), [merchant]);

  const updateParent = () => setLastUpdate(new Date());

  useEffect(() => {
    if (showEditFormModal) return;
    if (toggleActivateModal) return;
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await MerchantApi.getDetail({
          merchant_id: merchantId,
        });
        setMerchantData(res.data);
        setLoading(false);
      } catch (error) {
        sendError('Error fetching detail merchant management', error);
      }
    };

    fetchData();
  }, [merchantId, lastUpdate]);

  return (
    <Layout title="Merchant Management">
      <Breadcrumb
        data={[
          {
            link: MENU.MERCHANT_MANAGEMENT_LIST,
            title: 'Merchant List',
          },
          {
            link: null,
            title: <b>Merchant Detail</b>,
          },
        ]}
      />
      <div className="app-content__details">
        <Card loading={false} className="card" bordered={false}>
          <p className="title">Merchant Information</p>
          <ListTableView
            loading={loading}
            data={{
              'Merchant Logo': <SquareImage src={merchant.logo} />,
              'Merchant Name': merchant.name,
              'Status ': (
                <Tag
                  color={COLOR_STATUS_MAPPED[merchant.is_active]}
                  style={{
                    height: 26,
                    width: 100,
                    borderRadius: 50,
                    textAlign: 'center',
                    paddingTop: 2,
                  }}
                >
                  {merchant.is_active ? 'Active' : 'Deactive'}
                </Tag>
              ),
            }}
          />
        </Card>
        <Card loading={false} bordered={false} className="card">
          <p className="title">Account Owner Information</p>
          <ListTableView
            loading={loading}
            data={{
              'User Name': merchantOwnerName,
              'Email ': merchant.owner_email || '-',
              'Phone ': merchant.owner_phone || '-',
              'Date Created ': moment(merchant.created_date_utc).format('DD MMM YYYY, HH:mm'),
            }}
          />
        </Card>
        <Card bodyStyle={{ padding: '12px 24px' }} loading={false} bordered={false} className="p-0">
          <div className="flex">
            <Button className="m-0" loading={loading} onClick={() => setEditFormModal(true)} style={{ marginRight: 8, width: 145 }}>
              Edit
            </Button>
            <Button className="m-0" loading={loading} onClick={() => setShowChangeOwnerModal(true)} style={{ marginRight: 8, width: 145 }}>
              Change Owner
            </Button>
            {isMobile && <Divider className="my-2" />}
            <Button
              className="md:ml-auto ml-0"
              type="danger-outlined"
              loading={loading}
              onClick={() => setActiveToggleConfirmation(true)}
              style={{ marginRight: 8, width: 145 }}
            >
              {merchant.is_active ? 'Deactivate' : 'Activate'}
            </Button>
            <Button type="danger" className="m-0" loading={loading} onClick={() => setDeleteConfirmation(true)} style={{ width: 145 }}>
              Delete
            </Button>
          </div>
        </Card>
        <HistoryLogs merchantId={merchantId} lastUpdate={lastUpdate} />
      </div>
      <DeactivateMerchantModal
        merchant={merchant}
        visible={toggleActivateModal}
        close={() => setActiveToggleConfirmation(false)}
        onSuccess={updateParent}
      />
      <DeleteMerchantModal merchant={merchant} visible={deleteConfirmationModal} close={() => setDeleteConfirmation(false)} />
      <EditFormModal visible={showEditFormModal} close={() => setEditFormModal(false)} merchant={merchant} onSuccess={updateParent} />
      <ChangeOwnerModal
        visible={showChangeOwnerModal}
        close={() => setShowChangeOwnerModal(false)}
        merchant={merchant}
        onSuccess={updateParent}
      />
    </Layout>
  );
}

DetailMerchantManagement.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(DetailMerchantManagement);
