import { METHODS, API_BASE_URL, apiCall } from './config';

export const AcquisitionReviewApi = {
  get: (payload) => apiCall(METHODS.GET, API_BASE_URL, 'v1/acquisitions', payload),
  getDetail: (id, payload) => apiCall(METHODS.GET, API_BASE_URL, `v1/acquisitions/${id}`, payload),
  reject: (id, payload) => apiCall(METHODS.POST, API_BASE_URL, `v1/acquisitions/${id}/reject`, payload),
  undo: (id, payload) => apiCall(METHODS.POST, API_BASE_URL, `v1/acquisitions/${id}/review`, payload),
  approve: (id, payload) => apiCall(METHODS.POST, API_BASE_URL, `v1/acquisitions/${id}/approve`, payload),
  getHistory: (id, payload) => apiCall(METHODS.GET, API_BASE_URL, `v1/acquisitions/${id}/events`, payload),
};

export default { AcquisitionReviewApi };
