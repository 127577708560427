import React from 'react';
import { Icon } from 'antd';
import { Button } from 'components';
import { TableTitle } from 'components/table';

export const columns = (viewDetailAction, swapToggleModal, expelModal) => {
  return [
    {
      title: <TableTitle className="px-4">Territory ID</TableTitle>,
      dataIndex: 'position_id',
      key: 'position_id',
      render: (text) => <p className="px-4">{text}</p>,
    },
    {
      title: <TableTitle>Position Label</TableTitle>,
      dataIndex: 'position_label',
      key: 'position_label',
    },
    {
      title: <TableTitle>Role</TableTitle>,
      dataIndex: 'role_name',
      key: 'role_name',
    },
    {
      title: <TableTitle>Full Name</TableTitle>,
      dataIndex: 'user_name',
      key: 'user_name',
      render: (data) => <div>{data || '-'}</div>,
    },
    {
      title: <TableTitle>Email Address</TableTitle>,
      dataIndex: 'user_email',
      key: 'user_email',
      render: (data) => <div>{data || '-'}</div>,
    },
    {
      title: <TableTitle>Action</TableTitle>,
      key: 'action',
      align: 'center',
      render: (id, record) => (
        <div className="flex w-180px justify-center m-auto">
          <Button size="small" className="m-auto" onClick={() => viewDetailAction(id, record)}>
            View Detail
          </Button>
          <Button
            size="small"
            onClick={() => swapToggleModal(record)}
            className="max-w-min ml-2 mr-2.5"
            disabled={!record.user_email || !record.user_name}
          >
            <Icon type="swap" />
          </Button>
          <Button
            size="small"
            onClick={() => expelModal(record)}
            className="max-w-min"
            disabled={!record.user_email || !record.user_name}
          >
            <Icon type="close" />
          </Button>
        </div>
      ),
    },
  ];
};

export default columns;
