import React from 'react'
import { Modal } from 'antd'
import { withRouter, useHistory } from 'react-router-dom'
import { Table, Button, InputSearchQuery } from 'components'
import MENU from 'config/menu'
// import Filter from '../../../components/filter'
import './list.scss'
import getPositionAreaColumns from './columns'
import usePositionManagementAreaList from './states'
import CreatePositionArea from './create'

const PositionManagementAreaList = ({ location }) => {
	const urlPush = `${MENU.POSITION_MANAGEMENT}/area-presets`

	const {
		positionAreas,
		// search,
		totalItems,
		isLoading,
		showCreateModal,
		// setSearch,
		setShowCreateModal,
		submitCreatePositionArea,
	} = usePositionManagementAreaList({ location })

	const history = useHistory()

	const goToDetail = (id, record) => {
		history.push(`${MENU.POSITION_MANAGEMENT}/area-presets/${record.id}`)
	}

	const tableColumns = getPositionAreaColumns(goToDetail)

	return (
		<div>
			{/* <Row className="block" type="flex" justify="space-between">
				<Col className="app-content__action">
					<Row type="flex" justify="space-between">
						<Filter
							search
							urlPush={urlPush}
							searchValue={search}
							setSearch={setSearch}
						/>
						<Button
							type="primary"
							onClick={() => {
								setShowCreateModal(true)
							}}>
							<Icon className="align-middle" type="plus-square" />
							<span className="align-middle">Create New Area Preset</span>
						</Button>
					</Row>
				</Col>
			</Row> */}

			<div className="flex align-center justify-start mb-10 mt-4">
				<InputSearchQuery className="md:w-328px md:mr-5 md:mb-0 mb-5" />
				<Button
					className="md:w-328px w-full md:mr-0 md:mb-0 md:ml-auto"
					style={{ width: 200 }}
					type="primary"
					onClick={() => setShowCreateModal(true)}
					icon="plus-square">
					<span className="ml-2">Create New Area Preset</span>
				</Button>
			</div>

			<Table
				loading={isLoading}
				columns={tableColumns}
				dataSource={positionAreas || []}
				count={totalItems || 0}
				urlPush={urlPush}
				rowKey="id"
			/>

			{showCreateModal && (
				<Modal
					title="Create New Area"
					visible={showCreateModal}
					onCancel={() => setShowCreateModal(false)}
					footer={null}>
					<CreatePositionArea
						onSubmit={submitCreatePositionArea}
						setVisible={setShowCreateModal}
						isLoading={isLoading}
					/>
				</Modal>
			)}
		</div>
	)
}

export default withRouter(PositionManagementAreaList)
