import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useGoogleLogin } from '@react-oauth/google';
import { useRollbarPerson, useRollbarContext } from '@rollbar/react';

import { Form, Spin, Button } from 'antd';
import Logo from 'assets/logo.png';
import { CONFIG_COOKIES } from 'config/cookies';
import { setCookies, getCookies } from 'utils/cookies';
import { MENU } from 'config/menu';
import { authApi } from 'api/auth';
import FallbackIcon from 'assets/ic-icon-square';
import IcGoogle from 'assets/ic-google';
import { stringToInteger } from 'utils/formatter/string';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import { usersApi } from 'api/users';

import './login.scss';

function Login({ history, location }) {
  useRollbarContext('Login Page');
  const version = process.env.REACT_APP_VERSION;
  const sendError = useRollbarNotifier();

  const { from } = location.state || {
    from: { pathname: MENU.DASHBOARD },
  };

  const [isLoading, setIsLoading] = useState(false);
  const [showChooseMerchant, setShowChooseMerchant] = useState(false);
  const [merchantList, setMerchantList] = useState([]);

  useEffect(() => {
    const token = getCookies(CONFIG_COOKIES.TOKEN);
    const merchantId = getCookies(CONFIG_COOKIES.MERCHANT_ID);

    if (token && merchantId) {
      history.replace(from);
    }

    if (token && !merchantId) {
      setShowChooseMerchant(true);
    }
  }, [location.search, history, from]);

  useEffect(() => {
    if (showChooseMerchant) {
      const userId = getCookies(CONFIG_COOKIES.USER_ID);

      setIsLoading(true);

      const fetchData = async () => {
        try {
          const merchants = await authApi.getChooseMerchantPageData(userId);
          setMerchantList(merchants.data.data);

          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          sendError('Error fetching getChooseMerchantPageData', error);
        }
      };

      fetchData();
    }
  }, [showChooseMerchant]);

  const [currentUser, setCurrentUser] = useState();
  useRollbarPerson(currentUser);

  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const token = tokenResponse.code;
      try {
        setIsLoading(true);
        const res = await authApi.loginWithGoogle(token);
        setCurrentUser({
          id: stringToInteger(res.data.user_name),
          username: res.data.user_name,
          email: res.data.user_email,
        });
        setCookies(CONFIG_COOKIES.TOKEN, res?.data?.access_token);
        setCookies(CONFIG_COOKIES.REFRESH_TOKEN, res?.data?.refresh_token);
        setCookies(CONFIG_COOKIES.EMAIL, res?.data?.user_email);
        setCookies(CONFIG_COOKIES.USER_ID, res?.data?.user_id);
        setCookies(CONFIG_COOKIES.USER_IMG_URL, res?.data?.user_image);
        setCookies(CONFIG_COOKIES.CODE, res?.data?.user_code);
        setCookies(CONFIG_COOKIES.FULLNAME, res?.data?.user_name);
        setCookies(CONFIG_COOKIES.ROLE, res?.data?.role_id);
        setIsLoading(false);
        setShowChooseMerchant(true);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    },
    flow: 'auth-code',
    onError: () => {
      console.error('Login Failed');
    },
  });

  const chooseMerchant = async (merchantId) => {
    try {
      setIsLoading(true);
      const permissions = await usersApi.getUserPermissions(merchantId);
      setCookies(CONFIG_COOKIES.MERCHANT_ID, merchantId);
      setCookies(CONFIG_COOKIES.ROLE_TITLE, permissions?.data?.role || '');
      setCookies(CONFIG_COOKIES.PERMISSIONS, permissions?.data?.permissions || []);

      setIsLoading(false);
      history.replace(from);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-cf-bg-color">
      {isLoading && (
        <div className="w-11/12 max-w-xl text-center">
          <Spin />
        </div>
      )}

      {!isLoading && !showChooseMerchant && (
        <div className="w-11/12 max-w-xl px-16 py-12 rounded-xl text-center bg-white shadow-cf-login">
          <img className="h-16 m-auto mb-8" src={Logo} alt="" />
          <h2 className="m-2">Sign In to CANFAZZ</h2>
          <p>With your Google Account</p>
          <div className="mt-10 flex items-center justify-center">
            <Button loading={isLoading} onClick={loginWithGoogle} size="large" className="w-96 h-20 flex items-center">
              {!isLoading && <IcGoogle />}
              <div className="ml-14">Sign in with Google</div>
            </Button>
          </div>
        </div>
      )}

      {!isLoading && showChooseMerchant && (
        <div className="w-11/12 max-w-xl px-16 py-12 rounded-xl text-center bg-white shadow-cf-login">
          <h2 className="m-2">Silakan masuk ke salah satu merchant di bawah ini</h2>
          <div>
            {merchantList &&
              merchantList.map((merchant) => {
                return (
                  <button
                    type="button"
                    key={merchant.merchant_id}
                    className="w-full p-8 mt-4 border bg-white shadow-md"
                    onClick={() => chooseMerchant(merchant.merchant_id)}
                  >
                    <div className="flex justify-items-start">
                      <div className="w-24">
                        {merchant.merchant_logo_url ? (
                          <img className="w-16" src={merchant.merchant_logo_url} alt="Merchant Logo" />
                        ) : (
                          <FallbackIcon />
                        )}
                      </div>
                      <div className="flex flex-col flex-grow pl-4">
                        <div className="w-full text-left text-2xl font-bold leading-loose">{merchant.merchant_name}</div>
                        <div className="w-full text-left text-lg text-gray-500">{merchant.user_role_name}</div>
                      </div>
                    </div>
                  </button>
                );
              })}
          </div>
        </div>
      )}

      <div className="fixed bottom-0 left-0 p-4">{version}</div>
    </div>
  );
}

Login.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const WrappedLogin = Form.create({ name: 'login' })(Login);

export default WrappedLogin;
