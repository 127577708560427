import { useState, useEffect } from 'react'
import { notification } from 'antd'
import { usersApi } from 'api/users'
import { getQueryString } from 'utils/query'
import { getCookies } from 'utils/cookies'
import { CONFIG_COOKIES } from 'config/cookies'

const GREAT_NUMBER = 9999
const PAGE_LIMIT = 10
// const MERCHANT_ID = 'd82cbe2e-bac7-4a1b-be4c-dc6be229e41c'

function useUserListPage({ location }) {
	const [users, setUsers] = useState([])
	const [roles, setRoles] = useState(null)
	const [searchValue, setSearchValue] = useState('')
	const [currentPage, setCurrentPage] = useState(1)
	const [totalUsers, setTotalUsers] = useState(0)
	const [isLoading, setIsLoading] = useState(false)
	const [showCreateUserModal, setShowCreateUserModal] = useState(false)

	const fetchData = async () => {
		setIsLoading(true)

		try {
			const query = { ...getQueryString(location.search) }
			if (roles == null) {
				const rolesRes = await usersApi.getRoles({ per_page: GREAT_NUMBER })
				setRoles(rolesRes.data.data)
			}

			setCurrentPage(query.page)
			setSearchValue(query.search)

			const usersRes = await usersApi.get({
				perPage: PAGE_LIMIT,
				search: query.search,
				role: query.role,
				status: query.status,
				page: query.page,
				merchant_id: getCookies(CONFIG_COOKIES.MERCHANT_ID),
			})

			setUsers(usersRes.data.data)
			setTotalUsers(usersRes.data.metadata.total_count)

			setIsLoading(false)
		} catch (ex) {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [location.search])

	const submitCreateUser = async (formData) => {
		setIsLoading(true)

		try {
			await usersApi.createUser(formData)

			setIsLoading(false)
			setShowCreateUserModal(false)

			fetchData()

			notification.success({
				message: 'Success Create User',
				description: `Success create user ${formData.name}`,
			})
		} catch (ex) {
			setIsLoading(false)
		}
	}

	return {
		users,
		totalUsers,
		roles,
		searchValue,
		currentPage,
		isLoading,
		showCreateUserModal,
		setSearchValue,
		setShowCreateUserModal,
		submitCreateUser,
	}
}

export default useUserListPage
