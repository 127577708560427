import { STATUS_COLORS } from './colors';

export const USER_STATUSES = [
  { key: '', value: 'All status', label: 'All status' },
  { key: 'active', value: 'Active', label: 'Active' },
  { key: 'suspended', value: 'Suspended', label: 'Suspended' },
  { key: 'banned', value: 'Banned', label: 'Banned' },
];

export const USER_POSITION_ZONES = [
  { key: 'area_presets', value: 'Area Preset' },
  { key: 'national', value: 'National' },
  { key: 'Province', value: 'Province' },
  { key: 'Regency', value: 'Regency' },
  { key: 'District', value: 'District' },
  { key: 'Village', value: 'Village' },
];

export const POSITION_TYPE = {
  AREA_PRESET: 'area_presets',
  NATIONAL: 'national',
  PROVINCE: 'Province',
  REGENCY: 'Regency',
  DISTRICT: 'District',
  VILLAGE: 'Village',
};

export const USER_POSITION_AVAILABILITY = [
  { key: '', value: 'All status' },
  { key: 'available', value: 'Available' },
  { key: 'filled', value: 'Filled' },
];

export const SALES_CODES = ['SPV', 'SE', 'HOS', 'HOR', 'HOA'];

export const MAP_ROLE_TO_LOCATION = {
  SE: {
    fieldName: 'villages',
    type: 'village',
  },
  SPV: {
    fieldName: 'districts',
    type: 'district',
  },
  HOA: {
    fieldName: 'regencies',
    type: 'regency',
  },
  HOR: {
    fieldName: 'regencies',
    type: 'regency',
  },
  HOS: {
    fieldName: 'provinces',
    type: 'province',
  },
};

export const CLIENTS = ['Payfazz', 'Kaspin', 'Sellfazz'];

export const DOMAIN_TAG_INITIALS = {
  payfazz: 'PF',
  shopfazz: 'SF',
};

export const DOMAIN_TAG_COLORS = {
  payfazz: '#3b8ef3',
  shopfazz: '#ec1e24',
};

export const LS_POSITION_NASIONAL = {
  PROCESSED: 'LOCATION_PRESET_NASIONAL',
  RAW: 'LOCATION_PRESET_NASIONAL_RAW',
};

export const MAXIMUM_PROVINCES_LOAD_ALL = 5;

export const LOCATION_PRESET_EXPIRED = 7;

export const COLOR_STATUS_MAPPED = {
  active: STATUS_COLORS.green,
  pending: STATUS_COLORS.blue,
  suspended: STATUS_COLORS.yellow,
  banned: STATUS_COLORS.red,
};
