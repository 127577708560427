import { POSITION_TYPE } from 'config/users';
import { calculateLocationCodes } from 'utils/others/geofazz';
import { GeoApiV2 } from 'api/geo-location-v2';

import { filterDataTreeAreaPreset } from './filterDataTreeAreaPreset';

import { rawResponseToTreeObject, checkAvailability } from './other';

async function getProvincesGeoApi(type, geofazzParams) {
  let provinces = [];
  if (type === POSITION_TYPE.NATIONAL) {
    const res = await GeoApiV2.getLocationByScope(POSITION_TYPE.PROVINCE);
    provinces = res.data.data;
  }
  if (geofazzParams.provinceCodes.length > 0) {
    const res = await GeoApiV2.getLocationByCodes({ codes: geofazzParams.provinceCodes });
    provinces = res.data.data;
  }
  return provinces;
}

/**
 * Fetches data from the Geofazz API.
 *  * // 1. Fetch Provinces to get all province and all regencies
 *  * // 2. Generate dataTree based on provinces and districts from API
 *  * // 3. Filter dataTree for Area Preset because location in area_preset is filtered
 *  * // 4. Check availability if needed
 *
 * @param {string[]} options.territories -  is array of territories code to fetch data for.
 * @param {string} options.type - string of POSITION_TYPE ('area_preset', 'regency', etc.)
 * @param {boolean} options.apiForAvailability - Flag indicating whether to check availability using another API.
 * @returns antd dataTree object
 */
export const fetchGeofazzApi = async ({ territories, type, apiForAvailability }) => {
  const geofazzParams = calculateLocationCodes(territories);
  const provinces = await getProvincesGeoApi(type, geofazzParams);
  let dataTree = provinces.map(rawResponseToTreeObject);

  if (type === POSITION_TYPE.AREA_PRESET) {
    dataTree = filterDataTreeAreaPreset(dataTree, geofazzParams);
  }

  if (apiForAvailability) {
    const newDataTreeWithAvailability = await checkAvailability(dataTree, apiForAvailability);
    return newDataTreeWithAvailability;
  }

  return dataTree;
};

export default fetchGeofazzApi;
