import React from 'react'
import TabsPage from '../../layout/tabs-page'
import MENU from '../../config/menu'
import PositionListPage from './position/list'
import PositionManagementAreaList from './area'

const PositionManagementTabs = () => {
	const tabs = [
		{
			key: 'position',
			title: 'Position',
			content: <PositionListPage />,
		},
		{
			key: 'area-presets',
			title: 'Area Preset',
			content: <PositionManagementAreaList />,
		},
	]
	return (
		<div>
			<TabsPage
				pageTitle="Position Management"
				urlPush={MENU.POSITION_MANAGEMENT}
				tabs={tabs}
			/>
		</div>
	)
}

export default PositionManagementTabs
