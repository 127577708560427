import React, { useState } from 'react'
import FallbackIcon from '../../../assets/ic-icon-square'

function SquareImage({ src, width = 48, alt = 'logo' }) {
	const [isErrorImage, setIsErrorImage] = useState(false)
	if (!src) return <FallbackIcon />
	if (isErrorImage) return <FallbackIcon />
	return (
		<img
			style={{ objectFit: 'cover', width, height: width }}
			src={src}
			alt={alt}
			onError={({ currentTarget }) => {
				currentTarget.onerror = null // prevents looping
				setIsErrorImage(true)
				currentTarget.src = ''
			}}
		/>
	)
}

export default SquareImage
