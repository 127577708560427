import React, { useState } from 'react';
import { Icon, Select, Spin, Empty } from 'antd';
import { debounce } from 'utils/others';
import { visitAssignmentApi } from 'api/visit-assignment';

export default function SelectSearchVisitor(props) {
  const [loading, setLoading] = useState(false);
  const [renderOptions, setRenderOptions] = useState([]);

  const fetchData = async (val) => {
    if (!val) {
      setRenderOptions([]);
      setLoading(false);
      return;
    }
    try {
      setLoading(true);
      const res = await visitAssignmentApi.getVisitorList({ search: val });
      setRenderOptions(res?.data?.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // sendError(`Error when fetch SearchQueryByApi list options`, error);
    }
  };

  return (
    <Select //
      labelInValue
      allowClear
      showSearch
      size="large"
      suffix={<Icon type="search" />}
      placeholder="Type to search..."
      notFoundContent={loading ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Type to search..." />}
      className="mt-2"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      filterOption={false}
      onSearch={debounce(fetchData, 1000)}
      loading={loading}
    >
      {!loading &&
        renderOptions.map((opt) => (
          <Select.Option key={opt.user_id} value={opt.user_id}>
            <div className="flex justify-between items-center gap-4 w-full" style={{ width: 428 }}>
              <div>{opt.user_name}</div>
              <div className="italic text-cf-darkBlue">{opt.position_label}</div>
            </div>
          </Select.Option>
        ))}
    </Select>
  );
}
