/* eslint-disable */
import React, { useState } from 'react';
import { Icon, Upload, message } from 'antd';
import { trimSlashes } from 'utils/formatter/string';
import { CONTENT_API_URL } from '../../config/url';
import { formsApi } from '../../api/form';
import './upload.scss';

const UploadComponent = ({
  setObjectId = () => {},
  multiple,
  multipleUpload,
  customRemove,
  beforeUpload,
  thumbUrl,
  disabled,
  listType,
  description,
  fileFormats,
  uploadedData = [],
  onRemove = () => {},
}) => {
  const [fileList, setFileList] = useState([]);
  const props = {
    name: 'file',
    beforeUpload,
    listType: listType || null,
    multiple: multiple || false,
    fileList: uploadedData || fileList,
    onRemove: (file) => {
      if (multiple) {
        setFileList(fileList.filter((item) => item.uid !== file.uid));
        customRemove(file.uid);
      } else {
        setFileList([]);
        setObjectId({ key: '' });
      }
      onRemove(file);
    },
    customRequest: (info) => {
      formsApi
        .uploadContent(info.file)
        .then((res) => {
          if (!multipleUpload) {
            setFileList([info.file]);
            setObjectId({ key: res.data.key });
          } else {
            setFileList([
              ...fileList,
              ...[
                {
                  uid: res.data.key,
                  name: info.file.name,
                  status: 'done',
                  url: `${trimSlashes(CONTENT_API_URL)}/${res.data.key}`,
                  thumbUrl: thumbUrl ? `${trimSlashes(CONTENT_API_URL)}/${res.data.key}` : null,
                },
              ],
            ]);
            multipleUpload({
              uid: res.data.key,
              name: info.file.name,
              status: 'done',
              url: `${trimSlashes(CONTENT_API_URL)}/${res.data.key}`,
              thumbUrl: thumbUrl ? `${trimSlashes(CONTENT_API_URL)}/${res.data.key}` : null,
            });
          }
          message.success(`${info.file.name} file uploaded successfully.`);
        })
        .catch(() => {
          message.error(`${info.file.name} file upload failed.`);
          setFileList([]);
        });
    },
  };

  return (
    <div className={`custom-upload-style ${disabled ? '--disabled' : ''}`}>
      {disabled && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: '3',
            backgroundColor: 'rgba(0, 0, 0, 0.65)',
            opacity: '0.05',
            cursor: 'not-allowed',
          }}
        />
      )}
      <Upload.Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <Icon type="inbox" />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        {description || fileFormats ? (
          <div style={{ marginTop: '15px' }}>
            {description && (
              <>
                <small>{description || ''}</small>
                <br />
              </>
            )}
            {fileFormats && fileFormats.length > 0 && <small>{`File formats: ${fileFormats.join().replace(/,/g, '/') || ''}`}</small>}
          </div>
        ) : null}
      </Upload.Dragger>
    </div>
  );
};

export default UploadComponent;
