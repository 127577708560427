import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactGA from 'react-ga4';
import { isProduction } from 'utils/access-right';

import ConfigRoute from './router';
import * as serviceWorker from './serviceWorker';
import './styles/index.scss';

const gAId = process.env.REACT_APP_GA_MEASUREMENT;
if (gAId && isProduction) {
  ReactGA.initialize(gAId);
}

ReactDOM.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <ConfigRoute />
  </GoogleOAuthProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
