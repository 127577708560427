import React from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';

import Layout from 'layout/main';
import useSalesFunnel from './useSalesFunnel';
import StagedCanvas from './component/StagedCanvas';
import SummarySection from './component/SummarySection';
import FilterQuerySection from './component/FilterQuerySection';

const DEFAULT_QUERIES = {
  month: moment().format('M'),
  year: moment().format('YYYY'),
  quarter: '1',
  type: 'monthly',
};

function SalesFunnel() {
  const { data, loading } = useSalesFunnel({ defaultQueries: DEFAULT_QUERIES });

  return (
    <Layout title="Sales Funnel" loading={loading}>
      <div className="app-content">
        <FilterQuerySection defaultQueries={DEFAULT_QUERIES} />

        <p className="mx-2 my-4 font-bold text-cf-14">Leads created between 12/1/2022 and 12/31/2022</p>
        <Row gutter={[16, 8]}>
          <Col md={24} xl={15}>
            <StagedCanvas data={data} loading={loading} />
          </Col>
          <Col md={24} xl={9}>
            <SummarySection data={data} loading={loading} />
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default SalesFunnel;
