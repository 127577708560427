import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal, Input, notification } from 'antd';
import { MerchantApi } from 'api/merchant';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';

const { TextArea } = Input;

function ToggleActivateMerchant({ visible, close, merchant, onSuccess }) {
  const [reasonValue, setReasonValue] = useState('');
  const [loading, setLoading] = useState(false);
  const sendError = useRollbarNotifier();

  const isActiveMerchant = merchant.is_active;

  const handleSubmit = async () => {
    const callApi = isActiveMerchant ? MerchantApi.deactivate : MerchantApi.activate;

    const bodyReq = {
      merchant_id: merchant?.id,
      reason: reasonValue,
    };

    try {
      setLoading(true);
      await callApi(bodyReq);
      setLoading(false);
      notification.success({
        message: 'Success',
        description: isActiveMerchant ? 'Merchant has been Deactivated successfully' : 'Merchant has been Activated successfully',
      });
      setReasonValue('');
      close();
      onSuccess();
    } catch (error) {
      setLoading(false);
      sendError(`Error when ${isActiveMerchant ? 'deactivate' : 'activate'} merchant`, error);
    }
  };

  return (
    <Modal
      loading={loading}
      destroyOnClose
      maskClosable={false}
      onOk={handleSubmit}
      title={<span style={{ fontWeight: 700 }}>{isActiveMerchant ? 'Deactivate' : 'Activate'} Merchant</span>}
      visible={visible}
      onCancel={() => {
        close();
        setReasonValue('');
      }}
      okText={isActiveMerchant ? 'Deactivate' : 'Activate'}
      okButtonProps={{ disabled: !reasonValue }}
      cancelText="Cancel"
      centered
    >
      <p className="text-center text-cf-16">Are you sure want to {isActiveMerchant ? 'deactivate' : 'activate'} this Merchant?</p>
      <p className="text-cf-14 mt-8 mb-4">
        Reason<span style={{ color: '#ac2f24' }}> *</span>
      </p>
      <TextArea rows={4} value={reasonValue} onChange={(e) => setReasonValue(e.target.value)} />
    </Modal>
  );
}

ToggleActivateMerchant.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  merchant: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
};

ToggleActivateMerchant.defaultProps = {
  onSuccess: () => {},
};

export default withRouter(ToggleActivateMerchant);
