import React from 'react';
import PropTypes from 'prop-types';

function Tag({ children, textColor, bgColor, className }) {
  return (
    <div className={className}>
      <div
        style={{ height: 24, color: textColor, background: bgColor, border: `1px solid ${textColor}` }}
        className="font-bold rounded-full flex justify-center items-center px-4 whitespace-nowrap text-xl"
      >
        {children || '-'}
      </div>
    </div>
  );
}

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  textColor: PropTypes.string,
  bgColor: PropTypes.string,
  className: PropTypes.string,
};

Tag.defaultProps = {
  textColor: '#121212',
  bgColor: '#F2F4F5',
  className: '',
};

export default Tag;
