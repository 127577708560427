/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React from 'react';
import { Pagination, Table, Row } from 'antd';
import { withRouter } from 'react-router-dom';
import qs from 'qs';

import NextPrevPagination from '../Pagination';
import { getQueryStringForPagination } from '../../utils/query';
import './table.scss';

function TableList({
  dataSource,
  loading,
  rowSelection,
  columns,
  history,
  urlPush,
  location,
  count,
  withoutPagination,
  paginationType = 'default', // 'next-prev' | 'default'
  ...props
}) {
  const query = getQueryStringForPagination(location.search);

  const changePage = (page) => {
    const pageQuery = { ...query, page };
    if (page === 1) delete pageQuery.page;
    history.push(`${urlPush}?${qs.stringify(pageQuery)}`);
  };
  const { page } = query;

  return (
    <div className="app-table">
      <div className="app-table__table-container">
        <Table
          className="table-responsive"
          loading={loading}
          rowSelection={rowSelection}
          pagination={false}
          dataSource={dataSource}
          columns={columns}
          size="middle"
          {...props}
        />
      </div>
      {!withoutPagination && paginationType === 'default' && (
        <Row type="flex" justify="center" style={{ marginTop: '1em' }}>
          <Pagination
            size="small"
            showLessItems
            showQuickJumper
            onChange={changePage}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            current={page}
            total={count}
          />
        </Row>
      )}

      {!withoutPagination && paginationType === 'next-prev' && (
        <Row type="flex" justify="end" style={{ marginTop: '1em' }}>
          <NextPrevPagination limit={10} currentDataLength={dataSource.length} loading={loading} />
        </Row>
      )}
    </div>
  );
}

export function TableTitle({ className, ...props }) {
  return <p className={`font-bold text-cf-14 pt-6 pb-6 ${className || ''}`} {...props} />;
}

export default withRouter(TableList);
