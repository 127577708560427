/* eslint-disable no-param-reassign */
import axios from 'axios';
import { trimSlashes } from 'utils/formatter/string';
import { preRequestInterceptor, errorInterceptor } from './interceptors';

const instance = axios.create();
instance.interceptors.request.use(preRequestInterceptor, (err) => Promise.reject(err));
instance.interceptors.response.use((response) => response, errorInterceptor);

export const call = (method, url, subUrl = '', data = {}, additionalConfig = {}, additionalHeaders = {}) => {
  const config = {
    baseURL: url,
    method,
    url: `${trimSlashes(url)}/${trimSlashes(subUrl)}`,
    headers: {
      'Content-Type': 'application/json',
      ...additionalHeaders,
    },
    ...additionalConfig,
  };
  if (method === 'GET') {
    Object.keys(data).forEach((key) => {
      if (data[key] === null || data[key] === '') {
        delete data[key];
      }
    });
    config.params = data;
  } else {
    config.data = data;
  }
  return instance.request(config);
};

export default call;
