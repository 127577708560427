import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Spin, TreeSelect } from 'antd';

import { GeoApiV2 } from 'api/geo-location-v2';
import { areasApi } from 'api/areas';

import { POSITION_TYPE } from 'config/users';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import { calculateLocationCodes as generateGeofazzParams } from 'utils/others/geofazz';
import useGetLocationLabels from './useGetLocationLabels';

import {
  fetchGeofazzApi,
  filterChildrenTreeAreaPreset,
  checkAvailability,
  rawResponseToTreeObject,
  assignChildToParent,
  renderTreeData,
  SEARCH_AREA_TYPES,
  SEARCH_AREA_VALUE,
  SEARCH_AREA_TERRITORIES,
} from './helper';

function FormItemGeneral({ form, initialValue: initialTerritories, apiForAvailability }) {
  const [loading, setLoading] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [geoFazzParams, setGeoFazzParams] = useState(null);
  const sendError = useRollbarNotifier();

  const { labelLoading } = useGetLocationLabels({ form, initialTerritories });

  const { getFieldValue, getFieldDecorator, setFieldsValue } = form;
  const searchAreaType = getFieldValue(SEARCH_AREA_TYPES);
  const searchAreaValue = getFieldValue(SEARCH_AREA_VALUE);
  const positionRoleId = getFieldValue('role_id')?.key || getFieldValue('role_id');

  const apiAvailability = apiForAvailability
    ? async (allLocationCodes) =>
        apiForAvailability({
          territory_ids: allLocationCodes,
          role_id: positionRoleId,
        })
    : null;

  const fetchChildren = async (parentId, parentType, onSuccess = () => {}) => {
    try {
      setLoading(true);
      const res = await GeoApiV2.getChildrenByParentId(parentId);
      let childrenTree = res.data.data.map(rawResponseToTreeObject);

      if (getFieldValue(SEARCH_AREA_TYPES) === POSITION_TYPE.AREA_PRESET) {
        childrenTree = filterChildrenTreeAreaPreset(childrenTree, geoFazzParams, parentId, parentType);
      }

      if (apiAvailability) {
        const newDataTreeWithAvailability = await checkAvailability(childrenTree, apiAvailability);
        childrenTree = newDataTreeWithAvailability;
      }

      setTreeData((tree) => assignChildToParent(tree, parentId, childrenTree));
      onSuccess(childrenTree);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      sendError('Error fetching fetchChildren at territory form item', error);
    }
  };

  const onLoadChildren = (treeNode) => {
    return new Promise((resolve) => {
      if (treeNode.props.children.length > 0) {
        resolve();
        return;
      }
      if (treeNode.props.type === POSITION_TYPE.PROVINCE) {
        resolve();
        return;
      }

      const { id, type } = treeNode.props;
      fetchChildren(id, type, resolve);
    });
  };

  const updateDataTree = async (territories = []) => {
    const type = getFieldValue(SEARCH_AREA_TYPES);
    if (!territories && territories.length === 0) {
      return;
    }

    try {
      setLoading(true);
      const newDataTree = await fetchGeofazzApi({
        territories,
        type,
        apiForAvailability: apiAvailability,
      });

      setTreeData(newDataTree);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      sendError('Error fetching updateDataTree at territory form item', error);
    }
  };

  useEffect(() => {
    const newType = getFieldValue(SEARCH_AREA_TYPES);
    if (newType === POSITION_TYPE.AREA_PRESET) {
      return;
    }

    if (newType === POSITION_TYPE.NATIONAL) {
      updateDataTree([]);
    } else {
      const onlyUnique = (value, index, array) => array.indexOf(value) === index;
      const splitProvince = (loc) => loc.split('.')[0];
      updateDataTree(initialTerritories.map(splitProvince).filter(onlyUnique));
    }
  }, [searchAreaType]);

  useEffect(() => {
    if (getFieldValue(SEARCH_AREA_TYPES) !== POSITION_TYPE.AREA_PRESET) {
      return;
    }
    const { key, label } = getFieldValue(SEARCH_AREA_VALUE);

    const fetchAreaPreset = async () => {
      let areaPresetDetail = null;

      try {
        if (!key) {
          const { data } = await areasApi.searchAreaPresets({
            scope: POSITION_TYPE.AREA_PRESET,
            name: label,
          });
          areaPresetDetail = data.find((dt) => dt.label.toLowerCase() === label.toLowerCase());
        } else {
          const { data } = await areasApi.areaPresetDetail(key);
          areaPresetDetail = data;
        }
      } catch (error) {
        sendError(`Error when fetching areaPresetDetail on TerritoryFormItem useEffect`, error);
      }

      setFieldsValue({
        search_area_value: {
          key: areaPresetDetail.id,
          label: areaPresetDetail.name,
        },
      });

      setGeoFazzParams(generateGeofazzParams(areaPresetDetail?.territories || []));
      updateDataTree(areaPresetDetail?.territories || []);
    };

    fetchAreaPreset();
  }, [searchAreaValue?.key]);

  return (
    <Form.Item label="Territory">
      <div className="flex flex-nowrap justify-between">
        <div className="flex-grow" id="territoryContainer">
          {getFieldDecorator(SEARCH_AREA_TERRITORIES, {
            rules: [
              {
                required: true,
                message: 'Please input territories',
              },
            ],
          })(
            <TreeSelect
              labelInValue
              treeCheckable
              treeDataSimpleMode
              maxTagCount={10}
              placeholder="Select Territory"
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              disabled={loading || labelLoading || !getFieldValue(SEARCH_AREA_TYPES)}
              treeData={renderTreeData({ treeData: treeData || [], withAvailability: !!apiForAvailability })}
              getPopupContainer={() => document.getElementById('territoryContainer')}
              treeNodeLabelProp="label"
              treeNodeFilterProp="label"
              loadData={onLoadChildren}
              showCheckedStrategy={TreeSelect.SHOW_PARENT}
              treeCheckStrictly={getFieldValue(SEARCH_AREA_TYPES) === POSITION_TYPE.AREA_PRESET}
            />,
          )}
        </div>
        {(loading || labelLoading) && (
          <div className="ml-4">
            <Spin size="small" />
          </div>
        )}
      </div>
    </Form.Item>
  );
}

FormItemGeneral.propTypes = {
  apiForAvailability: PropTypes.any,
  form: PropTypes.object.isRequired,
  initialValue: PropTypes.array,
};

FormItemGeneral.defaultProps = {
  apiForAvailability: null,
  initialValue: [],
};

export default FormItemGeneral;
