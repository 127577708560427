import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { RollbarContext } from '@rollbar/react';
import Cookies from 'js-cookie';

import { CONFIG_COOKIES } from 'config/cookies';
import { MENU } from 'config/menu';
import { hasRight } from 'utils/access-right';
import Page404 from 'pages/page-404';
import usePageTracking from 'customHooks/usePageTracking';

function PrivateRouter({ component: Component, accessType = null, path, exact = false, redirectPath = '', pageEvent = '' }) {
  usePageTracking({ pageEvent, path });

  const isAuthenticated = !!Cookies.get(CONFIG_COOKIES.TOKEN);
  const hasRedirectPath = !!redirectPath;

  const renderRouteComponent = ({ match, location, history, staticContext }) => {
    if (!isAuthenticated) {
      return <Redirect to={{ pathname: MENU.LOGIN, state: { from: location } }} />;
    }

    if (accessType && !hasRight(accessType)) {
      return <Page404 />;
    }

    if (hasRedirectPath) {
      return <Redirect to={{ pathname: redirectPath }} />;
    }

    return <Component match={match} location={location} history={history} staticContext={staticContext} />;
  };

  return (
    <RollbarContext context={path}>
      <Route exact={exact} path={path} render={renderRouteComponent} />
    </RollbarContext>
  );
}

PrivateRouter.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  accessType: PropTypes.object,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool.isRequired,
  redirectPath: PropTypes.string,
  pageEvent: PropTypes.any,
};

PrivateRouter.defaultProps = {
  accessType: null,
  redirectPath: '',
  component: null,
  pageEvent: '',
};

export default PrivateRouter;
