import { CONFIG_COOKIES } from '../../config/cookies';
import { getCookies } from '../cookies';

export const hasRight = (accessType) => {
  const menu = accessType?.menu || '';
  const type = accessType?.type || '';

  const fromCookies = getCookies(CONFIG_COOKIES.PERMISSIONS) || '';
  if (!fromCookies) return false;
  const permissionsByMenu = JSON.parse(fromCookies)[menu] || [];

  const hasPermission = permissionsByMenu.map((p) => p.toLowerCase()).includes(type.toLowerCase());

  return hasPermission;
};

export const isProduction = process.env.REACT_APP_ROLLBAR_ENV === 'production';
