import React, { useState } from 'react';
import { Modal, Icon, notification } from 'antd';
import { Button } from 'components';
import PropTypes from 'prop-types';
import { visitAssignmentApi } from 'api/visit-assignment';

function DeleteAssignmentButton({ assignmentId, onSuccess }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const close = () => setOpen(false);

  const handleDelete = async () => {
    setLoading(true);
    await visitAssignmentApi.deleteAssignee(assignmentId);
    setLoading(true);
    notification.success({
      message: 'Success',
      description: 'Assignment Deleted',
    });
    onSuccess();
    close();
  };

  return (
    <>
      <Button size="small" onClick={() => setOpen(true)} className="max-w-min bg-cf-darkRed text-white">
        <Icon theme="filled" type="delete" />
      </Button>

      <Modal destroyOnClose visible={open} onCancel={close} maskClosable={false} footer={null}>
        <h2 className="font-bold text-3xl mb-12">Delete Visit Assignment</h2>
        <p className="pb-16 pt-6 border-y text-3xl font-medium">Are you sure want to delete this visit assignment?</p>
        <div className="flex w-full mt-4 justify-end">
          <Button
            style={{
              marginRight: '0.5em',
              borderRadius: 12,
            }}
            onClick={close}
            className="border-cf-gray"
          >
            Cancel
          </Button>
          <Button
            type="danger"
            onClick={handleDelete}
            htmlType="submit"
            loading={loading}
            style={{
              borderRadius: 12,
            }}
            className="bg-cf-darkRed text-white"
          >
            Delete
          </Button>
        </div>
      </Modal>
    </>
  );
}

DeleteAssignmentButton.propTypes = {
  assignmentId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default DeleteAssignmentButton;
