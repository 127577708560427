import React from 'react'
import { Breadcrumb, Icon } from 'antd'
import { Link, withRouter } from 'react-router-dom'

import MENU from '../../config/menu'
import './breadcrumb.scss'
// import { TASK_TYPES } from '../../config/task'

export const BREADCRUMB_ITEMS = {
	// TR: (
	// 	<Breadcrumb.Item key="tr">
	// 		<Link to={MENU.TASK_REVIEW_LIST}>Task Review</Link>
	// 	</Breadcrumb.Item>
	// ),
	// [`TR_${TASK_TYPES.ACQUISITION}`]: (
	// 	<Breadcrumb.Item key="tr-acq">
	// 		<Link to={`${MENU.TASK_REVIEW_LIST}/${TASK_TYPES.ACQUISITION}`}>
	// 			Acquisition
	// 		</Link>
	// 	</Breadcrumb.Item>
	// ),
	UM: (
		<Breadcrumb.Item key="tr">
			<Link to={MENU.USER_MANAGEMENT}>User Management</Link>
		</Breadcrumb.Item>
	),
	PM: (
		<Breadcrumb.Item key="pm">
			<Link to={MENU.POSITION_MANAGEMENT}>Position Management</Link>
		</Breadcrumb.Item>
	),
	AREA_PRESET: (
		<Breadcrumb.Item key="area-preset">
			<Link to={`${MENU.POSITION_MANAGEMENT}/${MENU.AREA_PRESET}`}>
				Area Preset
			</Link>
		</Breadcrumb.Item>
	),
	AREA_PRESET_DETAIL: (
		<Breadcrumb.Item key="area-preset">
			<Link to={`${MENU.POSITION_MANAGEMENT}/${MENU.AREA_PRESET}`}>
				Detail Information
			</Link>
		</Breadcrumb.Item>
	),
	PM_EDIT: (id) => (
		<Breadcrumb.Item key="pm-edit">
			<Link to={`${MENU.POSITION_MANAGEMENT}/${id}`}>Detail Information</Link>
		</Breadcrumb.Item>
	),
	// RPT: (
	// 	<Breadcrumb.Item key="rpt">
	// 		<Link to={MENU.REPORT_MANAGEMENT}>Report Management</Link>
	// 	</Breadcrumb.Item>
	// ),
	RPTC: <Breadcrumb.Item key="rptc">Create Report</Breadcrumb.Item>,
	RPTE: <Breadcrumb.Item key="rpte">Edit Report</Breadcrumb.Item>,
	// KPI: (
	// 	<Breadcrumb.Item key="kpi">
	// 		<Link to={MENU.KPI_MANAGEMENT}>KPI Parameter</Link>
	// 	</Breadcrumb.Item>
	// ),
	KPI_IND: (name) => (
		<Breadcrumb.Item key="kpi-ind">KPI Individual {name}</Breadcrumb.Item>
	),
	KPIC: <Breadcrumb.Item key="kpic">Create KPI Parameter</Breadcrumb.Item>,
	KPIE: <Breadcrumb.Item key="kpie">Edit KPI Parameter</Breadcrumb.Item>,
	EDT: <Breadcrumb.Item key="edit">Edit</Breadcrumb.Item>,
	DTL: <Breadcrumb.Item key="detail">Detail Information</Breadcrumb.Item>,
	MM: <Breadcrumb.Item key="mm">Merchant Management</Breadcrumb.Item>,
	MM_DETAIL: (id) => (
		<Breadcrumb.Item key="mm-detail">
			<Link to={`${MENU.MERCHANT_MANAGEMENT_LIST}/${id}`}>
				Detail Information
			</Link>
		</Breadcrumb.Item>
	),
	AM: <Breadcrumb.Item key="am">Account Management</Breadcrumb.Item>,
	// AM_DETAIL: (id) => (
	// 	<Breadcrumb.Item key="am-detail">
	// 		<Link to={`${MENU.ACCOUNT_MANAGEMENT}/${id}`}>
	// 			Detail Information
	// 		</Link>
	// 	</Breadcrumb.Item>
	// ),
}

const AppBreadcrumb = ({ history, items, data = [] }) => {
	const BREADCRUMB_ITEMS_NEW = (
		<>
			{data.length > 0
				? data.map((item) => (
						<Breadcrumb.Item key="tr">
							{item.link !== null ? (
								<Link to={item.link}>{item.title}</Link>
							) : (
								<span>{item.title}</span>
							)}
						</Breadcrumb.Item>
				  ))
				: items}
		</>
	)
	return (
		<div className="app-breadcrumb flex">
			<Icon type="left" onClick={() => history.goBack()} />
			<Breadcrumb>{BREADCRUMB_ITEMS_NEW}</Breadcrumb>
		</div>
	)
}

export default withRouter(AppBreadcrumb)
