import { METHODS, API_URIS, API_BASE_URL, apiCall } from './config'
import { getCookies } from '../utils/cookies'
import { CONFIG_COOKIES } from '../config/cookies'

export const authApi = {
	login: (data) => apiCall(METHODS.POST, API_URIS.AUTH, 'v2/auth', data),
	loginViaCanfazz: (data) =>
		apiCall(METHODS.POST, API_BASE_URL, '/v1/user/login', data, {}),
	whois: () =>
		apiCall(
			METHODS.GET,
			API_URIS.CANFAZZ,
			'v1/whois',
			{},
			{},
			{ Authorization: `Bearer ${getCookies(CONFIG_COOKIES.TOKEN_CANFAZZ)}` },
		),
	companies: () =>
		apiCall(
			METHODS.GET,
			API_URIS.CLIENT,
			`v1/clients`,
			{},
			{
				Authorization: `Bearer ${getCookies(CONFIG_COOKIES.TOKEN_CANFAZZ)}`,
			},
		),
	getChooseMerchantPageData: (userId) =>
		apiCall(
			METHODS.GET,
			API_BASE_URL,
			`/v1/login/${userId}/choose-merchant-data`,
		),
	loginWithGoogle: (token) =>
		apiCall(
			METHODS.POST,
			API_BASE_URL,
			`/v1/login/dashboard?redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`,
			{
				token,
				login_type: 'google',
			},
		),
}
