import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, notification } from 'antd';
import { AcquisitionReviewApi } from 'api/acquisition-review';
import PropTypes from 'prop-types';

function ApproveAcquisition({ visible, close, payload, onSuccess }) {
  const [loading, setLoading] = useState(false);
  const acquisitionId = payload?.id || '';

  const handleApprove = async () => {
    setLoading(true);
    await AcquisitionReviewApi.approve(acquisitionId);
    setLoading(false);

    notification.success({
      message: 'Success',
      description: 'Acquisition Review approved',
    });
    close();
    onSuccess();
  };

  return (
    <Modal
      loading={loading}
      destroyOnClose
      maskClosable={false}
      onOk={handleApprove}
      title={<span style={{ fontWeight: 700 }}>Approve Acquisition Review</span>}
      visible={visible}
      onCancel={close}
      okText="Approve"
      cancelText="Cancel"
      okButtonProps={{ loading, size: 'large', className: 'font-bold' }}
      cancelButtonProps={{ size: 'large', className: 'font-bold' }}
    >
      <p style={{ fontSize: 16, textAlign: 'center' }}>Are you sure want to approve this acquisition review?</p>
    </Modal>
  );
}

ApproveAcquisition.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  payload: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default withRouter(ApproveAcquisition);
