import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import { areasApi } from 'api/areas';
import SearchSelect from 'components/search-select';
import { POSITION_TYPE, USER_POSITION_ZONES } from 'config/users';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import TerritoryFormItemGeneral from './TerritoryFormItem';
import { SEARCH_AREA_TYPES, SEARCH_AREA_VALUE } from './helper/other';

/**
 * This is the form item for:
 * "search_area_type",
 * "search_area_value",
 * "search_area_territories"
 */
function AreaFormItem({ form, apiForAvailability, initialValues, withoutRole, preventChangeType }) {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const searchAreaType = getFieldValue(SEARCH_AREA_TYPES);
  const sendError = useRollbarNotifier();

  const searchAreasByKeyword = async (keyword) => {
    try {
      const resp = await areasApi.searchAreaPresets({
        scope: getFieldValue(SEARCH_AREA_TYPES),
        name: keyword,
      });

      const searchResults = resp.data || [];
      return searchResults.map((dt) => ({
        text: dt.label,
        value: dt.id,
      }));
    } catch (error) {
      sendError(`Error when fetching data in useChart hooks`, error);
      return [];
    }
  };

  const onChangeAreaType = (newType) => {
    if (newType === POSITION_TYPE.AREA_PRESET) {
      setFieldsValue({
        search_area_territories: [],
        search_area_value: undefined,
      });
    } else {
      setFieldsValue({
        search_area_value: undefined,
      });
    }
  };

  const onChangeAreaValue = () => {
    setFieldsValue({ search_area_territories: [] });
  };

  const disableChangeAreaTypes = () => {
    const positionRoleId = getFieldValue('role_id')?.key || getFieldValue('role_id');
    if (preventChangeType) return true;
    if (withoutRole) return false;
    if (!positionRoleId) return true;
    return false;
  };

  const disabledSearchArea = () => {
    getFieldValue(SEARCH_AREA_TYPES);
    if (getFieldValue(SEARCH_AREA_TYPES) === POSITION_TYPE.NATIONAL) return true;
    if (!getFieldValue(SEARCH_AREA_TYPES)) return true;
    return false;
  };

  return (
    <div>
      <Form.Item label="Area" style={{ marginBottom: 0 }}>
        <Form.Item className="inline-block w-1/4">
          {getFieldDecorator(SEARCH_AREA_TYPES, {
            rules: [
              {
                required: true,
                message: 'Please select area',
              },
            ],
            initialValue: initialValues?.search_area_type.key || undefined,
          })(
            <Select placeholder="Select Area" disabled={disableChangeAreaTypes()} style={{ width: '100%' }} onChange={onChangeAreaType}>
              {USER_POSITION_ZONES.map((userPositionZone) => (
                <Select.Option key={userPositionZone.key}>{userPositionZone.value}</Select.Option>
              ))}
            </Select>,
          )}
        </Form.Item>

        <Form.Item className="inline-block w-3/4 pl-3">
          {getFieldDecorator(SEARCH_AREA_VALUE, {
            rules: [
              {
                required: false,
                message: 'Please input search area',
              },
            ],
            initialValue: initialValues?.search_area_value || undefined,
          })(
            <SearchSelect
              wordLimit={3}
              disabled={disabledSearchArea()}
              placeholder="Search"
              isEffect
              effect={searchAreaType}
              onChange={onChangeAreaValue}
              api={searchAreasByKeyword}
            />,
          )}
        </Form.Item>
      </Form.Item>

      <TerritoryFormItemGeneral form={form} initialValue={initialValues?.search_area_territories} apiForAvailability={apiForAvailability} />
    </div>
  );
}

AreaFormItem.propTypes = {
  form: PropTypes.object.isRequired,
  apiForAvailability: PropTypes.any,
  withoutRole: PropTypes.bool,
  preventChangeType: PropTypes.bool,
  initialValues: PropTypes.object,
};

AreaFormItem.defaultProps = {
  apiForAvailability: null,
  withoutRole: false,
  preventChangeType: false,
  initialValues: null,
};

export default AreaFormItem;
