export const VISITATION_STATUS_OPTIONS = [
  {
    key: 'empty',
    label: 'Empty',
    textColor: '#121212',
    bgColor: '#F2F4F5',
  },
  {
    key: 'not_started',
    label: 'Not Started',
    textColor: '#F18813',
    bgColor: '#FEF3E7',
  },
  {
    key: 'not_visited',
    label: 'Not Visited',
    textColor: '#D93025',
    bgColor: '#FBEAE9',
  },
  {
    key: 'visited',
    label: 'Visited',
    textColor: '#00B583',
    bgColor: '#E5F8F3',
  },
];

export const VISITATION_STATUS_ASSIGNEE_OPTIONS = [
  {
    key: 'in_progress',
    label: 'In Progress',
    textColor: '#F18813',
    bgColor: '#FEF3E7',
  },
  {
    key: 'not_achieved',
    label: 'Not Achieved',
    textColor: '#D93025',
    bgColor: '#FBEAE9',
  },
  {
    key: 'done',
    label: 'Done',
    textColor: '#00B583',
    bgColor: '#E5F8F3',
  },
];

export const ACQUISITION_OPTIONS = [
  {
    key: 'open',
    label: 'Leads',
    textColor: '#00B583',
    bgColor: '#E5F8F3',
  },
  {
    key: 'approved',
    label: 'Acquired', // TODO: need to be confirmed!
    textColor: '#0C45E1',
    bgColor: '#E7ECFC',
  },
  {
    key: 'inreview',
    label: 'In Review',
    textColor: '#F18813',
    bgColor: '#FEF3E7',
  },
  {
    key: 'rejected',
    label: 'Rejected',
    textColor: '#D93025',
    bgColor: '#FBEAE9',
  },
];
