/* eslint-disable */

export const formatNumber = (value) => {
	let newValue = value
	newValue += ''
	const list = newValue.split('.')
	const prefix = list[0].charAt(0) === '-' ? '-' : ''
	let num = prefix ? list[0].slice(1) : list[0]
	let result = ''
	while (num.length > 3) {
		result = `,${num.slice(-3)}${result}`
		num = num.slice(0, num.length - 3)
	}
	if (num) {
		result = num + result
	}
	return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`
}

export const debounce = (func, delay) => {
	let timeoutId
	return (...args) => {
		clearInterval(timeoutId)
		timeoutId = setTimeout(() => func.apply(this, args), delay)
	}
}

export const flatten = (arr) => {
	if (arr) {
		return arr.reduce((flat, toFlatten) => {
			return flat.concat(
				Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten,
			)
		}, [])
	}
	return []
}

export const isValidISODate = (date) => {
	return !!(new Date(date) !== 'Invalid Date' && !Number.isNaN(new Date(date)))
}

export const getTime = (value) => {
	const time = new Date(value)
	const result = time.getTime()
	return result
}

export const splitByComma = (value) => {
	const splitted = value.split(',')
	return splitted
}

export const compareBetweenValues = (value1, value2, inputValue) => {
	if (value1 <= inputValue && inputValue <= value2) {
		return true
	}
	return false
}

export const generateUID = () => {
	return (
		new Date().getTime() +
		Math.random()
			.toString(36)
			.substr(2, 9) +
		Math.random()
			.toString(36)
			.substr(2, 9)
	)
}

export const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export const formatDate = (date) => {
	const monthDict = {
		1: 'Jan',
		2: 'Feb',
		3: 'Mar',
		4: 'Apr',
		5: 'May',
		6: 'Jun',
		7: 'Jul',
		8: 'Aug',
		9: 'Sep',
		10: 'Oct',
		11: 'Nov',
		12: 'Dec',
	}
	const dateStr = new Date(date)

	return (
		dateStr.getDate() +
		' ' +
		monthDict[dateStr.getMonth() + 1] +
		' ' +
		dateStr.getFullYear()
	)
}

export function isJson(str) {
	try {
		JSON.parse(str)
	} catch (e) {
		return false
	}
	return true
}

export function parseJSON(input, defaultOutput = {}) {
	try {
		return JSON.parse(input)
	} catch (e) {
		return defaultOutput
	}
}
