import React from 'react';
import { withRouter } from 'react-router-dom';
import { Modal } from 'antd';
import { Table, Button, InputSearchQuery, SelectSearchQuery } from 'components';
import { MENU } from 'config/menu';
import ExpelModal from '../detail/expel-modal';

import SwitchUserModal from './switchUserModal';
import usePositionListPage from './state';
import CreatePositionForm from './create';
import { columns } from './columns';

function PositionListPage() {
  const {
    positions,
    totalPositions,
    roles,
    isLoading,
    showCreatePositionModal,
    setShowCreatePositionModal,
    submitCreatePosition,
    viewDetailAction,
    onClickSwitchIcon,
    switchUserModal,
    setSwitchUserModal,
    onClickExpelIcon,
    showExpelModal,
    setShowExpelModal,
    fetchData,
  } = usePositionListPage();

  return (
    <div>
      <div className="flex align-center justify-start mb-10 mt-4">
        <InputSearchQuery className="md:w-328px md:mr-5 md:mb-0 mb-5" />
        <SelectSearchQuery
          className="md:w-328px w-full md:mr-5 md:mb-0 mb-2"
          options={[{ key: '', label: 'All roles' }].concat((roles || []).map((r) => ({ key: r.name, label: r.name })))}
          fieldName="role"
          allText="All Status"
        />
        <Button
          className="md:w-328px w-full md:mr-0 md:mb-0 md:ml-auto"
          style={{ width: 180 }}
          type="primary"
          onClick={() => setShowCreatePositionModal(true)}
          icon="plus-square"
        >
          <span className="ml-2">Create New Position</span>
        </Button>
      </div>
      <Table
        loading={isLoading}
        columns={columns(viewDetailAction, onClickSwitchIcon, onClickExpelIcon)}
        dataSource={positions || []}
        count={totalPositions || 0}
        urlPush={`${MENU.POSITION_MANAGEMENT}/position`}
      />
      {showCreatePositionModal && (
        <Modal
          title="Create New Position"
          visible={showCreatePositionModal}
          onCancel={() => setShowCreatePositionModal(false)}
          footer={null}
        >
          <CreatePositionForm roles={roles} onSubmit={submitCreatePosition} setVisible={setShowCreatePositionModal} isLoading={isLoading} />
        </Modal>
      )}

      <ExpelModal
        position={showExpelModal.position}
        visible={showExpelModal.visible}
        close={() => setShowExpelModal({ visible: false, position: null })}
        reloadParent={fetchData}
      />

      <SwitchUserModal
        visible={switchUserModal.visible}
        selectedPosition={switchUserModal.position}
        close={() => setSwitchUserModal({ visible: false, position: null })}
        onOk={fetchData}
      />
    </div>
  );
}

export default withRouter(PositionListPage);
