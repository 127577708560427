import { useState, useEffect } from 'react';
import { notification } from 'antd';
import { getCookies } from 'utils/cookies';
import { usersApi } from 'api/users';
import { areasApi } from 'api/areas';
import { getQueryString } from 'utils/query';
import { CONFIG_COOKIES } from 'config/cookies';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import './list.scss';

const PAGE_LIMIT = 10;

function usePositionManagementAreaList({ location }) {
  const [positionAreas, setPositionAreas] = useState([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const sendError = useRollbarNotifier();

  const getData = async () => {
    setIsLoading(true);

    try {
      const query = {
        ...getQueryString(location.search),
      };

      setCurrentPage(query.page);
      setSearch(query.search);

      const result = await areasApi.listAreaPresets({
        per_page: PAGE_LIMIT,
        search: query.search,
        page: query.page,
        merchant_id: getCookies(CONFIG_COOKIES.MERCHANT_ID),
      });

      setPositionAreas(result.data.data);
      setTotalItems(result.data.metadata.total_count);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      sendError(`Error when fetching data in usePositionManagementAreaList hooks`, error);
    }
  };

  useEffect(() => {
    getData();
  }, [location.search]);

  const submitCreatePositionArea = async (formData) => {
    const payload = {
      ...formData,
      merchant_id: getCookies(CONFIG_COOKIES.MERCHANT_ID),
      territories: formData.search_area_territories.map((loc) => loc.value),
    };

    setIsLoading(true);

    try {
      await usersApi.createAreaPreset(payload);
      setIsLoading(false);
      setShowCreateModal(false);
      getData();

      notification.success({
        message: 'Success Create Position Area',
        description: `Success create position area ${formData.name}`,
      });
    } catch (error) {
      setIsLoading(false);
      sendError(`Error when create new position in usePositionManagementAreaList hooks`, error);
    }
  };

  return {
    positionAreas,
    search,
    currentPage,
    totalItems,
    isLoading,
    showCreateModal,
    setSearch,
    setShowCreateModal,
    submitCreatePositionArea,
  };
}

export default usePositionManagementAreaList;
