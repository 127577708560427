import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Table } from 'components'
import moment from 'moment'
import qs from 'qs'
import { capitalize } from '../../../../utils/formatter/string'
import HistoryCard from '../../../../components/Molecules/HistoryCard/history-card'
import { getQueryStringForPagination } from '../../../../utils/query'
import { MENU } from '../../../../config/menu'
import { positionsApi } from '../../../../api/positions'

const PAGE_SIZE = 10

const columns = () => [
	{
		title: 'Timestamp',
		dataIndex: 'updated_date_utc',
		key: 'updated_date_utc',
		width: 200,
		render: (text) => moment(text).format('DD MMMM YYYY (HH:mm)'),
	},
	{
		title: 'Action',
		dataIndex: 'action',
		key: 'action',
		// width: '60%',
		render: (text) => capitalize(text) || '-',
	},
	{
		title: 'Description',
		dataIndex: 'reason',
		key: 'description',
		// width: '60%',
		render: (text) => capitalize(text) || '-',
	},
	{
		title: 'Updated by',
		dataIndex: 'updated_by',
		key: 'updated_by',
		render: (text) => capitalize(text) || '-',
	},
]

const PositionEventsCard = ({ positionId, location, history }) => {
	const [loading, setLoading] = useState(false)
	const [positionEvents, setPositionEvents] = useState([])
	const [totalData, setTotalData] = useState(0)

	const query = getQueryStringForPagination(location.search)
	const { historyPage } = query
	const onChangePage = (pageNum) => {
		const pageQuery = { ...query, historyPage: pageNum }
		if (pageNum === 1) delete pageQuery.historyPage
		history.push(
			`${MENU.POSITION_MANAGEMENT}/position/${positionId}?${qs.stringify(
				pageQuery,
			)}`,
		)
	}

	useEffect(() => {
		const fetchEvents = async () => {
			setLoading(true)
			try {
				const res = await positionsApi.listPositionEvents(positionId, {
					page: historyPage,
					per_page: PAGE_SIZE,
				})

				setPositionEvents(res.data.data)
				setTotalData(res.data.metadata.total_count)
				setLoading(false)
			} catch (error) {
				setLoading(false)
			}
		}

		fetchEvents()
	}, [positionId, historyPage])

	return (
		<HistoryCard>
			<Table
				loading={loading}
				dataSource={positionEvents}
				columns={columns()}
				rowKey="updated_date_utc"
				withoutPagination
				pagination={{
					current: +historyPage || 1,
					total: totalData,
					pageSize: PAGE_SIZE,
					onChange: onChangePage,
				}}
			/>
		</HistoryCard>
	)
}

export default withRouter(PositionEventsCard)
