import {METHODS, API_BASE_URL, apiCall} from './config'

export const MerchantApi = {
    get: (data = {}) => {
        const modifiedData = {...data, page: data.page || 1}
        return apiCall(METHODS.GET, API_BASE_URL, 'v1/merchants', modifiedData)
    },
    getAll: () => apiCall(METHODS.GET, API_BASE_URL, `v1/merchants`),
    getApiList: (data) =>
        apiCall(METHODS.GET, API_BASE_URL, `v1/merchants/${data.merchant_id}`),
    getHistory: (data) =>
        apiCall(
            METHODS.GET,
            API_BASE_URL,
            `v1/merchants/${data.merchant_id}/history`,
            data,
        ),
    create: (data) => apiCall(METHODS.POST, API_BASE_URL, `v1/merchants`, data),
    update: (data) =>
        apiCall(
            METHODS.POST,
            API_BASE_URL,
            `v1/merchants/${data.merchant_id}`,
            data,
        ),
    getDetail: (data) =>
        apiCall(METHODS.GET, API_BASE_URL, `v1/merchants/${data.merchant_id}`),
    delete: (data) =>
        apiCall(
            METHODS.POST,
            API_BASE_URL,
            `v1/merchants/${data.merchant_id}/delete`,
            data,
        ),
    deactivate: (data) =>
        apiCall(
            METHODS.POST,
            API_BASE_URL,
            `v1/merchants/${data.merchant_id}/deactive`,
            data,
        ),
    activate: (data) =>
        apiCall(
            METHODS.POST,
            API_BASE_URL,
            `v1/merchants/${data.merchant_id}/active`,
            data,
        ),

    getOwnerListByMerchant: (data) => {
        // return new Promise((resolve, reject) => {
        // 	resolve({
        // 		data: {
        // 			data: [
        // 				{
        // 					id: '973b4b2c-9ee2-4e42-9dc7-925eb668fa96',
        // 					name: 'New Employee',
        // 					code: 'QU56ANCU',
        // 					email: 'new@employee.com',
        // 					phone: '081266219932',
        // 					address: 'test',
        // 					location: 'test',
        // 					location_code: 'test',
        // 					nik: '3173082610971002',
        // 					birth_date: '2001-01-01T00:00:00Z',
        // 					image_url: 'url',
        // 					badges: [],
        // 					is_kyc: true,
        // 					is_otp: true,
        // 					status: 'active',
        // 					last_active_date: null,
        // 					created_by: 'adrian',
        // 					created_date_utc: '2022-06-27T15:10:07.333892Z',
        // 					updated_date_utc: '2022-06-27T15:10:07.333892Z',
        // 				},
        // 				{
        // 					id: '24445306-c8c3-4c15-8fdb-5bdc667aa005',
        // 					name: 'Siapa lagi lah',
        // 					code: 'SUVDQ9QM',
        // 					email: 'jono2@example.com',
        // 					phone: '082314561133',
        // 					address: '',
        // 					location: '',
        // 					location_code: '',
        // 					nik: '',
        // 					birth_date: '0001-01-01T00:00:00Z',
        // 					image_url: '',
        // 					badges: [],
        // 					is_kyc: false,
        // 					is_otp: false,
        // 					status: '',
        // 					last_active_date: null,
        // 					created_date_utc: '2022-06-28T01:03:37.468855Z',
        // 					updated_date_utc: '2022-07-05T07:58:03.372828Z',
        // 				},
        // 			],
        // 		},
        // 	})
        // })
        return apiCall(
            METHODS.GET,
            API_BASE_URL,
            `v1/merchants/${data.id}/users`,
            data,
        )
    },

    changeOwner: (data, merchantId) => {
        return apiCall(
            METHODS.POST,
            API_BASE_URL,
            `v1/merchants/${merchantId}/changeowner`,
            data,
        )
    },
}
