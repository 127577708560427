import { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import { queryStringToObject } from 'utils/query';

const DATE_FORMAT = 'YYYY-MM-DD';
const DEFAULT_QUERIES = {
  granularity: 'daily',
  start_filter: moment().startOf('month').format(DATE_FORMAT),
  end_filter: moment().endOf('month').format(DATE_FORMAT),
};

/**
 *
 * @param { callApi } callApi is the api function that return chart data
 * @returns data, loading state, and query
 */
function useCharts({ callApi }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const sendError = useRollbarNotifier();

  const query = useMemo(() => ({ ...DEFAULT_QUERIES, ...queryStringToObject(history.location.search) }), [history.location.search]);
  const startDate = moment(query.start_filter, DATE_FORMAT);
  const endDate = moment(query.end_filter, DATE_FORMAT);

  const checkRequiredQuery = (payload) => {
    const hasRequiredPayloadFields = payload.granularity && payload.start_date && payload.end_date;
    const hasPositionOrSalesId = payload.position_id || payload.sales_id;

    if (hasRequiredPayloadFields && hasPositionOrSalesId) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    const payload = {
      granularity: query?.granularity,
      start_date: query?.start_filter,
      end_date: query?.end_filter,
      position_id: query?.k_position_id,
      sales_id: query?.k_sales_id,
    };

    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await callApi(payload, { signal: controller.signal });
        setData(res.data.data || res.data);
        setLoading(false);
      } catch (error) {
        setData(null);

        if (error.code === 'ERR_CANCELED') {
          setLoading(true);
        } else {
          setLoading(false);
          sendError(`Error when fetching data in useChart hooks`, error);
        }
      }
    };

    const isRequiredQueriesFilled = checkRequiredQuery(payload);
    if (isRequiredQueriesFilled && callApi) {
      fetchData();
    } else {
      setData(null);
    }

    return () => controller.abort();
  }, [history.location.search]);

  return { data, loading, startDate, endDate, query };
}

export default useCharts;
