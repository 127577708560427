import React from 'react';
// import { Button } from 'antd'
import { Button } from 'components';
import { TableTitle } from 'components/table';

// const { TableTitle } = Table

export const columns = (onClickDetail) => [
  {
    title: <TableTitle className="px-4">Role</TableTitle>,
    dataIndex: 'name',
    key: 'name',
    render: (text) => <div className="ml-4">{text}</div>,
  },
  {
    title: <TableTitle>Description</TableTitle>,
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: <TableTitle>Action</TableTitle>,
    dataIndex: 'id',
    key: 'action',
    width: 200,
    align: 'center',
    render: (id) => (
      <Button size="small" className="m-auto" onClick={() => onClickDetail(id)}>
        DETAIL
      </Button>
    ),
  },
];

export default columns;
