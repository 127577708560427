import React from 'react';
import TabsPage from '../../layout/tabs-page';
import { MENU } from '../../config/menu';
import VisitAssignmentAssignee from './assignee/list';
import VisitAssignmentLeads from './leads/list';

function PositionManagementTabs() {
  const tabs = [
    {
      key: 'leads',
      title: 'Leads',
      content: <VisitAssignmentLeads />,
    },
    {
      key: 'assignee',
      title: 'Assignee',
      content: <VisitAssignmentAssignee />,
    },
  ];
  return (
    <div>
      <TabsPage pageTitle="Visit Assignment" urlPush={MENU.VISIT_ASSIGNMENT} tabs={tabs} />
    </div>
  );
}

export default PositionManagementTabs;
