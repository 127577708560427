import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, Menu, Tag } from 'antd';
import { NavLink } from 'react-router-dom';
import { PERMISSION_MENU as M, PERMISSION_TYPES as T } from 'config/permissions';
import { hasRight } from 'utils/access-right';
import ProfileAvatar from 'components/ProfileAvatar';
import { POSITION_MANAGEMENT, REPORTING_TOOLS, USER_MANAGEMENT, VISIT_ASSIGNMENT } from '../../config/feature';
import { getCookies, logout } from '../../utils/cookies';
import { CONFIG_COOKIES } from '../../config/cookies';

import { MENU } from '../../config/menu';

function Sidebar({ visible, close, getActiveNavLink }) {
  const fullName = getCookies(CONFIG_COOKIES.FULLNAME);
  const userCode = getCookies(CONFIG_COOKIES.CODE);
  const roleTitle = getCookies(CONFIG_COOKIES.ROLE_TITLE);

  return (
    <Drawer
      closable
      title={
        <div className="main__drawer-avatar">
          <div className="flex">
            <ProfileAvatar size={52} />
            <div className="ml-3">
              <p className="main__drawer-avatar-acc">{fullName}</p>
              <p className="small-text text-gray-400">{userCode}</p>
              <Tag className="text-sm">{roleTitle}</Tag>
            </div>
          </div>
        </div>
      }
      placement="left"
      onClose={close}
      visible={visible}
    >
      <Menu mode="inline" defaultSelectedKeys={['/']} selectedKeys={[getActiveNavLink()]}>
        {hasRight({ menu: M.ACQUISITION_REVIEW, type: T.READ }) && (
          <Menu.Item key={MENU.ACQUISITION_REVIEW_KEY}>
            <NavLink to={MENU.ACQUISITION_REVIEW}>Acquisition Review</NavLink>
          </Menu.Item>
        )}
        {hasRight({ menu: M.MERCHANT_MANAGEMENT, type: T.READ }) && (
          <Menu.Item key={MENU.MERCHANT_MANAGEMENT_LIST_KEY}>
            <NavLink to={MENU.MERCHANT_MANAGEMENT_LIST}>Merchant Management</NavLink>
          </Menu.Item>
        )}
        {hasRight({ menu: M.ACCOUNT_MANAGEMENT, type: T.READ }) && (
          <Menu.Item key={MENU.ACCOUNT_MANAGEMENT_KEY}>
            <NavLink to={MENU.ACCOUNT_MANAGEMENT}>Role Management</NavLink>
          </Menu.Item>
        )}
        {USER_MANAGEMENT && hasRight({ menu: M.USER_MANAGEMENT, type: T.READ }) && (
          <Menu.Item key={MENU.USER_MANAGEMENT_KEY}>
            <NavLink to={MENU.USER_MANAGEMENT}>User Management</NavLink>
          </Menu.Item>
        )}
        {POSITION_MANAGEMENT && hasRight({ menu: M.POSITION_MANAGEMENT, type: T.READ }) && (
          <Menu.Item key={MENU.POSITION_MANAGEMENT_KEY}>
            <NavLink to={MENU.POSITION_MANAGEMENT}>Position Management</NavLink>
          </Menu.Item>
        )}
        {VISIT_ASSIGNMENT && hasRight({ menu: M.VISIT_ASSIGNMENT, type: T.READ }) && (
          <Menu.Item key={MENU.VISIT_ASSIGNMENT_KEY}>
            <NavLink to={MENU.VISIT_ASSIGNMENT}>Visit Assignment</NavLink>
          </Menu.Item>
        )}
        {REPORTING_TOOLS && hasRight({ menu: M.REPORTING_TOOLS, type: T.READ }) && (
          <Menu.SubMenu key="report" title={<span>Reporting Tools</span>}>
            <Menu.Item key={MENU.SALES_FUNNEL_KEY}>
              <NavLink to={MENU.SALES_FUNNEL}>Sales Funnel</NavLink>
            </Menu.Item>
            <Menu.Item key={MENU.VISITATION_REPORTS_KEY}>
              <NavLink to={MENU.VISITATION_REPORTS}>Visitation Report Summary</NavLink>
            </Menu.Item>
            <Menu.Item key={MENU.KPI_VISUALIZATION_KEY}>
              <NavLink to={MENU.KPI_VISUALIZATION}>KPI Visualization</NavLink>
            </Menu.Item>
            <Menu.Item key={MENU.OTHER_REPORTS_KEY}>
              <NavLink to={MENU.OTHER_REPORTS}>Other Report</NavLink>
            </Menu.Item>
          </Menu.SubMenu>
        )}
        <Menu.Item key="4" onClick={logout}>
          Logout
        </Menu.Item>
      </Menu>
    </Drawer>
  );
}

Sidebar.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  getActiveNavLink: PropTypes.func.isRequired,
};

Sidebar.defaultProps = {};

export default Sidebar;
