import React, { useState, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import { Card } from 'antd';
import qs from 'qs';
import { Table } from 'components';

import { getQueryStringForPagination } from 'utils/query';
import { MENU } from 'config/menu';
import { visitAssignmentApi } from 'api/visit-assignment';

import { columns } from './columns';
import AddAssignmentButton from './AddNewAssignmentButton';

const PAGE_SIZE = 10;

function HistoryLogs({ location, history, match }) {
  const [loading, setLoading] = useState(false);
  const [historyLogs, setHistoryLogs] = useState([]);
  const [lastUpdate, setLastUpdate] = useState('');

  const query = getQueryStringForPagination(location.search);
  const { historyPage } = query;
  const [totalData, setTotalData] = useState(null);
  const acquisitionId = useMemo(() => match.params.id, [match.params]);
  const sendError = useRollbarNotifier();

  const reload = () => setLastUpdate(new Date());

  const onChangePage = (pageNum) => {
    const pageQuery = { ...query, historyPage: pageNum };
    if (pageNum === 1) delete pageQuery.historyPage;
    history.push(`${MENU.VISIT_ASSIGNMENT}/${acquisitionId}?${qs.stringify(pageQuery)}`);
  };

  useEffect(() => {
    const fetchHistory = async () => {
      setLoading(true);
      try {
        const res = await visitAssignmentApi.historyListByLeads(acquisitionId, {
          page: historyPage,
          per_page: PAGE_SIZE,
        });
        setHistoryLogs(res.data.data);
        setTotalData(res.data.count);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        sendError(`Error when fetch acquisition detail history list options`, error);
      }
    };
    fetchHistory();
  }, [acquisitionId, historyPage, lastUpdate]);

  return (
    <Card bordered={false} className="card lastEvent" bodyStyle={{ padding: '24px' }} style={{ marginBottom: 0 }}>
      <div className="mb-10">
        <AddAssignmentButton onSuccess={reload} />
      </div>
      <Table
        loading={loading}
        dataSource={historyLogs}
        columns={columns(reload)}
        rowKey="assignment_id"
        withoutPagination
        pagination={{
          current: +historyPage || 1,
          total: totalData,
          pageSize: PAGE_SIZE,
          onChange: onChangePage,
        }}
      />
    </Card>
  );
}

HistoryLogs.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(HistoryLogs);
