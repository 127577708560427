import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';

import { ArrowLeft, ArrowRight } from 'assets';
import useMediaQuery from 'customHooks/useMediaQuery';
import Text from './text';

import { formatPercent } from '../helper';

function SummarySection({ data, loading }) {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Card className="flex justify-center items-center" style={{ height: isMobile ? 'unset' : '426px' }}>
      <p className="font-bold text-cf-20 text-cf-gray text-center">Overall Conversion Rate</p>
      <div className="my-10 flex-nowrap justify-center items-center" style={{ display: 'flex', zoom: isMobile ? 0.6 : 1 }}>
        <div className="w-32 rounded-lg border text-center p-2">
          <p className="text-cf-12 w-24">New Leads</p>
          <Text className="font-bold" loading={loading}>
            {data.lead_count || 0}
          </Text>
        </div>
        <ArrowLeft className="m-5" />
        <div className="w-1/2 -mx-4">
          <Text className="text-9xl text-cf-gray font-bold" loading={loading}>
            {formatPercent(data.conversion_rate_percentage)}
          </Text>
        </div>
        <ArrowRight className="m-5" />
        <div className="w-32 rounded-lg border text-center p-2">
          <p className="text-cf-12 w-24">MAU200</p>
          <Text className="font-bold" loading={loading}>
            {data.mau_blended_count || 0}
          </Text>
        </div>
      </div>
    </Card>
  );
}

SummarySection.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SummarySection;
