import React from 'react'

function IconArrowUp() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24">
			<path
				fill="#009696"
				fillRule="evenodd"
				d="M12 7l8.736 8.505a.859.859 0 010 1.239.917.917 0 01-1.272 0L12 9.479l-7.464 7.265a.917.917 0 01-1.272 0 .859.859 0 010-1.24L12 7z"
				clipRule="evenodd"
			/>
		</svg>
	)
}

export default IconArrowUp
