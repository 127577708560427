import React, { useState } from 'react';
import { Modal, Form, Input } from 'antd';
import PropTypes from 'prop-types';
import { Button, ListTableView } from 'components';
import SearchSelect from 'components/search-select';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import { positionsApi } from 'api/positions';
import { capitalize } from 'utils/formatter/string';

function SwitchUserModal({ visible, close, onOk, selectedPosition, form }) {
  const [loading, setLoading] = useState(false);
  const [newPosition, setNewPosition] = useState(null);
  const { getFieldDecorator } = form;
  const sendError = useRollbarNotifier();

  const searchPositionApi = async (search) => {
    const res = await positionsApi.listPositions({ search, role: '', page: 1, per_page: 10 });
    return res.data.data.map((dt) => ({
      text: dt.position_label,
      value: JSON.stringify({ id: dt.position_id, email: dt.user_email, name: dt.user_name }),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await form.validateFields();

    try {
      setLoading(true);
      await positionsApi.switchPosition(selectedPosition.position_id, newPosition.id);
      setLoading(false);
      setNewPosition(null);
      close();
      Modal.success({
        title: 'Success switch position',
        content: `Success`,
        onOk,
      });
    } catch (error) {
      setLoading(false);
      sendError('Error when switch position', error);
    }
    setLoading(false);
  };

  const onCancel = () => {
    setNewPosition(null);
    close();
  };

  return (
    <Modal
      title={<span style={{ fontWeight: 700 }}>Switch Position</span>}
      destroyOnClose
      maskClosable={false}
      onOk={handleSubmit}
      onCancel={onCancel}
      visible={visible}
      footer={null}
      centered
    >
      <Form onSubmit={handleSubmit}>
        <Form.Item label="Position Label">
          {getFieldDecorator('currentPositionLabel', {
            initialValue: selectedPosition?.position_label,
          })(<Input placeholder="Position Label" disabled />)}
        </Form.Item>
        <Form.Item label="Full Name">
          {getFieldDecorator('name', {
            initialValue: selectedPosition?.user_name,
          })(<Input placeholder="Name" disabled />)}
        </Form.Item>
        <Form.Item label="Email">
          {getFieldDecorator('email', {
            initialValue: selectedPosition?.user_name,
          })(<Input placeholder="Email Address" disabled />)}
        </Form.Item>

        <Form.Item label="New Position">
          {getFieldDecorator('position_id_2', {
            rules: [{ required: true, message: 'Please select new position' }],
          })(
            <SearchSelect
              loading={loading}
              wordLimit={3}
              placeholder="Search"
              isEffect
              onChange={(position) => setNewPosition(JSON.parse(position.value))}
              api={searchPositionApi}
            />,
          )}
        </Form.Item>
        {newPosition && (
          <div className="bg-yellow-100 pt-2 pb-4 px-4 rounded-lg -mt-5">
            <ListTableView
              className="w-1/2"
              loading={loading}
              data={{
                'Name ': capitalize(newPosition?.name || '-'),
                'Email ': capitalize(newPosition?.email || '-'),
              }}
            />
          </div>
        )}

        <div className="flex mt-5">
          <Button className="mr-3 ml-auto" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Switch Position
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

SwitchUserModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  selectedPosition: PropTypes.object,
  form: PropTypes.object.isRequired,
};

SwitchUserModal.defaultProps = {
  selectedPosition: {},
};

export default Form.create()(SwitchUserModal);
