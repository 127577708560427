import { useEffect, useState } from 'react';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import { GeoApiV2 } from 'api/geo-location-v2';
import { SEARCH_AREA_TERRITORIES, SEARCH_AREA_TYPES, rawResponseToTreeObject } from './helper';

function useGetLocationLabels({ form, initialTerritories }) {
  const [loading, setLoading] = useState(false);
  const [locWithLabels, setLocWithLabels] = useState([]);
  const sendError = useRollbarNotifier();

  useEffect(() => {
    if (!form.getFieldValue(SEARCH_AREA_TYPES)) {
      return;
    }

    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await GeoApiV2.getLocationByCodes({ codes: initialTerritories });
        const initTerrWithLabel = res.data.data.map(rawResponseToTreeObject);

        setLocWithLabels(initTerrWithLabel);

        form.setFieldsValue({
          [SEARCH_AREA_TERRITORIES]: initTerrWithLabel,
        });

        setLoading(false);
      } catch (error) {
        sendError(`Error when fetching getLocationByCodes to append territories label`, error);
      }
    };

    fetchData();
  }, []);

  return {
    labelLoading: loading,
    initialTerrWithLabel: locWithLabels,
  };
}

export default useGetLocationLabels;
