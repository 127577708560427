import React from 'react';
import { useHistory } from 'react-router-dom';

import { Col, Row } from 'antd';
import { usersApi } from 'api/users';
import { SearchQueryByApi, SelectRangePickerQuery, SelectSearchQuery } from 'components';
import Layout from 'layout/main';
import moment from 'moment';

import { getQuarterRanges } from 'utils/date';
import { queryStringToObject } from 'utils/query';
import { AGENT_TYPE } from 'config/report';
import ListSectionItem from './ListSection';
import SummarySection from './SummarySection';

const CURRENT_YEAR = moment().format('YYYY');

function VisitationReport() {
  const history = useHistory();

  const query = {
    start_filter: moment().startOf('month').format('YYYY-MM-DD'),
    end_filter: moment().endOf('month').format('YYYY-MM-DD'),
    ...queryStringToObject(history.location.search),
  };

  return (
    <Layout title="Visitation Report Summary">
      <div className="app-content">
        <div className="mb-5">
          <Row gutter={[16, 16]} className="max-w-7xl">
            <Col span={24} md={8}>
              <SelectRangePickerQuery
                disabledDate={(curr) => curr && curr > moment().endOf('day')}
                ranges={{
                  Today: [moment(), moment()],
                  'This Week': [moment().startOf('week'), moment().endOf('week')],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                  'This Year': [moment().startOf('year'), moment().endOf('year')],
                  Q1: getQuarterRanges({ q: 1, year: CURRENT_YEAR }),
                  Q2: getQuarterRanges({ q: 2, year: CURRENT_YEAR }),
                  Q3: getQuarterRanges({ q: 3, year: CURRENT_YEAR }),
                  Q4: getQuarterRanges({ q: 4, year: CURRENT_YEAR }),
                }}
                initialValue={[moment().startOf('month'), moment().endOf('month')]}
              />
            </Col>
            <Col span={24} md={8}>
              <SelectSearchQuery className="w-full" options={AGENT_TYPE} fieldName="type" />
            </Col>
            <Col span={24} md={8}>
              <SearchQueryByApi
                placeholder="Search Sales Name"
                getOptions={async (search) => {
                  const res = await usersApi.searchSales(search);
                  return res?.data?.data?.map((dt) => ({ key: dt.id, label: dt.name })) || [];
                }}
                fieldName="sales_id"
              />
            </Col>
          </Row>
        </div>
        <SummarySection query={query} />
        <ListSectionItem query={query} />
      </div>
    </Layout>
  );
}

export default VisitationReport;
