import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Tooltip, Icon, Row, Col } from 'antd';
import Layout from 'layout/main';
import ListTableView from 'components/Atoms/ListTableView';
import { Breadcrumb, Button, Card, Tag } from 'components';
import useMediaQuery from 'customHooks/useMediaQuery';

import IconChecklist from 'assets/ic-checklist';
import { MENU } from 'config/menu';
import { AccountApi } from 'api/account';
import { capitalize, toTitleCase } from 'utils/formatter/string';

import DeleteAccountConfirmation from '../modals/delete-account-confirmation';
import HistoryLogs from './history-logs';
import './detail.scss';

function DetailAccountManagement({ match, history }) {
  const [loading, setLoading] = useState(false);
  const [roleDetail, setRoleDetail] = useState({ name: '', description: '' });
  const [dashboardPermissions, setDashboardPermissions] = useState([]);
  const [mobilePermissions, setMobilePermissions] = useState([]);
  const accountId = match.params.id;
  const [hasTeamMember, setHasTeamMember] = useState();

  const [deleteConfirmationModal, setDeleteConfirmation] = useState(false);

  const isMobile = useMediaQuery('(max-width: 768px)');

  const onClickEdit = () => {
    history.push(`${MENU.ACCOUNT_MANAGEMENT}/role/edit/${roleDetail.id}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { data } = await AccountApi.getDetail({ role_id: accountId });
      setHasTeamMember(data.team_member > 0);
      setRoleDetail(data.role);
      setDashboardPermissions(data?.role_permissions?.dashboard || []);
      setMobilePermissions(data?.role_permissions?.mobile || []);
      setLoading(false);
    };
    fetchData();
  }, [accountId]);

  return (
    <Layout title="Role Management">
      <Breadcrumb
        data={[
          {
            link: MENU.ACCOUNT_MANAGEMENT,
            title: 'Role Management',
          },
          {
            link: null,
            title: 'Detail Information',
          },
        ]}
      />
      <div className="app-content__details">
        <Card className="card pb-5" loading={loading} bordered={false}>
          <div className="font-bold text-2xl text-cf-gray mb-3">Role Information</div>
          <Row gutter={32}>
            <Col md={8} sm={24} className={isMobile ? '' : 'border-r'}>
              <ListTableView
                loading={loading}
                data={{
                  'Role Name': (
                    <p>
                      {toTitleCase(roleDetail.name) || '-'}{' '}
                      {roleDetail.is_admin && (
                        <Tag color="green" className="ml-4" style={{ width: 60, height: 24 }}>
                          Admin
                        </Tag>
                      )}
                    </p>
                  ),
                  'Description ': capitalize(roleDetail.description) || '-',
                }}
              />
            </Col>
            <Col md={8} sm={24}>
              <ListTableView
                loading={loading}
                data={{
                  'Mobile Access Permission': mobilePermissions.length > 0 ? 'YES' : 'NO',
                  'Area Restricted': roleDetail.area_restricted ? 'YES' : 'NO',
                }}
              />
              {mobilePermissions.map(({ menu }) => (
                <Tag key={menu} color="#009696" className="mt-2" style={{ width: 60, height: 24 }}>
                  {menu}
                </Tag>
              ))}
            </Col>
          </Row>
        </Card>
        <div className="gridTemplate">
          <Card className="card" bordered={false}>
            <div className="font-bold text-2xl text-cf-gray mb-1">Permission</div>
            <div
              className="p-5 pt-1 mt-8"
              style={{
                maxheight: 622,
                columnCount: isMobile ? 1 : 3,
                maxWidth: 700,
              }}
            >
              {dashboardPermissions.length > 0 &&
                [...dashboardPermissions].map((p) => (
                  <div key={p.menu} style={{ breakInside: 'avoid' }}>
                    <p className="font-bold text-cf-gray p-2 text-cf-14">{p.menu}</p>
                    <div className="text-cf-gray p-2">
                      {p.permission_group.map((c) => (
                        <div key={c.id} className="flex p-2">
                          <IconChecklist />
                          <span className="ml-2 text-cf-14">{c.action}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </Card>
        </div>
        <Card className="card" bordered={false}>
          <div className="flex justify-between">
            <Button loading={loading} onClick={onClickEdit} className="h-16">
              <span className="text-cf-14">Edit</span>
            </Button>
            <div className="flex justify-center items-center">
              <Tooltip title="You can only delete a role if no user are assigned to it">
                <Icon className="text-3xl" type="info-circle" style={{ marginRight: 8 }} />
              </Tooltip>
              <Button loading={loading} type="danger" className="h-16" onClick={() => setDeleteConfirmation(true)} disabled={hasTeamMember}>
                <span>Delete</span>
              </Button>
            </div>
          </div>
        </Card>
        <HistoryLogs accountId={accountId} loading={loading} />
      </div>
      <DeleteAccountConfirmation role={roleDetail} visible={deleteConfirmationModal} close={() => setDeleteConfirmation(false)} />
    </Layout>
  );
}

DetailAccountManagement.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(DetailAccountManagement);
