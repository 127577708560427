import { useState, useEffect } from 'react';
import { usersApi } from 'api/users';

const GREAT_NUMBER = 999;
function useGetRoles() {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      //   if (!merchantId) return;
      setLoading(true);
      try {
        const rolesRes = await usersApi.getRoles({
          per_page: GREAT_NUMBER,
        });
        setRoles(rolesRes.data.data);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return { roles, loading };
}

export default useGetRoles;
