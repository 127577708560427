import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'antd';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { generateUrlQueryParams, parseUrlQueryParams } from 'utils/query';

function NextPrevPagination({ loading, limit, currentDataLength, history, location }) {
  const query = parseUrlQueryParams(location.search);
  const currentPage = query.page;
  const valPage = query?.page || 1;

  const updatePageQuery = (value) => {
    history.push({
      search: generateUrlQueryParams({
        ...parseUrlQueryParams(location.search),
        page: value,
      }),
    });
  };

  const handlePrevPage = () => updatePageQuery(currentPage - 1);
  const handleNextPage = () => updatePageQuery(currentPage + 1);

  return (
    <Button.Group>
      <Button disabled={valPage === 1 || !valPage || loading} type="primary" onClick={handlePrevPage}>
        <div className="pb-2">
          <Icon type="left" />
        </div>
      </Button>
      <Button disabled={currentDataLength < limit || loading} type="primary" onClick={handleNextPage}>
        <div className="pb-2">
          <Icon type="right" />
        </div>
      </Button>
    </Button.Group>
  );
}

NextPrevPagination.propTypes = {
  loading: PropTypes.bool.isRequired,
  limit: PropTypes.number.isRequired,
  currentDataLength: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(NextPrevPagination);
