import React from 'react'
import { toTitleCase } from 'utils/formatter/string'
import TitleTable from './TitleTable'

const Label = ({ containerClassName = 'mt-7', ...props }) => (
	<p className={containerClassName}>
		<label {...props} />
	</p>
)

const LabelWithBackground = ({
	containerClassName = 'mt-7',
	bgColor = '#dbe0e4',
	...props
}) => (
	<p
		className={`p-2 text-cf-12 font-semibold text-gray-700 ${containerClassName}`}
		style={{ backgroundColor: bgColor }}>
		<span className="" {...props} />
	</p>
)

const Text = (props) => (
	<p className="text-cf-12 text-cf-lightGray" {...props} />
)

const TitleText = (props) => (
	<p className="text-cf-12 text-cf-lightGray" {...props}>
		{toTitleCase(props?.children || '-')}
	</p>
)

export default { TitleTable, Label, Text, TitleText, LabelWithBackground }
