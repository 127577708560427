import React, { useState } from 'react';
import { Form, Input, Select, Radio, message } from 'antd';
import { Button, SearchVillage } from 'components';
import { getCookies } from 'utils/cookies';
import { CONFIG_COOKIES } from 'config/cookies';
import { EMAIL_PATTERN, NAME_PATTERN, PHONE_PATTERN } from 'utils/string-pattern';
import PositionFormItem from '../position-form-item';

const CreateUserForm = Form.create()(({ form, roles = [], onSubmit, setVisible, isLoading }) => {
  const [assignRoleBy, setAssignRoleBy] = useState('position');
  const [selectedPosition, setSelectedPosition] = useState(null);

  const { getFieldDecorator, setFields, getFieldsValue } = form;

  const onPositionChange = (dt) => {
    setSelectedPosition(dt);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      const payload = {
        merchant_id: getCookies(CONFIG_COOKIES.MERCHANT_ID),
        email: values.email.toLowerCase(),
        name: values.name,
        phone: values.phone,
        position_id: assignRoleBy === 'position' ? values?.position?.key : null,
        role_id: assignRoleBy === 'position' ? selectedPosition?.role_id : values.role_id,
        location: values.location.label,
        location_code: values.location.key,
      };

      if (assignRoleBy === 'position' && !payload.position_id) {
        message.error('Please select position');
        return;
      }

      if (!err) {
        onSubmit(payload);
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label="Email">
        {getFieldDecorator('email', {
          rules: [
            { required: true, message: 'Please input email' },
            {
              pattern: EMAIL_PATTERN,
              message: 'Please input a valid Google email',
            },
          ],
        })(<Input placeholder="Email Address" />)}
      </Form.Item>
      <Form.Item label="Full Name">
        {getFieldDecorator('name', {
          rules: [
            { required: true, message: 'Please input name' },
            {
              pattern: NAME_PATTERN,
              message: 'Please input valid name',
            },
          ],
        })(<Input placeholder="Full Name" />)}
      </Form.Item>
      <Form.Item label="Phone Number">
        {getFieldDecorator('phone', {
          rules: [
            {
              pattern: PHONE_PATTERN,
              message: 'Please input valid phone number',
            },
          ],
        })(<Input type="phone" placeholder="Phone Number" />)}
      </Form.Item>

      <Form.Item label="Location">
        {getFieldDecorator('location', {
          rules: [{ required: true, message: 'Please input location' }],
        })(<SearchVillage />)}
      </Form.Item>

      <h2 className="my-2 text-gray-600">Assign Role By</h2>
      <Radio.Group className="mb-5" buttonStyle="solid" value={assignRoleBy} onChange={({ target }) => setAssignRoleBy(target.value)}>
        <Radio.Button value="position">Position</Radio.Button>
        <Radio.Button value="role">Role</Radio.Button>
      </Radio.Group>

      {assignRoleBy === 'position' && (
        <PositionFormItem
          withTitle={false}
          setFields={setFields}
          getFieldDecorator={getFieldDecorator}
          data={getFieldsValue()}
          onChangePosition={onPositionChange}
        />
      )}
      {assignRoleBy === 'role' && (
        <Form.Item label="Role">
          {getFieldDecorator('role_id', {
            rules: [{ required: true, message: 'Please select role' }],
          })(
            <Select placeholder="Search Role" style={{ width: '100%' }}>
              {roles?.map((role) => (
                <Select.Option key={role.code} value={role.id}>
                  {role.name}
                </Select.Option>
              ))}
            </Select>,
          )}
        </Form.Item>
      )}
      <div className="flex-end">
        <Button style={{ marginRight: '0.5em' }} onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Create New User
        </Button>
      </div>
    </Form>
  );
});

export default CreateUserForm;
