import { METHODS, API_BASE_URL, apiCall } from './config';

export const reportsNewApi = {
  get: (data) => apiCall(METHODS.GET, API_BASE_URL, 'v1/reports', data),
  downloadOtherReport: (id, data) => apiCall(METHODS.GET, API_BASE_URL, `v1/reports/download/${id}`, data),
};

export const visitationReportApi = {
  get: (data) => apiCall(METHODS.GET, API_BASE_URL, 'v1/visitation-report', data),
  getSummary: (data) => apiCall(METHODS.GET, API_BASE_URL, 'v1/visitation-summary', data),
};

export const kpiVisualizationApi = {
  getTransactionAmount: (data, additionalConfig) =>
    apiCall(METHODS.GET, API_BASE_URL, 'v1/visualizations/transaction/amount', data, additionalConfig),
  getTransactionCount: (data, additionalConfig) =>
    apiCall(METHODS.GET, API_BASE_URL, 'v1/visualizations/transaction/count', data, additionalConfig),
  getTransactionContributionMargin: (data, additionalConfig) =>
    apiCall(METHODS.GET, API_BASE_URL, 'v1/visualizations/transaction/contribution-margin', data, additionalConfig),
  getTransactionMauCount: (data, additionalConfig) =>
    apiCall(METHODS.GET, API_BASE_URL, 'v1/visualizations/transaction/mau-count', data, additionalConfig),
  getTransactionLoanRepayment: (data, additionalConfig) =>
    apiCall(METHODS.GET, API_BASE_URL, 'v1/visualizations/loan/repayment', data, additionalConfig),
};

export const kpiVisitationApi = {
  getVisitationCount: (data, additionalConfig) =>
    apiCall(METHODS.GET, API_BASE_URL, 'v1/visualizations/visitation/count', data, additionalConfig),
  getVisitationDuration: (data, additionalConfig) =>
    apiCall(METHODS.GET, API_BASE_URL, 'v1/visualizations/visitation/duration', data, additionalConfig),
  getVisitationCategorized: (data, additionalConfig) =>
    apiCall(METHODS.GET, API_BASE_URL, 'v1/visualizations/visitation/categorized', data, additionalConfig),
  getVisitationDistance: (data, additionalConfig) =>
    apiCall(METHODS.GET, API_BASE_URL, 'v1/visualizations/visitation/distance', data, additionalConfig),
};

export const salesFunnelApi = {
  getSalesFunnel: (data, additionalConfig) => apiCall(METHODS.GET, API_BASE_URL, 'v1/visualizations/funnel', data, additionalConfig),
};
