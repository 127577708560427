import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Input } from 'antd'
import { usersApi } from 'api/users'
import { toTitleCase } from 'utils/formatter/string'

const { TextArea } = Input
function ReasonMergeUser({ visible, close, user, newUser, history }) {
	const [reasonValue, setReasonValue] = useState('')
	const [loading, setLoading] = useState(false)

	const submit = async () => {
		const reqBody = {
			user_id_a: user.id,
			user_id_b: newUser.id,
			reason: reasonValue,
		}

		try {
			setLoading(true)
			const res = await usersApi.merge(reqBody)
			setLoading(false)
			if (!res) return
			setReasonValue('')
			history.push('/user-management')
			Modal.success({
				title: 'Success',
				content: `Account has been merged successfully`,
			})
		} catch (error) {
			setLoading(false)
		}

		setLoading(false)
	}
	return (
		<Modal
			loading={loading}
			destroyOnClose
			maskClosable={false}
			onOk={submit}
			title={<span style={{ fontWeight: 700 }}>Merge Account</span>}
			visible={visible}
			onCancel={() => {
				close()
				setReasonValue('')
			}}
			okText="Merge Account"
			okButtonProps={{ disabled: !reasonValue }}
			cancelText="Cancel"
			centered>
			<p style={{ fontSize: 16, textAlign: 'center' }}>
				Are you sure want to merge user <b>{toTitleCase(user.name)}</b> to user{' '}
				<b>{toTitleCase(newUser.name)}</b>? this action can not be undone in
				this position!
			</p>
			<p className="text-cf-14 mt-8 mb-4">
				Reason<span style={{ color: '#ac2f24' }}> *</span>
			</p>
			<TextArea
				rows={4}
				value={reasonValue}
				onChange={(e) => setReasonValue(e.target.value)}
			/>
		</Modal>
	)
}

export default withRouter(ReasonMergeUser)
