import { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { getCookies } from 'utils/cookies';
import { CONFIG_COOKIES } from 'config/cookies';
import { isProduction } from 'utils/access-right';

/**
 * A custom React hook that tracks pageviews in Google Analytics using
 * the ReactGA library.
 * @param {string|Function} pageEvent - The name of the event to track,
 * or a function that generates the event name dynamically based on the current
 * URL params.
 * @param {string} path - The path to match against the current URL to
 * determine if the hook should track the pageview.
 * @returns {null}
 */
const usePageTracking = ({ pageEvent, path }) => {
  const history = useHistory();
  const match = useRouteMatch(path);
  const merchantId = getCookies(CONFIG_COOKIES.MERCHANT_ID);

  useEffect(() => {
    /**
     * Tracks the pageview in Google Analytics.
     */

    const trackPage = () => {
      const eventName = typeof pageEvent === 'function' ? pageEvent(match?.params) : pageEvent;

      const eventParams = {
        path: match?.url,
        merchant_id: merchantId,
      };

      if (eventName && isProduction) {
        ReactGA.event(eventName, eventParams);
      } else {
        console.warn(
          'This page is not tracked by Google Analytics due to empty eventName at usePageTracking hooks or this is a staging environment',
        );
      }
    };

    trackPage();
  }, [history, match, pageEvent, merchantId]);

  return null;
};

export default usePageTracking;
