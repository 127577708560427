import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Input } from 'antd'
import { MerchantApi } from 'api/merchant'

const { TextArea } = Input
function ReasonChangeOwner({ visible, close, merchant, newOwner, onSuccess }) {
	const [reasonValue, setReasonValue] = useState('')
	const [loading, setLoading] = useState(false)

	const submit = async () => {
		const reqBody = {
			new_owner_id: newOwner,
			reason: reasonValue,
		}

		try {
			setLoading(true)
			const res = await MerchantApi.changeOwner(reqBody, merchant.id)
			setLoading(false)
			if (!res) return
			setReasonValue('')
			close()
			Modal.success({
				title: 'Success',
				content: `Change Owner has been sucessfully`,
				onOk: onSuccess,
			})
		} catch (error) {
			setLoading(false)
		}

		setLoading(false)
	}
	return (
		<Modal
			loading={loading}
			destroyOnClose
			maskClosable={false}
			onOk={submit}
			title={<span style={{ fontWeight: 700 }}>Change Owner</span>}
			visible={visible}
			onCancel={() => {
				close()
				setReasonValue('')
			}}
			okText="Change Owner"
			okButtonProps={{ disabled: !reasonValue }}
			cancelText="Cancel"
			centered>
			<p style={{ fontSize: 16, textAlign: 'center' }}>
				Are you sure you want to change the account owner? the previous account
				owner will lose all his access
			</p>
			<p className="text-cf-14 mt-8 mb-4">
				Reason<span style={{ color: '#ac2f24' }}> *</span>
			</p>
			<TextArea
				rows={4}
				value={reasonValue}
				onChange={(e) => setReasonValue(e.target.value)}
			/>
		</Modal>
	)
}

export default withRouter(ReasonChangeOwner)
