import { Button, Card, Tag } from 'components';
import React from 'react';
import PropTypes from 'prop-types';
import { PERMISSION_MENU as M, PERMISSION_TYPES as T } from 'config/permissions';

import { TAG_COLOR } from 'config/acquisition';
import { capitalize } from 'utils/formatter/string';
import { isLessthan24hr } from 'utils/date';
import { hasRight } from 'utils/access-right';
import CountDownBefore24Hrs from './CountDownBefore24Hrs';

const IN_REVIEW = 'inreview' || 'in review';
const APPROVED = 'approved';
const REJECTED = 'rejected';

export default function ActionButtons({ loading, onApprove, onReject, onClickEdit, editMode, acqData, onRevertReview }) {
  const isInReview = acqData?.status.toLowerCase() === IN_REVIEW;
  const isApproved = acqData?.status.toLowerCase() === APPROVED;
  const isRejected = acqData?.status.toLowerCase() === REJECTED;
  const isAllowedToRevert = (isRejected || isApproved) && isLessthan24hr(acqData?.reviewed_date);

  return (
    <Card loading={loading} bordered={false} className="p-0">
      <div className="flex gap-2">
        {isInReview && (
          <>
            <Button type="primary" className="ml-auto" loading={loading} onClick={onApprove}>
              Approve
            </Button>
            <Button type="danger" loading={loading} onClick={onReject}>
              Reject
            </Button>
          </>
        )}
        {!isInReview && (
          <div className="flex items-center w-full justify-between">
            <div className="flex items-center">
              {isApproved && (
                <Button onClick={onClickEdit} className="mr-4" size="small">
                  {editMode ? 'Cancel' : 'Edit'}
                </Button>
              )}
              {editMode && isApproved && (
                <Button size="small" type="primary" htmlType="submit" loading={false} disabled={false}>
                  Save
                </Button>
              )}
            </div>

            <div className="flex items-center gap-2">
              <Tag className="md:mt-0 mt-3 pt-0.5" color={TAG_COLOR[acqData?.status]}>
                <span>{capitalize(acqData?.status || 'Invalid')}</span>
              </Tag>

              {isAllowedToRevert && hasRight({ menu: M.ACQUISITION_REVIEW, type: T.UNDO_REVIEW }) && (
                <div>
                  <Button type="warning" loading={loading} onClick={onRevertReview} size="small">
                    Undo
                  </Button>
                  <CountDownBefore24Hrs reviewedDate={acqData?.reviewed_date || ''} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}

ActionButtons.propTypes = {
  loading: PropTypes.bool.isRequired,
  onApprove: PropTypes.func.isRequired,
  onRevertReview: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  acqData: PropTypes.shape({
    status: PropTypes.string,
    reviewed_date: PropTypes.string,
  }).isRequired,
};
