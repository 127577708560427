import React from 'react';
import { toTitleCase } from 'utils/formatter/string';
import { visitationReportApi } from 'api/reports';
import useCharts from 'customHooks/useCharts';
import useMediaQuery from 'customHooks/useMediaQuery';
import SummaryCard from '../../shared/SummaryCard';

function SummarySection() {
  const { data, loading } = useCharts({
    callApi: async (params) => {
      if (!params?.sales_id) return null;
      const res = await visitationReportApi.getSummary(params);
      return res;
    },
  });

  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <SummaryCard loading={loading}>
      <SummaryCard.Col>
        <p className="font-bold text-cf-16 mb-2 text-cf-gray">{toTitleCase(data?.sales_name) || '-'}</p>
        <p className="text-cf-12 mb-2">{data?.sales_code?.toUpperCase() || '-'}</p>
        <p className="text-cf-12">{toTitleCase(data?.sales_phone) || '-'}</p>
      </SummaryCard.Col>
      <SummaryCard.Col>
        <p className={`text-cf-12 mb-3 ${isMobile ? '' : 'text-center'}`}>Check-in Visitation</p>
        <p className={`font-bold ${isMobile ? '' : 'text-center'} text-6xl text-cf-gray`}>{data?.visitation_count || '0'}</p>
      </SummaryCard.Col>
      <SummaryCard.Col>
        <p className={`text-cf-12 mb-3 ${isMobile ? '' : 'text-center'}`}>Online Engagement</p>
        <p className={`font-bold ${isMobile ? '' : 'text-center'} text-6xl text-cf-gray`}>{data?.engagement_count || '0'}</p>
      </SummaryCard.Col>
    </SummaryCard>
  );
}

export default SummarySection;
