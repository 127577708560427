export const formatPercent = (strNum) => {
  const percentNum = +strNum || 0;
  if (percentNum >= 10) {
    return `${percentNum.toFixed(0)}%`;
  }

  return `${percentNum.toFixed(1)}%`;
};

export const formatNumber = (num) => {
  if (num >= 100) {
    return `${+num.toFixed(0)}`;
  }

  return `${parseFloat(+num).toFixed(1)}`;
};
