import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';

import { withRouter } from 'react-router-dom';
import { Modal, Input, notification } from 'antd';

import { AcquisitionReviewApi } from 'api/acquisition-review';

const { TextArea } = Input;
function RejectAcquisition({ visible, close, payload, onSuccess }) {
  const [reasonValue, setReasonValue] = useState('');
  const [loading, setLoading] = useState(false);
  const sendError = useRollbarNotifier();

  const handleReject = async () => {
    try {
      setLoading(true);
      await AcquisitionReviewApi.reject(payload?.id, {
        note: reasonValue,
      });
      notification.success({
        message: 'Success',
        description: 'Acquisition review has been rejected successfully',
      });
      setReasonValue('');
      onSuccess();
      close();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      sendError(`Error when reject acquisition`, error);
    }
  };
  return (
    <Modal
      loading={loading}
      destroyOnClose
      maskClosable={false}
      onOk={handleReject}
      title={<span style={{ fontWeight: 700 }}>Reject Acquisition Review</span>}
      visible={visible}
      onCancel={() => {
        close();
        setReasonValue('');
      }}
      okText="Reject"
      okButtonProps={{ disabled: !reasonValue, loading, size: 'large', className: 'font-bold' }}
      cancelButtonProps={{ size: 'large', className: 'font-bold' }}
      cancelText="Cancel"
    >
      <p style={{ fontSize: 16, textAlign: 'center' }}>Are you sure want to reject this Acquisition Review?</p>
      <p className="text-cf-14 mt-8 mb-4">
        Reason<span style={{ color: '#ac2f24' }}> *</span>
      </p>
      <TextArea rows={4} value={reasonValue} onChange={(e) => setReasonValue(e.target.value)} />
    </Modal>
  );
}

RejectAcquisition.propTypes = {
  visible: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  payload: PropTypes.object,
};

RejectAcquisition.defaultProps = {
  payload: null,
};

export default withRouter(RejectAcquisition);
