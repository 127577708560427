import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';

import { notification } from 'antd';
import { areasApi } from 'api/areas';

function useAreaPresetDetail() {
  const [areaPresetDetail, setAreaPresetDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const sendError = useRollbarNotifier();

  const [lastPageReload, setLastPageReload] = useState(null);
  const reload = () => setLastPageReload(new Date());

  const { id } = useParams();

  const getData = async () => {
    setIsLoading(true);
    try {
      const res = await areasApi.areaPresetDetail(id);

      setAreaPresetDetail(res);
      setIsLoading(false);
    } catch (error) {
      notification.error({
        message: "Can't load area preset detail",
        description: `Error ${error.toString()}`,
      });
      sendError(`Error when getData area preset detail`, error);
      setIsLoading(false);
    }
  };

  const deleteAreaPreset = async () => {
    setIsLoading(true);

    try {
      await areasApi.deleteAreaPreset(id);
      setIsLoading(false);

      return areaPresetDetail?.data;
    } catch (error) {
      notification.error({
        message: 'Delete Area Preset Error',
        description: `Error ${error.message}`,
      });
      sendError(`Error when delete Area Preset`, error);
      setIsLoading(false);
    }

    return null;
  };

  useEffect(() => {
    getData();
  }, [lastPageReload]);

  return {
    areaPresetDetail,
    isLoading,
    showDeleteModal,
    setShowDeleteModal,
    deleteAreaPreset,
    showEditModal,
    setShowEditModal,
    reload,
  };
}

export default useAreaPresetDetail;
