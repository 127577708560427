import React from 'react';
import PropTypes from 'prop-types';

import { Pagination, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import { parseUrlQueryParams, generateUrlQueryParams } from 'utils/query';

import './PaginationQuery.scss';

function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <Icon type="left" />;
  }
  if (type === 'next') {
    return <Icon type="right" />;
  }
  return originalElement;
}

function PaginationQuery({ className = '', style = {}, history, location, pageSize, total }) {
  const currentPage = parseUrlQueryParams(location.search).page;

  const updatePageQuery = (value) => {
    history.push({
      search: generateUrlQueryParams({
        ...parseUrlQueryParams(location.search),
        page: value,
      }),
    });
  };

  return (
    <div className={`flex justify-center items-center w-full ${className || ''}`} style={style}>
      <Pagination
        className="pagination-query"
        current={currentPage}
        pageSize={pageSize}
        total={total}
        onChange={updatePageQuery}
        itemRender={itemRender}
      />
    </div>
  );
}

PaginationQuery.propTypes = {
  pageSize: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

PaginationQuery.defaultProps = {
  className: '',
  style: {},
};

export default withRouter(PaginationQuery);
