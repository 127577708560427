export function secondsToHms(d) {
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h + (h === 1 ? ' Hour ' : ' Hours ') : '';
  const mDisplay = m > 0 ? m + (m === 1 ? ' Minute ' : ' Minutes ') : '';
  const sDisplay = s > 0 ? s + (s === 1 ? ' Second' : ' Seconds') : '';
  return hDisplay + mDisplay + sDisplay;
}

export default secondsToHms;
