import qs from 'qs';

export const getQueryString = (search) => {
  const query = qs.parse(search.replace('?', ''));
  const offset = (parseInt(query.page, 10) - 1 || 0) * 10;
  return {
    ...query,
    offset,
  };
};

export const queryStringToObject = (queryString) => {
  if (!queryString) return {};
  const str = queryString.substring(1);
  return JSON.parse(`{"${str.replace(/&/g, '","').replace(/=/g, '":"')}"}`, (key, value) => {
    return key === '' ? value : decodeURIComponent(value);
  });
};

export const getQueryStringForPagination = (search) => {
  const query = qs.parse(search.replace('?', ''));
  const page = parseInt(query.page, 10) || 1;
  return {
    ...query,
    page,
  };
};

export const generateUrlQueryParams = (params, withoutPage) => {
  const paramsClone = withoutPage ? { ...params } : { ...params, page: params.page || 1 };
  const keys = Object.keys(params);
  keys.map((key) => {
    if (!params[key]) delete paramsClone[key];
    return key;
  });
  return qs.stringify(paramsClone);
};

export const parseUrlQueryParams = (query) => {
  const parsedQuery = qs.parse(query.replace('?', ''));
  return parsedQuery.page ? { ...parsedQuery, page: parseInt(parsedQuery.page, 10) } : { ...parsedQuery, page: 1 };
};
