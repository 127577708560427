import React, { useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

import { SelectSearchQuery } from 'components';
import { MONTHS, YEARS, QUARTERS, RANGE_TYPES } from 'config/report';
import { parseUrlQueryParams } from 'utils/query';

const TYPES = { MONTHLY: 'monthly', YEARLY: 'yearly', QUARTERLY: 'quarterly' };

function FilterQuerySection({ location, defaultQueries }) {
  const rangeType = useMemo(() => parseUrlQueryParams(location.search)?.type || defaultQueries.type, [location.search]);
  const isYearly = rangeType === TYPES.YEARLY;
  const isMonthly = rangeType === TYPES.MONTHLY;
  const isQuarterly = rangeType === TYPES.QUARTERLY;

  return (
    <Row gutter={[16, 16]} className="mb-10">
      <Col span={24} md={4}>
        <SelectSearchQuery className="w-full" options={RANGE_TYPES} fieldName="type" initialValue={defaultQueries.type} />
      </Col>
      {!isYearly && isMonthly && (
        <Col span={24} md={4}>
          <SelectSearchQuery className="w-full" options={MONTHS} fieldName="month" initialValue={defaultQueries.month} />
        </Col>
      )}
      {!isYearly && isQuarterly && (
        <Col span={24} md={4}>
          <SelectSearchQuery className="w-full" options={QUARTERS} fieldName="quarter" initialValue={defaultQueries.quarter} />
        </Col>
      )}
      <Col span={24} md={4}>
        <SelectSearchQuery className="w-full" options={YEARS} fieldName="year" initialValue={defaultQueries.year} />
      </Col>
    </Row>
  );
}

FilterQuerySection.propTypes = {
  location: PropTypes.object.isRequired,
  defaultQueries: PropTypes.object.isRequired,
};

export default withRouter(FilterQuerySection);
