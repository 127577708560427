import React from 'react'
import './index.css'
import useMediaQuery from 'customHooks/useMediaQuery'

function GoogleMapDisplay({
	width = 500,
	height = 300,
	latitude,
	longitude,
	...props
}) {
	// responsive layout
	const isMobile = useMediaQuery('(max-width: 768px)')
	return (
		<iframe
			title="google-map"
			className="mt-5"
			style={{ width: isMobile ? '100%' : width }}
			width={!isMobile ? width : undefined}
			height={!isMobile ? height : undefined}
			frameBorder="0"
			scrolling="no"
			marginHeight="0"
			marginWidth="0"
			src={`https://maps.google.com/maps?q=${latitude},${longitude}&output=embed`}
			{...props}
		/>
	)
}

export default GoogleMapDisplay
