import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Input } from 'antd'

const { TextArea } = Input
function DeleteModal({ visible, close, onOk, history }) {
	const [reasonValue, setReasonValue] = useState('')
	const [loading, setLoading] = useState(false)

	const submit = async () => {
		try {
			setLoading(true)
			const areaPresetDetail = await onOk()
			setLoading(false)
			if (!areaPresetDetail) return
			setReasonValue('')
			close()
			Modal.success({
				title: 'Success Delete Area Preset',
				content: `Success delete area preset ${areaPresetDetail?.name || ''}`,
				onOk: history.goBack,
			})
		} catch (error) {
			setLoading(false)
		}

		setLoading(false)
	}
	return (
		<Modal
			destroyOnClose
			maskClosable={false}
			onOk={submit}
			title={<span style={{ fontWeight: 700 }}>Delete Area</span>}
			visible={visible}
			onCancel={() => {
				close()
				setReasonValue('')
			}}
			okText="Delete"
			okButtonProps={{ disabled: !reasonValue, loading }}
			cancelText="Cancel"
			centered>
			<p style={{ fontSize: 16, textAlign: 'center' }}>
				Are you sure want to delete this Area?
			</p>
			<p className="text-cf-14 mt-8 mb-4">
				Reason<span style={{ color: '#ac2f24' }}> *</span>
			</p>
			<TextArea
				rows={4}
				value={reasonValue}
				onChange={(e) => setReasonValue(e.target.value)}
			/>
		</Modal>
	)
}

export default withRouter(DeleteModal)
