import { METHODS, API_BASE_URL, API_URIS, apiCall } from './config';
import { GeoApi } from './geo';

export const usersApi = {
  get: (data) => apiCall(METHODS.GET, API_BASE_URL, '/v1/users', { ...data, asc: true }),
  getDetail: (id, data) => apiCall(METHODS.GET, API_BASE_URL, `/v1/users/${id}`, data),
  listEvents: (id, data) => apiCall(METHODS.GET, API_BASE_URL, `/v1/users/${id}/events`, data),
  suspend: (id, data) => apiCall(METHODS.POST, API_BASE_URL, `/v1/users/${id}/suspend`, data),
  unsuspend: (id, data) => apiCall(METHODS.POST, API_BASE_URL, `/v1/users/${id}/unsuspend`, data),
  delete: (id, data) => apiCall(METHODS.POST, API_BASE_URL, `/v1/users/${id}/delete`, data),
  merge: (data) => apiCall(METHODS.POST, API_BASE_URL, `/v1/users/merge`, data),
  update: (id, data) => apiCall(METHODS.POST, API_BASE_URL, `/v1/users/${id}`, data),
  getRoles: (data) => apiCall(METHODS.GET, API_BASE_URL, '/v1/roles', data),
  getPositionAreas: (data) => apiCall(METHODS.GET, API_URIS.AUTH, 'v1/areas', data),
  getPositions: (data) => apiCall(METHODS.GET, API_URIS.AUTH, 'v1/title_user', data),
  getPosition: (code) => apiCall(METHODS.GET, API_URIS.AUTH, `v1/title_user/${code}`),
  getAreas: (data) => apiCall(METHODS.GET, API_URIS.AUTH, 'v1/area/search', data),
  getAreaList: (data) => apiCall(METHODS.GET, API_URIS.AUTH, 'v1/area', data),
  getDomains: (data) => apiCall(METHODS.GET, API_URIS.CLIENT, 'v1/clients', data),
  getGeofazz: ({ provinces, regencies } = {}) => GeoApi.getHierarchyNoVillages(provinces, regencies),
  getGeofazzWithVillages: ({ provinces, regencies, districts, villages } = {}) =>
    GeoApi.getHierarchy(provinces, regencies, districts, villages),
  getLocationsForView: (role, locationCodes) => {
    const { provinceCodes, regencyCodes, districtCodesAll, villagesCodes } = locationCodes;
    return role === 'HOS' || role === 'HOR'
      ? GeoApi.getHierarchyNoVillages(provinceCodes, regencyCodes, districtCodesAll)
      : GeoApi.getHierarchy(provinceCodes, regencyCodes, districtCodesAll, villagesCodes);
  },
  getZoneAvailability: (data) => apiCall(METHODS.POST, API_URIS.AUTH, 'v1/title/territory', data),
  getHistories: (id) => apiCall(METHODS.GET, API_URIS.AUTH, `v1/title/history/${id}`),
  createUser: (data) => apiCall(METHODS.POST, API_BASE_URL, '/v1/users', data),
  createPosition: (data) => apiCall(METHODS.POST, API_URIS.AUTH, 'v1/title', data),
  createAreaPreset: (data) => apiCall(METHODS.POST, API_URIS.AUTH, 'v1/area-presets', data),
  editPosition: (id, data) => apiCall(METHODS.PUT, API_URIS.AUTH, `v1/title/${id}`, data),
  deletePosition: (id, data) => apiCall(METHODS.DELETE, API_URIS.AUTH, `v1/title/${id}`, data),
  switchUser: (id1, id2, data) => apiCall(METHODS.PUT, API_URIS.AUTH, `v1/user/switch/${id1}/${id2}`, data),
  expelUser: (id, data) => apiCall(METHODS.PUT, API_URIS.AUTH, `v1/user/remove/${id}`, data),
  checkClient: (data) => apiCall(METHODS.POST, API_URIS.AUTH, 'v1/register/integration/check', data),
  searchSwitchUsers: (search) => apiCall(METHODS.GET, API_URIS.AUTH, 'v1/title/switch', { search }),
  getUserHistories: (id, data) => apiCall(METHODS.GET, API_URIS.AUTH, `v1/user/${id}/history`, data),
  getTitleHistories: (id, data) => apiCall(METHODS.GET, API_URIS.AUTH, `v1/user/${id}/title/history`, data),
  getUserTitleHistories: (id, data) => apiCall(METHODS.GET, API_URIS.AUTH, `v1/title_user/history/${id}`, data),
  getHistoryDetail: (id, historyId) =>
    Promise.all([
      apiCall(METHODS.GET, API_URIS.AUTH, `v1/user/${id}/history/${historyId}`),
      apiCall(METHODS.GET, API_URIS.CHECK, `v1/kyc/${id}/history/${historyId}`),
    ]).then((res) => ({
      ...res[0],
      data: {
        ...res[0].data,
        kyc: res[1].data,
      },
    })),
  checkIsUserExist: (data) => apiCall(METHODS.GET, API_BASE_URL, 'v1/users/checkexist', data),
  promoteUser: (userId, data) => apiCall(METHODS.POST, API_URIS.AUTH, `v1/users/${userId}/assign`, data),
  getUserPermissions: (merchantID, data) => {
    return apiCall(METHODS.GET, API_BASE_URL, `v1/users/permissions`, data, {}, { merchantID });
  },
  searchSales: (search) => apiCall(METHODS.GET, API_BASE_URL, '/v1/users/accessible', { name: search }),
};

export default usersApi;
