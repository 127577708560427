import React from 'react';
import moment from 'moment';
import { capitalize } from 'utils/formatter/string';
import { VISITATION_STATUS_OPTIONS } from 'config/visit-assignment';
import Tag from 'components/Tag/v2';

export const columns = (onClickBusinessName) => [
  {
    title: <p>Assignment Date</p>,
    dataIndex: 'assignment_date',
    key: 'assignment_date',
    width: 200,
    render: (text) => moment(text).format('DD MMMM YYYY (HH:mm)'),
  },
  {
    title: <p>Visitation Date</p>,
    dataIndex: 'visitation_date',
    key: 'visitation_date',
    width: 200,
    render: (text) => moment(text).format('DD MMMM YYYY (HH:mm)'),
  },
  {
    title: <p>Visitor</p>,
    dataIndex: 'visitor_name',
    key: 'visitor_name',
    render: (text) => capitalize(text) || '-',
  },
  {
    title: <p>Business Name (Leads)</p>,
    dataIndex: 'acquisition_business_name',
    key: 'acquisition_business_name',
    render: (text, row) => (
      <div className="underline font-semibold text-blue-700 cursor-pointer" onClick={() => onClickBusinessName(row)}>
        {capitalize(text) || '-'}
      </div>
    ),
  },
  {
    title: <p>Visitation Status</p>,
    dataIndex: 'visitation_status',
    key: 'visitation_status',
    align: 'center',

    render: (code) => {
      const status = VISITATION_STATUS_OPTIONS.find((opt) => opt.key === code);
      return (
        <div className="w-min mx-auto">
          <Tag key={code} bgColor={status?.bgColor} textColor={status?.textColor}>
            {status.label}
          </Tag>
        </div>
      );
    },
  },
];

export default { columns };
