export const CONFIG_COOKIES = {
  TOKEN: '_canfazzFreelancerDashboardToken',
  REFRESH_TOKEN: '_canfazzFreelancerDashboardRefreshToken',
  USER_ID: `_canfazzDashboardUserID`,
  USER_IMG_URL: `_canfazzDashboardUserImgUrl`,
  EMAIL: `_canfazzDashboardUserEmail`,
  FULLNAME: '_canfazzFreelancerDashboardFullname',
  ROLE: '_canfazzFreelancerDashboardRole',
  CODE: '_canfazzFreelancerDashboardUserCode',
  TOKEN_CANFAZZ: '_canfazzFreelancerDashboardCanfazzToken',
  MERCHANT_ID: '_canfazzDashboardMerchantID',
  ROLE_TITLE: '_canfazzFreelancerDashboardRoleTitle',
  PERMISSIONS: '_canfazzFreelancerDashboardPermissions',
};

export default CONFIG_COOKIES;
