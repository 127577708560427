import React from 'react';
import { Col, Row } from 'antd';
import { CONFIG_COOKIES } from 'config/cookies';
import { MENU } from 'config/menu';
import { PERMISSION_MENU as M, PERMISSION_TYPES as T } from 'config/permissions';
import Layout from 'layout/main';
import { useHistory } from 'react-router-dom';
import { hasRight } from 'utils/access-right';
import { getCookies } from 'utils/cookies';

import ImgMainPicture from 'assets/wd-main-picture.png';
import IconAcquisitionReview from 'assets/wd-icon-acquisition-review.png';
import IconAccountManagement from 'assets/wd-icon-account-management.png';
import IconPositionManagement from 'assets/wd-icon-position-management.png';
import IconMerchantManagement from 'assets/wd-icon-merchant-management.png';
import IconUserManagement from 'assets/wd-icon-user-management.png';
import IconReportingTools from 'assets/wd-icon-reporting-tools.png';

import Pane from './Pane';
import Text from './Text';
import { getGreetingTime } from './time-greeting';

export default function Dashboard() {
  const history = useHistory();
  const greeting = getGreetingTime();
  const user = getCookies(CONFIG_COOKIES.FULLNAME);
  const role = getCookies(CONFIG_COOKIES.ROLE_TITLE);

  const redirect = ({ menu, type, route }) => {
    const isAllowed = hasRight({ menu, type });
    if (!isAllowed) return;
    history.push(route);
  };

  return (
    <Layout title="Dashboard">
      <div className="m-6 p-5 lg:mx-auto mx-0" style={{ maxWidth: 1500 }}>
        <Row gutter={20}>
          <Col md={24} lg={8} xl={8}>
            <div className="rounded-2xl flex flex-col overflow-hidden mb-8" style={{ background: '#009696', height: 559 }}>
              <div className="m-auto mt-24" style={{ maxWidth: 250 }}>
                <Text>{greeting},</Text>
                <Text>{user}!</Text>
                <Text size="small">{role}</Text>
              </div>
              <div className="flex justify-center items-center">
                <img className="inline-block cursor-pointer" src={ImgMainPicture} alt="canfazz-logo" />
              </div>
            </div>
          </Col>
          <Col md={24} lg={16} xl={16}>
            <Row gutter={20}>
              <Pane
                title="Acquisition Review"
                srcImg={IconAcquisitionReview}
                desc="Melihat detail agen di area Anda dan approve/reject akuisisi yang digunakan oleh Sales Eksekutif"
                disabled={!hasRight({ menu: M.ACQUISITION_REVIEW, type: T.READ })}
                onClick={() =>
                  redirect({
                    menu: M.ACQUISITION_REVIEW,
                    type: 'read',
                    route: MENU.ACQUISITION_REVIEW,
                  })
                }
              />
              <Pane
                title="Role Management"
                srcImg={IconAccountManagement}
                desc="Melakukan pengaturan permission akses dashboard untuk setiap role yang ada di organisasi Anda"
                disabled={!hasRight({ menu: M.ACCOUNT_MANAGEMENT, type: T.READ })}
                onClick={() =>
                  redirect({
                    menu: M.ACCOUNT_MANAGEMENT,
                    type: 'read',
                    route: MENU.ACCOUNT_MANAGEMENT,
                  })
                }
              />
              <Pane
                title="Position Management"
                srcImg={IconPositionManagement}
                desc="Melakukan pengaturan coverage area untuk masing-masing Sales Executive"
                disabled={!hasRight({ menu: M.POSITION_MANAGEMENT, type: T.READ })}
                onClick={() =>
                  redirect({
                    menu: M.POSITION_MANAGEMENT,
                    type: 'read',
                    route: MENU.POSITION_MANAGEMENT,
                  })
                }
              />
              <Pane
                title="Merchant Management"
                srcImg={IconMerchantManagement}
                desc="Melakukan configurasi merchant baru"
                disabled={!hasRight({ menu: M.MERCHANT_MANAGEMENT, type: T.READ })}
                onClick={() =>
                  redirect({
                    menu: M.MERCHANT_MANAGEMENT,
                    type: 'read',
                    route: MENU.MERCHANT_MANAGEMENT_LIST,
                  })
                }
              />
              <Pane
                title="User Management"
                srcImg={IconUserManagement}
                desc="Mendaftarkan user baru, melihat user yang terdaftar dan menghapus user yang dapat mengakses merchant Anda di CANFAZZ apps"
                disabled={!hasRight({ menu: M.USER_MANAGEMENT, type: T.READ })}
                onClick={() =>
                  redirect({
                    menu: M.USER_MANAGEMENT,
                    type: 'read',
                    route: MENU.USER_MANAGEMENT,
                  })
                }
              />
              <Pane
                title="Reporting Tools"
                srcImg={IconReportingTools}
                desc="Melihat laporan yang berkaitan dengan organisasi Anda"
                disabled={!hasRight({ menu: M.REPORTING_TOOLS, type: T.READ })}
                onClick={() =>
                  redirect({
                    menu: M.REPORTING_TOOLS,
                    type: 'read',
                    route: MENU.OTHER_REPORTS,
                  })
                }
              />
              <Pane
                title="Visit Assignment"
                srcImg={IconPositionManagement}
                desc="Melihat laporan yang berkaitan dengan tugas visitasi"
                disabled={!hasRight({ menu: M.VISIT_ASSIGNMENT, type: T.READ })}
                onClick={() =>
                  redirect({
                    menu: M.VISIT_ASSIGNMENT,
                    type: 'read',
                    route: MENU.VISIT_ASSIGNMENT,
                  })
                }
              />
            </Row>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}
