import React from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';

import './table.scss';

function ListView({ data = {}, loading = false, className = '', style = {} }) {
  if (loading)
    return (
      <div className={`loading ${className}`} style={style}>
        <Spin />
      </div>
    );

  return (
    <table className={`table text-cf-lightGray ${className}`} style={style}>
      <tbody>
        {Object.keys(data).map((title) => {
          return (
            <tr key={title}>
              <td className="titleTable">
                <div className="whitespace-nowrap">{title}</div>
              </td>
              <td>:</td>
              <td>{data[title] || '-'}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

ListView.propTypes = {
  className: PropTypes.func.isRequired,
  data: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  style: PropTypes.bool,
};

ListView.defaultProps = {
  data: {},
  style: {},
};

export default ListView;
