import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import useMediaQuery from 'customHooks/useMediaQuery';

function SummaryCard({ loading, children }) {
  return (
    <Card bodyStyle={{ padding: 12 }} loading={loading} className="mb-6">
      <div className="flex">{children}</div>
    </Card>
  );
}

SummaryCard.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

SummaryCard.defaultProps = {
  loading: false,
};

function Col({ children, withoutBorder }) {
  const isMobile = useMediaQuery('(max-width: 768px)');

  const widthClass = isMobile ? 'w-full' : 'w-80';
  const borderClass = !withoutBorder && !isMobile ? 'border-r' : '';
  const paddingClass = 'p-5 self-stretch';

  const classNames = [widthClass, borderClass, paddingClass].filter((cls) => cls !== '').join(' ');

  return <div className={classNames}>{children}</div>;
}

Col.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  withoutBorder: PropTypes.bool,
};

Col.defaultProps = {
  withoutBorder: false,
};

SummaryCard.Col = Col;
export default SummaryCard;
