import React, { useState } from 'react';
import { Icon, Layout } from 'antd';
import PropTypes from 'prop-types';
import { MENU } from 'config/menu';
import { withRouter } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import { PUBLIC_URL } from '../../config/url';
import Sidebar from './sidebar';
import './main.scss';

const { Header, Content } = Layout;

function Main({ children, title, location, history }) {
  const [visible, setVisible] = useState(false);
  const locs = location.pathname ? location.pathname.replace(PUBLIC_URL, '').split('/') : [];
  const getActiveNavLink = () => (locs.length > 1 ? locs[1] : '');
  return (
    <Layout className="main">
      <Sidebar visible={visible} close={() => setVisible(false)} getActiveNavLink={getActiveNavLink} />
      <Header className="main__header">
        <h2>{title}</h2>
        <div className="main-hedaer__logo">
          <Icon className="align-baseline" type="menu" onClick={() => setVisible(true)} />
          <div className="-mt-20 ml-6 pt-1" onClick={() => history.push(MENU.DASHBOARD)}>
            <img className="inline-block cursor-pointer" src={Logo} alt="canfazz-logo" />
          </div>
        </div>
      </Header>
      <Content className="app-content__container">{children}</Content>
    </Layout>
  );
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(Main);
