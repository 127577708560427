import React from 'react';
import PropTypes from 'prop-types';
import { nFormatter } from 'utils/formatter/string';

export default function CustomizedLabel({ x, y, stroke, value, width }) {
  return (
    <text x={x + width / 2} y={y} dy={-5} fill={stroke} fontSize={10} textAnchor="middle">
      {nFormatter(value)}
    </text>
  );
}

CustomizedLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  stroke: PropTypes.string,
  value: PropTypes.number,
  width: PropTypes.number,
};

CustomizedLabel.defaultProps = {
  x: 0,
  y: 0,
  stroke: '',
  value: 0,
  width: 0,
};
