import React from 'react'
import { Tooltip, Tag } from 'antd'
import moment from 'moment'
import { COLOR_STATUS_MAPPED } from 'config/users'
import { capitalizeFirstLetter } from 'utils/helpers'
import { TableTitle } from 'components/table'
import { Button } from 'components'

export const userColumns = (viewDetailAction) => {
	return [
		{
			title: <TableTitle className="px-4">User ID</TableTitle>,
			dataIndex: 'id',
			key: 'id',
			render: (id) => (
				<div style={{ maxWidth: '100px' }} className="ml-4">
					<Tooltip title={id}>
						<div className="app-ellipsis-text">{id}</div>
					</Tooltip>
				</div>
			),
		},
		{
			title: <TableTitle>Full name</TableTitle>,
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: <TableTitle>Email</TableTitle>,
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: <TableTitle>Phone Number</TableTitle>,
			dataIndex: 'phone',
			key: 'phone',
			render: (data) => <div>{data || '-'}</div>,
		},
		{
			title: <TableTitle>Role</TableTitle>,
			dataIndex: 'role',
			key: 'role',
			render: (role) => <div>{role}</div>,
		},
		{
			title: <TableTitle>Status</TableTitle>,
			dataIndex: 'status',
			key: 'status',
			render: (status) => (
				<Tag color={COLOR_STATUS_MAPPED[status]}>
					{capitalizeFirstLetter(status)}
				</Tag>
			),
		},
		{
			title: <TableTitle>Registration Date</TableTitle>,
			dataIndex: 'created_date_utc',
			key: 'registration_date_utc',
			render: (data) => (
				<div>{data ? moment(data).format('DD/MM/YYYY hh:ss') : '-'}</div>
			),
		},
		{
			title: <TableTitle>Last Active Date</TableTitle>,
			dataIndex: 'last_active_date',
			key: 'last_active_date',
			render: (data) => (
				<div>{data ? moment(data).format('DD/MM/YYYY hh:ss') : '-'}</div>
			),
		},
		{
			title: <TableTitle>Action</TableTitle>,
			key: 'action',
			align: 'center',
			render: (id, record) => (
				<Button
					size="small"
					className="mx-auto"
					onClick={() => viewDetailAction(id, record)}>
					View Detail
				</Button>
			),
		},
	]
}
