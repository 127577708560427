// export const ACQUISITION_STATUS = {
// 	APPROVED: 'approved',
// 	REJECTED: 'rejected',
// 	IN_REVIEW: 'inreview',
// }

export const ACQUISITION_STATUS = [
	{ key: '', label: 'All status' },
	{ key: 'approved', label: 'Approved' },
	{ key: 'rejected', label: 'Rejected' },
	{ key: 'inreview', label: 'Inreview' },
]

export const TAG_COLOR = {
	inreview: '#ffc53d',
	'in review': '#ffc53d',
	inprogress: '#ffc53d',
	completed: '#3b8ef3',
	approved: '#87d068',
	rejected: '#ba1818',
	expired: '#757575',
	canceled: '#ffb72a',
	'completed-edited': '#2dc4f8',
}

export const BUSINESS_TYPES = [
	'Toko Kelontong',
	'Toko Pulsa & Handphone',
	'Toko Elektronik',
	'Toko Fashion',
	'Toko Bengkel Otomotif',
	'Toko Alat Tulis & Buku',
	'Rumah Makan',
	'Jasa Perjalanan (Travel)',
	'Jasa Internet (Warnet)',
	'Jasa Cuci Pakaian (Laundry)',
	'Apotek',
	'Agen Laku Pandai',
	'Lain Lain',
]
