import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withRouter } from 'react-router-dom';
import { DatePicker } from 'antd';
import { generateUrlQueryParams, parseUrlQueryParams } from 'utils/query';
import './SelectRangePickerQuery.scss';

const { RangePicker } = DatePicker;
const DATE_FORMAT = 'YYYY-MM-DD';

function SelectMonthPickerQuery({ location, history, className = '', initialValue }) {
  const [value, setValue] = useState(initialValue);
  const { start_filter: startFilter, end_filter: endFilter } = useMemo(() => parseUrlQueryParams(location.search), [location.search]);

  const handlePanelChange = (val) => {
    const startDate = val[0].startOf('month').format(DATE_FORMAT);
    const endDate = val[1].endOf('month').format(DATE_FORMAT);

    history.push({
      search: generateUrlQueryParams({
        ...parseUrlQueryParams(location.search),
        start_filter: startDate,
        end_filter: endDate,
        page: 1,
      }),
    });
    setValue(val);
  };

  useEffect(() => {
    const start = moment(startFilter, DATE_FORMAT);
    const end = moment(endFilter, DATE_FORMAT);

    if (start.isValid() && end.isValid()) {
      return setValue([start, end]);
    }

    return setValue(initialValue);
  }, [endFilter, startFilter]);

  return (
    <div className={`${className || ''}`}>
      <RangePicker
        placeholder={['Start month', 'End month']}
        format="YYYY-MM"
        mode={['month', 'month']}
        size="large"
        className="w-full h-48px text-cf-14"
        value={value}
        onPanelChange={handlePanelChange}
      />
    </div>
  );
}

SelectMonthPickerQuery.propTypes = {
  className: PropTypes.string,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  initialValue: PropTypes.array.isRequired,
};

SelectMonthPickerQuery.defaultProps = {
  className: '',
};

export default withRouter(SelectMonthPickerQuery);
