import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Modal, Input } from 'antd'
import { usersApi } from '../../../api/users'

const { TextArea } = Input

const SuspendUserConfirmationModal = ({
	visible,
	setVisible,
	user,
	history,
}) => {
	const [reasonValue, setReasonValue] = useState('')
	const [loading, setLoading] = useState(false)

	const handleSuspend = async () => {
		setLoading(true)
		const res =
			user.status === 'active'
				? await usersApi.suspend(user.id, {
						reason: reasonValue,
				  })
				: await usersApi.unsuspend(user.id, {
						reason: reasonValue,
				  })

		if (!res) return

		Modal.success({
			title: 'Success',
			content: `Account has been ${
				user.status === 'active' ? 'Suspended' : 'Unsuspend'
			} successfully`,
			onOk: () => {
				history.push('/user-management')
				setReasonValue('')
				setVisible(false)
			},
		})

		setLoading(false)
	}

	return (
		<Modal
			loading={loading}
			destroyOnClose
			onOk={handleSuspend}
			title={
				<span style={{ fontWeight: 700 }}>
					{user.status === 'active' ? 'Suspend' : 'Unsuspend'} User
				</span>
			}
			visible={visible}
			onCancel={() => {
				setVisible(false)
				setReasonValue('')
			}}
			okText={user.status === 'active' ? 'Suspend' : 'Unsuspend'}
			okButtonProps={{ disabled: !reasonValue }}
			cancelText="Cancel"
			centered>
			<p style={{ fontSize: 16, textAlign: 'center' }}>
				Are you sure want to{' '}
				{user.status === 'active' ? 'suspend' : 'unsuspend'} this user?
			</p>
			<p style={{ fontSize: 14 }}>Reason</p>
			<TextArea
				rows={4}
				value={reasonValue}
				onChange={(e) => setReasonValue(e.target.value)}
			/>
			<p style={{ fontSize: 14 }}>Reason required</p>
		</Modal>
	)
}

export default withRouter(SuspendUserConfirmationModal)
