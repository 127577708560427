import { MENU } from 'config/menu';
import React, { useEffect, useState } from 'react';
import { AcquisitionReviewApi } from 'api/acquisition-review';
import { useParams } from 'react-router-dom';
import { Button, Col, Icon, Row, Tag } from 'antd';
import Layout from 'layout/main';
import { AllText, Card, GoogleMapDisplay, ImageViewer } from 'components';
import ListTableView from 'components/Atoms/ListTableView';
import Breadcrumb from 'components/breadcrumb';
import { CONTENT_API_URL } from 'config/url';
import { TAG_COLOR } from 'config/acquisition';
import { toTitleCase, trimSlashes } from 'utils/formatter/string';
import moment from 'moment';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import HistoryLogs from './HistoryLogs';

const { LabelWithBackground } = AllText;

function VisitAssignmentLeadsDetail() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const sendError = useRollbarNotifier();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await AcquisitionReviewApi.getDetail(params.id);
        setData(res.data);
        setLoading(false);
      } catch (error) {
        sendError(`Error when fetch VisitAssignmentLeadsDetail`, error);
        setLoading(false);
      }
    };

    fetchData();
  }, [params.id]);

  return (
    <Layout title="Visit Assignment (Leads)">
      <Breadcrumb
        data={[
          {
            link: `${MENU.VISIT_ASSIGNMENT}/leads`,
            title: 'Visit Assignment',
          },
          {
            link: null,
            title: <b>Detail Visitation</b>,
          },
        ]}
      />
      <div className="app-content__details">
        <Card loading={loading} bordered={false} className="card">
          <div className="flex gap-6">
            <h2 className="font-bold text-2xl mt-6 mb-4">Leads Detail</h2>
            <Button onClick={() => {}} className="border-cf-gray" size="default" type="">
              <div className="flex gap-4 items-center justify-center w-full font-bold">
                <Icon type="exclamation-circle" /> Report Leads
              </div>
            </Button>
          </div>
          <Row type="flex" gutter={[32, 32]}>
            <Col xs={24} md={8} lg={8}>
              <ListTableView
                loading={false}
                data={{
                  'User ID ': <Tag>{data?.ref_id || '-'}</Tag>,
                  'Status ': (
                    <Tag color={TAG_COLOR[data?.status]} className="mt-2 pt-0.5">
                      {toTitleCase(data?.status || '-')}
                    </Tag>
                  ),
                  'Nama Toko ': data?.business_name || '-',
                  'Nama Pemilik Toko ': toTitleCase(data?.name) || '-',
                  'Jenis Toko ': data?.business_type || '-',
                  'No Handphone ': data?.phone || '-',
                  'Leads Created Date ': moment(data?.created_date_utc).format('DD MMM YYYY hh:mm a'),
                  'Updated ': moment(data?.updated_date_utc).format('DD MMM YYYY hh:mm a'),
                  'Leads Created by ': <Tag>{data?.created_by}</Tag>,
                  'Potensi Transfer Bulanan ': data?.potential_monthly_transfer,
                  'Potensi Recharge Bulanan ': data?.potential_monthly_recharge,
                }}
              />
            </Col>
            <Col xs={24} md={8} lg={8}>
              <ListTableView
                loading={false}
                data={{
                  'Location ': data?.location || '-',
                }}
              />
              <GoogleMapDisplay width="100%" height="254" latitude={data?.latitude} longitude={data?.longitude} />
            </Col>
            <Col xs={24} md={8} lg={8}>
              <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
                <div>
                  <LabelWithBackground>Foto Toko</LabelWithBackground>
                  <div style={{ zoom: 0.6 }}>
                    {(data?.business_image_key || data?.business_image) && (
                      <ImageViewer
                        switchable
                        imageKey={1}
                        src={
                          data?.business_image_key
                            ? `${trimSlashes(CONTENT_API_URL)}/${data?.business_image || ''}`
                            : `https://content.payfazz.com/object/${data?.business_image || ''}`
                        }
                      />
                    )}
                  </div>
                </div>
                <div>
                  <LabelWithBackground>Foto Pemilik Toko</LabelWithBackground>
                  <div style={{ zoom: 0.6 }}>
                    {(data?.owner_image_key || data?.owner_image) && (
                      <ImageViewer
                        switchable
                        imageKey={1}
                        src={
                          data?.owner_image_key
                            ? `${trimSlashes(CONTENT_API_URL)}/${data?.owner_image || ''}`
                            : `https://content.payfazz.com/object/${data?.owner_image || ''}`
                        }
                      />
                    )}
                  </div>
                </div>
                <div>
                  <LabelWithBackground>Foto Dengan Pemilik Toko</LabelWithBackground>
                  <div style={{ zoom: 0.6 }}>
                    {(data?.owner_business_image_key || data?.owner_business_image) && (
                      <ImageViewer
                        switchable
                        imageKey={1}
                        src={
                          data?.owner_business_image_key
                            ? `${trimSlashes(CONTENT_API_URL)}/${data?.owner_business_image || ''}`
                            : `https://content.payfazz.com/object/${data?.owner_business_image || ''}`
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Card>

        <HistoryLogs />
      </div>
    </Layout>
  );
}

export default VisitAssignmentLeadsDetail;
