import React from 'react';
import moment from 'moment';
import { capitalize } from 'utils/formatter/string';
import { VISITATION_STATUS_OPTIONS } from 'config/visit-assignment';
import Tag from 'components/Tag/v2';
import ViewReportButton from './ViewReportButton';
import EditAssignmentButton from './EditAssignmentButton';
import DeleteAssignmentButton from './DeleteAssignmentButton';

export const columns = (reload) => [
  {
    title: <p className="font-bold text-cf-gray">Assignment Date</p>,
    dataIndex: 'assignment_date',
    key: 'assignment_date',
    width: 200,
    render: (text) => moment(text).format('DD MMMM YYYY (HH:mm)'),
  },
  {
    title: <p className="font-bold text-cf-gray">Visitation Date</p>,
    dataIndex: 'visitation_date',
    key: 'visitation_date',
    width: 200,
    render: (text) => (text ? moment(text).format('DD MMMM YYYY (HH:mm)') : '-'),
  },
  {
    title: <p className="font-bold text-cf-gray">Visitor</p>,
    dataIndex: 'visitor_name',
    key: 'visitor_name',
    render: (text) => capitalize(text) || '-',
  },
  {
    title: <p className="font-bold text-cf-gray">Notes</p>,
    dataIndex: 'assignment_note',
    key: 'assignment_note',
    render: (text) => capitalize(text) || '-',
  },
  {
    title: <p className="font-bold text-cf-gray">Visitation Status</p>,
    dataIndex: 'visitation_status',
    key: 'visitation_status',
    align: 'center',
    width: 100,
    render: (code) => {
      const status = VISITATION_STATUS_OPTIONS.find((opt) => opt.key === code);
      return (
        <Tag key={code} bgColor={status?.bgColor} textColor={status?.textColor}>
          {status.label}
        </Tag>
      );
    },
  },
  {
    title: <p className="font-bold text-cf-gray">Action</p>,
    dataIndex: 'updated_by',
    key: 'updated_by',
    align: 'right',
    render: (_, row) => (
      <div className="flex gap-4 w-full justify-end">
        <ViewReportButton data={row} />
        <EditAssignmentButton onSuccess={reload} initialValues={row} />
        <DeleteAssignmentButton assignmentId={row.assignment_id} onSuccess={reload} />
      </div>
    ),
  },
];

export default { columns };
