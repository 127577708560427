import React from 'react'
import { Form, Select, Input, Row, Col } from 'antd'
import SearchSelect from 'components/search-select'
import { positionsApi } from 'api/positions'
import { STATUS_COLORS } from 'config/colors'
import { getUniqueObjArrayBy } from 'utils/array'

const PositionFormItem = ({
	withTitle = true,
	preventEditWithInitValue = false,
	editMode,
	data,
	getFieldDecorator,
	setFields,
	initValue,
	size = 'default',
	isPositionRequired = false,
	onChangePosition = () => {},
}) => {
	const isLargeSize = size === 'large'

	const onSelectStatus = async () => {
		// if (editMode) return

		setFields({
			position: { value: { key: '', label: '' } },
		})
	}

	const searchPositionsByKeyword = async (keyword) => {
		const resp = await positionsApi.listPositionsForCreateUser({
			status: data.position_status,
			search: keyword,
		})

		const searchResult = getUniqueObjArrayBy(
			resp?.data?.data || [],
			'position_id',
		)

		return searchResult.map((dt) => ({
			...dt,
			text: (
				<Row key={dt.id} type="flex" justify="space-between">
					<Col>
						<Row type="flex">
							<div
								style={{
									maxWidth: '120px',
									whiteSpace: 'wrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
								}}>
								{dt.position_label}
							</div>
						</Row>
					</Col>
					<Col
						className="small-text"
						style={{
							color: `${
								dt.position_status_label === 'Available'
									? 'green'
									: STATUS_COLORS.red
							}`,
						}}>
						{dt.position_status_label}
					</Col>
				</Row>
			),
			label: dt.position_label,
			value: dt.position_id,
		}))
	}

	return (
		<div>
			{withTitle && (
				<h2
					className={
						isLargeSize ? 'text-cf-14 mb-2 mt-4 cf-lightGray' : 'mb-2'
					}>
					Position
				</h2>
			)}
			<Form.Item style={{ marginBottom: 0 }}>
				<Form.Item label="Status" className="inline-block w-1/4">
					{getFieldDecorator('position_status', {
						initialValue: 'all',
						rules: [],
					})(
						<Select
							disabled={preventEditWithInitValue && !!initValue?.key}
							style={{ width: '100%' }}
							onChange={onSelectStatus}
							size={size}>
							<Select.Option value="all">All Status</Select.Option>
							<Select.Option value="available">Available</Select.Option>
							<Select.Option value="filled">Filled</Select.Option>
						</Select>,
					)}
				</Form.Item>

				{editMode && !data.position_status ? (
					<Form.Item label="Position Label" className="inline-block w-3/4 pl-3">
						<Input size={size} disabled value={data.position_label} />
					</Form.Item>
				) : (
					<Form.Item label="Position Label" className="inline-block w-3/4 pl-3">
						{getFieldDecorator('position', {
							initialValue: initValue?.key ? { ...initValue } : undefined,
							rules: isPositionRequired
								? [{ required: true, message: 'Please Select Position Label' }]
								: [],
						})(
							<SearchSelect
								allowClear
								disabled={preventEditWithInitValue && !!initValue?.key}
								wordLimit={3}
								optionLabelProp="label"
								api={searchPositionsByKeyword}
								placeholder="Search"
								isEffect
								effect={data.position_status}
								size={size}
								onChange={onChangePosition}
							/>,
						)}
					</Form.Item>
				)}
			</Form.Item>
		</div>
	)
}

export default PositionFormItem
