import moment from 'moment'
import { capitalize } from '../../../utils/formatter/string'

export const columns = () => [
	{
		title: 'Timestamp',
		dataIndex: 'updated_date_utc',
		key: 'updated_date_utc',
		width: 200,
		render: (text) => moment(text).format('DD MMMM YYYY (HH:mm)'),
	},
	{
		title: 'Action',
		dataIndex: 'action',
		key: 'action',
		// width: '60%',
		render: (text) => capitalize(text) || '-',
	},
	{
		title: 'Description',
		dataIndex: 'reason',
		key: 'reason',
		// width: '60%',
		render: (text) => capitalize(text) || '-',
	},
	{
		title: 'Updated by',
		dataIndex: 'updated_by',
		key: 'updated_by',
		render: (text) => capitalize(text) || '-',
	},
]
