import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import useMediaQuery from 'customHooks/useMediaQuery';
import { toTitleCase } from 'utils/formatter/string';
import { visitationReportApi } from 'api/reports';

import SummaryCard from '../shared/SummaryCard';

const initialData = {
  engagement_count: 0,
  sales_code: '-',
  sales_name: '-',
  sales_phone: '-',
  visitation_count: 0,
};

function SummarySection({ query }) {
  const [summary, setSummary] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const sendError = useRollbarNotifier();

  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    if (!query.start_filter || !query.end_filter || !query.k_sales_id) return;
    const fetchData = async () => {
      const payload = {
        sales_id: query.k_sales_id,
        start_date: query.start_filter,
        end_date: query.end_filter,
        acquisition_type: query.type || '',
      };
      try {
        setLoading(true);
        const res = await visitationReportApi.getSummary(payload);
        setSummary(res.data);

        setLoading(false);
      } catch (error) {
        setSummary(initialData);
        setLoading(false);
        sendError('Error fetching summary visitation report', error);
      }
    };
    fetchData();
  }, [query]);

  return (
    <SummaryCard loading={loading}>
      <SummaryCard.Col>
        <p className="font-bold text-cf-16 mb-2 text-cf-gray">{summary?.sales_name}</p>
        <p className="text-cf-12 mb-2">{summary?.sales_code.toUpperCase()}</p>
        <p className="text-cf-12">{toTitleCase(summary?.sales_phone)}</p>
      </SummaryCard.Col>
      <SummaryCard.Col>
        <p className={`text-cf-12 mb-3 ${isMobile ? '' : 'text-center'}`}>Check-in Visitation</p>
        <p className={`font-bold ${isMobile ? '' : 'text-center'} text-6xl text-cf-gray`}>{summary?.visitation_count}</p>
      </SummaryCard.Col>
      <SummaryCard.Col>
        <p className={`text-cf-12 mb-3 ${isMobile ? '' : 'text-center'}`}>Online Engagement</p>
        <p className={`font-bold ${isMobile ? '' : 'text-center'} text-6xl text-cf-gray`}>{summary?.engagement_count}</p>
      </SummaryCard.Col>
    </SummaryCard>
  );
}

SummarySection.propTypes = {
  query: PropTypes.object.isRequired,
};

export default SummarySection;
