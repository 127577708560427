import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import { Spin } from 'antd';

import { AccountApi } from 'api/account';
import PermissionTree from './PermissionTree';

function PermissionTrees({ selectedPermissionIds, onChange }) {
  const [dashboardTreeData, setDashboardTreeData] = useState({ treeData: [], checkedKeys: [] });
  const [mobileTreeData, setMobileTreeData] = useState({ treeData: [], checkedKeys: [] });
  const [loading, setLoading] = useState(false);
  const sendError = useRollbarNotifier();

  const generateTreeData = (rawData) =>
    rawData.map((menu) => ({
      key: `parentKey___${menu.menu}`,
      title: menu.menu,
      isParent: true,
      children: menu.permission_group.map((role) => ({
        key: role.id,
        title: role.action,
        isParent: false,
      })),
    }));

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      try {
        setLoading(true);
        const { data } = await AccountApi.getPermissions();
        if (!isCancelled) {
          setDashboardTreeData({
            ...dashboardTreeData,
            treeData: generateTreeData(data?.data?.dashboard || []),
          });
          setMobileTreeData({
            ...mobileTreeData,
            treeData: generateTreeData(data?.data?.mobile || []),
          });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        sendError(`Error when fetch permissions in Permission Trees component`, error);
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <Spin tip="Loading..." spinning={loading}>
      <PermissionTree
        treeData={dashboardTreeData.treeData}
        checkedKeys={selectedPermissionIds.dashboard}
        title="Dashboard Permission"
        onChange={(keys) => onChange({ dashboard: keys })}
      />
      <PermissionTree
        treeData={mobileTreeData.treeData}
        checkedKeys={selectedPermissionIds.mobile}
        title="Mobile Permission"
        onChange={(keys) => onChange({ mobile: keys })}
      />
    </Spin>
  );
}

PermissionTrees.propTypes = {
  selectedPermissionIds: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PermissionTrees;
