import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider as RollbarProvider, ErrorBoundary, LEVEL_WARN } from '@rollbar/react';
import { rollbar } from 'config/rollbar';

import FallbackError from 'components/FallbackError';
import PrivateRoute from './private-router';
import Login from '../pages/login';
import Page404 from '../pages/page-404';
import { PUBLIC_URL } from '../config/url';
import { privateRoutes } from './routes';

function RouteConfig() {
  return (
    <Router basename={PUBLIC_URL}>
      <RollbarProvider instance={rollbar}>
        <ErrorBoundary level={LEVEL_WARN} fallbackUI={FallbackError}>
          <div>
            <Switch>
              <Route exact path="/" component={Login} />
              {privateRoutes.map(
                (privateRoute) =>
                  privateRoute.config !== false && (
                    <PrivateRoute
                      exact
                      key={privateRoute.path}
                      path={privateRoute.path}
                      component={privateRoute.component}
                      accessType={privateRoute.accessType}
                      redirectPath={privateRoute.redirectPath}
                      pageEvent={privateRoute.pageEvent}
                    />
                  ),
              )}
              <Route component={Page404} />
            </Switch>
          </div>
        </ErrorBoundary>
      </RollbarProvider>
    </Router>
  );
}

export default RouteConfig;
