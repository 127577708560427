import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { withRouter } from 'react-router-dom';
import { generateUrlQueryParams, parseUrlQueryParams } from 'utils/query';

import Layout from '../main';

function TabsPage({ match, history, location, pageTitle, urlPush, tabs, preserveQueryParams }) {
  const tabFromPath = match.params.tab || tabs[0]?.key;
  const selectedTab = tabs.find((tab) => tab.key === tabFromPath) || {};

  const onTabChange = (key) => {
    history.push({
      pathname: `${urlPush}/${key}`,
      search: preserveQueryParams ? generateUrlQueryParams(parseUrlQueryParams(location.search)) : undefined,
    });
  };

  return (
    <Layout title={pageTitle}>
      <Tabs className="custom__tabs" activeKey={tabFromPath} onChange={onTabChange}>
        {tabs.map((tab) => (
          <Tabs.TabPane key={tab.key} tab={tab.title} />
        ))}
      </Tabs>
      <div className="app-content --tabs">{selectedTab.content}</div>
    </Layout>
  );
}

TabsPage.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  urlPush: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    }),
  ).isRequired,
  preserveQueryParams: PropTypes.bool,
};

TabsPage.defaultProps = {
  preserveQueryParams: false,
};

export default withRouter(TabsPage);
