import {
  METHODS,
  // API_URIS,
  API_BASE_URL,
  apiCall,
} from './config';

export const visitAssignmentApi = {
  leadList: (data) => apiCall(METHODS.GET, API_BASE_URL, '/v1/assignments/leads', data),
  assigneeList: (data) => apiCall(METHODS.GET, API_BASE_URL, '/v1/assignments/visitors', data),
  createAssignment: (data) =>
    apiCall(METHODS.POST, API_BASE_URL, '/v1/assignments', {
      ...data,
      acquisition_id: data.acquisition_id, // uuid of lead
      assignment_date: data.assignment_date, // string (ex: 2024-05-01)
      note: data.note,
      visitor_id: data.visitor_id,
    }),
  updateAssignee: (data) =>
    apiCall(METHODS.POST, API_BASE_URL, `/v1/assignments/${data.assignment_id}`, {
      ...data,
      assignment_id: data.assignment_id,
      assignment_date: data.assignment_date, // string (ex: 2024-05-01)
      note: data.note,
      visitor_id: data.visitor_id,
    }),
  deleteAssignee: (assignmentId) =>
    apiCall(METHODS.POST, API_BASE_URL, `/v1/assignments/${assignmentId}/delete`, {
      assignment_id: assignmentId,
    }),
  historyListByLeads: (acquisitionId, data) => apiCall(METHODS.GET, API_BASE_URL, `/v1/assignments/leads/${acquisitionId}/history`, data),
  historyListByVisitors: (visitorId, data) => apiCall(METHODS.GET, API_BASE_URL, `/v1/assignments/visitors/${visitorId}/history`, data),
  detailAssignmentByVisitor: (visitorId) => apiCall(METHODS.GET, API_BASE_URL, `/v1/assignments/visitors/${visitorId}`),
  getVisitorList: (data) =>
    apiCall(METHODS.GET, API_BASE_URL, `/v1/positions/subordinates`, {
      search: data.search || '',
      per_page: data.per_page || 20,
      page: data.page || 1,
    }),
  recentAssignmentByLeads: (acquisitionId) => apiCall(METHODS.GET, API_BASE_URL, `/v1/assignments/lead/${acquisitionId}`),
};

export default visitAssignmentApi;
