import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon, Select, Spin, Empty } from 'antd';
import { generateUrlQueryParams, parseUrlQueryParams } from 'utils/query';
import { capitalize } from 'utils/formatter/string';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import { debounce } from 'utils/others';
import './style.css';

const { Option } = Select;

function SearchQueryByApi({ fieldName = 'filter', location, history, className, getOptions, placeholder }) {
  const [renderOptions, setRenderOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const query = parseUrlQueryParams(location.search);
  const sendError = useRollbarNotifier();

  const updateSearchQuery = (val) => {
    history.push({
      search: generateUrlQueryParams({
        ...parseUrlQueryParams(location.search),
        page: 1,
        [`k_${fieldName}`]: val?.key || '',
        [`l_${fieldName}`]: val?.label || '',
      }),
    });
  };

  const fetchData = async (val) => {
    if (!val) {
      setRenderOptions([]);
      setLoading(false);
      return;
    }
    try {
      setLoading(true);
      const newOptions = await getOptions(val);
      setRenderOptions(newOptions || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      sendError(`Error when fetch SearchQueryByApi list options`, error);
    }
  };

  const handleSearch = debounce(fetchData, 1000);

  return (
    <div className={`${className || ''}`}>
      <Select
        labelInValue
        allowClear
        showSearch
        style={{ height: 48, width: '100%' }}
        className={`selectSearchQuery text-cf-14 ${className || ''}`}
        size="large"
        suffix={<Icon type="search" />}
        defaultActiveFirstOption={false}
        value={query[`k_${fieldName}`] ? { key: query[`k_${fieldName}`] || '', label: query[`l_${fieldName}`] } : undefined}
        onChange={updateSearchQuery}
        loading={loading}
        notFoundContent={loading ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Type to search..." />}
        onSearch={handleSearch}
        filterOption={false}
        placeholder={placeholder}
      >
        {!loading &&
          renderOptions.map((opt) => (
            <Option key={opt.key} value={opt.key}>
              {capitalize(opt.label)}
            </Option>
          ))}
      </Select>
    </div>
  );
}

SearchQueryByApi.propTypes = {
  fieldName: PropTypes.string,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getOptions: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

SearchQueryByApi.defaultProps = {
  fieldName: 'key',
  className: '',
  placeholder: 'Type to search',
};

export default withRouter(SearchQueryByApi);
