export function serializeCamelCase(str) {
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, (newStr) => {
    return newStr.toUpperCase();
  });
}

export const capitalize = (string = '') => string.charAt(0).toUpperCase() + string.slice(1);

export function toTitleCase(str) {
  if (!str) {
    return '';
  }

  return String(str)
    .replaceAll('_', ' ')
    .replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

export const stringToInteger = (str) => {
  return str
    .split('')
    .map((i, ind) => str.charCodeAt(ind))
    .join('');
};

export function nFormatter(num) {
  if (num >= 1000000000) {
    return `${(num / 1000000000).toFixed(1).replace(/\.0$/, '')}T`;
  }
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;
  }
  return num;
}

/**
 * Removes leading and trailing slashes from a given string.
 *
 * @param {string} str - The string from which to remove leading and trailing slashes.
 * @returns {string} - The resulting string after removing leading and trailing slashes.
 *
 * @example
 * trimSlashes('/example/');
 * // Returns: 'example'
 */

export const trimSlashes = (str) => {
  return str.replace(/^\/+|\/+$/g, '');
};
