import { METHODS, apiCall, API_BASE_URL } from './config'

export const AccountApi = {
	get: (data = {}) => apiCall(METHODS.GET, API_BASE_URL, 'v1/roles', data),
	create: (data) => apiCall(METHODS.POST, API_BASE_URL, `v1/roles`, data),
	edit: (data) =>
		apiCall(METHODS.POST, API_BASE_URL, `v1/${data.role_id}/update`, data),
	getPermissions: (data) =>
		apiCall(METHODS.GET, API_BASE_URL, `v1/permissions`, data),
	getDetail: (data) =>
		apiCall(METHODS.GET, API_BASE_URL, `v1/roles/${data.role_id}`),
	getHistory: (data) =>
		apiCall(METHODS.GET, API_BASE_URL, `v1/event/roles`, {
			merchant_id: data.merchant_id,
			role_id: data.role_id,
			page: data.page || 1,
			per_page: data.per_page || 10,
		}),
	delete: (data) => {
		return apiCall(
			METHODS.POST,
			API_BASE_URL,
			`v1/roles/${data.role_id}/delete`, {
				merchant_id: data.merchant_id,
			}
		)
	},
	// getApiList: (data) =>
	// 	apiCall(METHODS.GET, API_URIS.ACCOUNT, `v1/roles/${data.role_id}`),
	// update: (data) =>
	// 	apiCall(
	// 		METHODS.POST,
	// 		API_URIS.ACCOUNT,
	// 		`v1/roles/${data.role_id}`,
	// 		data,
	// 	),
	// getDetail: (data) =>
	// 	apiCall(METHODS.GET, API_URIS.ACCOUNT, `v1/roles/${data.role_id}`),
}
