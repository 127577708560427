import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Modal, Select } from 'antd'
import { Button, ListTableView } from 'components'
import { ArrowDown } from 'assets'
import { MerchantApi } from 'api/merchant'
import ReasonModal from './reason-change-owner'

const ChangeOwner = ({ visible, close, merchant, form, onSuccess }) => {
	const [loading, setLoading] = useState(false)
	const [ownerList, setOwnerList] = useState([])
	const [newOwner, setNewOwner] = useState(null)

	const [showReasonModal, setShowReasonModal] = useState(false)
	const { getFieldDecorator } = form

	const onClose = () => {
		setNewOwner(null)
		setLoading(false)
		close()
	}

	const goToReason = (e) => {
		e.preventDefault()
		form.validateFields(async (err, values) => {
			if (err) return
			setNewOwner(values.new_owner_id.key)
			setShowReasonModal(true)
			close()
		})
	}

	useEffect(() => {
		if (!visible) return
		const fetchOwners = async () => {
			try {
				setLoading(true)
				const res = await MerchantApi.getOwnerListByMerchant(merchant)
				setOwnerList([...res.data.data])
				setLoading(false)
			} catch (error) {
				console.error(error)
				setLoading(false)
			}
		}

		fetchOwners()
	}, [merchant, visible])

	return (
		<>
			<Modal
				width={520}
				destroyOnClose
				maskClosable={false}
				title={<p className="font-bold text-cf-16">Change Owner</p>}
				footer={null}
				visible={visible}
				onCancel={close}>
				<Form className="master-field__form" onSubmit={goToReason}>
					<div className="border p-5">
						<p className="font-bold text-cf-16 mb-2">Owner Information</p>
						<ListTableView
							// loading={loading}
							data={{
								Email: merchant.owner_email || '-',
								'First Name': merchant.owner_first_name || '-',
								'Last Name': merchant.owner_last_name || '-',
								'Phone Number': merchant.owner_phone || '-',
							}}
						/>
					</div>

					<ArrowDown className="flex justify-center m-5" />
					<div className="border p-5">
						<p className="text-cf-16 font-bold mb-5">New Owner</p>
						<Form.Item label="Search Email, Name, Phone Number">
							{getFieldDecorator('new_owner_id', {
								rules: [
									{ required: true, message: 'Please input Merchant Name' },
								],
							})(
								<Select
									loading={loading}
									labelInValue
									placeholder="Email, Name, Phone Number"
									className="h-48px"
									autoFocus
									size="large"
									onSearch={() => {}}
									showSearch
									notFoundContent={
										<span className="text-red-600">
											No merchant owner is availabe this time...
										</span>
									}
									filterOption={(input, option) =>
										option?.props?.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}>
									{ownerList?.map((owner) => (
										<Select.Option key={owner.id} value={owner.id}>
											{`${owner.name}-${owner.email}`}
										</Select.Option>
									))}
								</Select>,
							)}
						</Form.Item>
					</div>
					<div className="flex mt-5">
						<Button className="mr-3 ml-auto" onClick={onClose}>
							Cancel
						</Button>
						<Button type="primary" htmlType="submit" loading={loading}>
							Change Owner
						</Button>
					</div>
				</Form>
			</Modal>
			<ReasonModal
				visible={showReasonModal}
				close={() => setShowReasonModal(false)}
				merchant={merchant}
				newOwner={newOwner}
				onSuccess={onSuccess}
			/>
		</>
	)
}

const WrappedDetail = Form.create({
	name: 'changeOwner',
})(ChangeOwner)

export default withRouter(WrappedDetail)
