import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import Layout from 'layout/main';
import { Table, Button, InputSearchQuery, SelectSearchQuery } from 'components';
// import Filter from 'components/filter'
import { USER_STATUSES } from 'config/users';
import { MENU } from 'config/menu';
import CreateUserForm from './create';
import useUserListPage from './state';
import { userColumns } from './columns';

function UserListPage({ location }) {
  const userListPageURL = MENU.USER_MANAGEMENT;
  const { users, totalUsers, roles, isLoading, showCreateUserModal, setShowCreateUserModal, submitCreateUser } = useUserListPage({
    location,
  });
  const history = useHistory();

  const viewDetailAction = (id, record) => {
    history.push(`${MENU.USER_MANAGEMENT}/${record.id}`);
  };

  return (
    <Layout title="User Management">
      <div className="app-content">
        <div className="flex align-center justify-start mb-10 mt-4">
          <InputSearchQuery className="md:w-328px md:mr-5 md:mb-0 mb-5" />
          <SelectSearchQuery
            className="md:w-328px w-full md:mr-5 md:mb-0 mb-2"
            options={USER_STATUSES}
            fieldName="status"
            allText="All Status"
          />
          <SelectSearchQuery
            className="md:w-328px w-full md:mr-5 md:mb-0 mb-2"
            options={[{ key: '', label: 'All roles' }].concat((roles || []).map((r) => ({ key: r.name, label: r.name })))}
            fieldName="role"
          />
          <Button
            className="md:w-328px w-full md:mr-0 md:mb-0 md:ml-auto"
            style={{ width: 180 }}
            type="primary"
            onClick={() => setShowCreateUserModal(true)}
            icon="plus-square"
          >
            <span className="ml-2">Create New User</span>
          </Button>
        </div>
        <Table
          loading={isLoading}
          columns={userColumns(viewDetailAction)}
          dataSource={users || []}
          count={totalUsers || 0}
          urlPush={userListPageURL}
          rowKey="id"
        />

        <Modal visible={showCreateUserModal} title="Create New User" onCancel={() => setShowCreateUserModal(false)} footer={null}>
          <CreateUserForm roles={roles} onSubmit={submitCreateUser} setVisible={setShowCreateUserModal} isLoading={isLoading} />
        </Modal>
      </div>
    </Layout>
  );
}

export default withRouter(UserListPage);
