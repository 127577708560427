import React, { useState } from 'react';
import { useRollbar } from '@rollbar/react';
import { Select, Spin } from 'antd';
import { GeoApi } from 'api/geo';

function SearchVillage({ ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const [villages, setVillages] = useState([]);
  const rollbar = useRollbar();

  const onSearchVillage = async (search) => {
    if (search.length > 2) {
      try {
        setIsLoading(true);
        const res = await GeoApi.searchVillage(search);
        setVillages(res.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        rollbar.error(`Error when search village on edit acquisition detail page`, error);
      }
    }
  };

  return (
    <Select
      labelInValue
      placeholder="Type to search location"
      showSearch
      filterOption={false}
      onSearch={onSearchVillage}
      notFoundContent={isLoading ? <Spin size="small" /> : 'Data not Found, type another keyword'}
      {...props}
    >
      {villages.map((item) => (
        <Select.Option key={item.code} value={item.code}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
}

export default SearchVillage;
