import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { notification } from 'antd';

import { Table, SelectRangePickerQuery } from 'components';
import Layout from 'layout/main';
import useGAEvents from 'customHooks/useGAEvents';

import { getQueryStringForPagination, generateUrlQueryParams, parseUrlQueryParams } from 'utils/query';

import { reportsNewApi } from 'api/reports';
import { MENU } from 'config/menu';
import { columns } from './columns';

const PER_PAGE = 10;
const DATE_FORMAT = 'YYYY-MM-DD';
function ReportList({ history, location }) {
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState([]);
  const [metadata, setMetadata] = useState({});
  const sendGAEvents = useGAEvents();

  const query = useMemo(() => getQueryStringForPagination(location.search), [location.search]);

  const onClickDownload = async ({ id, name }) => {
    const { start_filter: startFilter, end_filter: endFilter } = query;
    const res = await reportsNewApi.downloadOtherReport(id, {
      start_filter: startFilter || '',
      end_filter: endFilter || '',
    });
    if (res) {
      const blob = new Blob([res.data], { type: 'text/csv' });
      const csvURL = window.URL.createObjectURL(blob);
      const element = document.createElement('a');
      element.href = csvURL;
      element.setAttribute('download', `${name} - ${startFilter.replace('-', '')}_${endFilter.replace('-', '')}.csv`);
      element.click();
      notification.success({
        message: 'Success',
        description: `Download ${name} Report success.`,
      });

      sendGAEvents.downloadOtherReport();
    }
  };

  useEffect(() => {
    // Force push to yesterday
    const { start_filter: startFilter, end_filter: endFilter } = query;
    if (!startFilter && !endFilter) {
      setIsFetching(true);
      history.push({
        search: generateUrlQueryParams({
          ...parseUrlQueryParams(location.search),
          start_filter: moment().subtract(1, 'days').format(DATE_FORMAT),
          end_filter: moment().subtract(1, 'days').format(DATE_FORMAT),
        }),
      });
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await reportsNewApi.get({
          per_page: PER_PAGE,
          ...query,
        });
        setData([...res.data.data]);
        setMetadata(res.data.metadata);
        setLoading(false);
        setIsFetching(false);
      } catch (error) {
        setLoading(false);
        setIsFetching(false);
      }
    };

    if (!isFetching) fetchData();
  }, [query]);

  return (
    <Layout title="Report">
      <div className="app-content">
        <div className="mb-10 mt-4">
          <SelectRangePickerQuery
            className="md:w-328px w-full"
            // Can not select days after today
            disabledDate={(curr) => curr && curr > moment().endOf('day')}
            ranges={{
              Today: [moment(), moment()],
              'This Week': [moment().startOf('week'), moment().endOf('week')],
              'This Month': [moment().startOf('month'), moment().endOf('month')],
              'This Year': [moment().startOf('year'), moment().endOf('year')],
            }}
            initialValue={[moment(), moment()]}
          />
        </div>
        <Table
          loading={loading}
          columns={columns(onClickDownload)}
          rowKey="id"
          dataSource={[...data]}
          count={metadata.total_count}
          history={history}
          location={location}
          urlPush={MENU.OTHER_REPORTS}
        />
      </div>
    </Layout>
  );
}

ReportList.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(ReportList);
