import { METHODS, API_URIS, apiCall } from './config'

import { Geofazz } from './config/geofazz'
import GeofazzBuilder from './config/geofazz.builder'

export const GeoApi = {
	getHierarchy: (provinceCodes, regencyCodes, districtCodes, villageCodes) => {
		const query = new GeofazzBuilder()
			.setProvinces(Geofazz.CODES, provinceCodes, ['code', 'name'])
			.setRegencies(Geofazz.IN, regencyCodes, ['code', 'name'])
			.setDistricts(Geofazz.IN, districtCodes, ['code', 'name'])
			.setVillages(Geofazz.IN, villageCodes, ['code', 'name'])
			.build()
		return apiCall(METHODS.POST, API_URIS.GEO, '', { query })
	},
	getHierarchyNoVillages: (provinceCodes, regencyCodes, districtCodes) => {
		const query = new GeofazzBuilder()
			.setProvinces(Geofazz.CODES, provinceCodes, ['code', 'name'])
			.setRegencies(Geofazz.IN, regencyCodes, ['code', 'name'])
			.setDistricts(Geofazz.IN, districtCodes, ['code', 'name'])
			.build()
		return apiCall(METHODS.POST, API_URIS.GEO, '', { query })
	},
	getHierarchyToRegencies: (provinceCodes, regencyCodes) => {
		const query = new GeofazzBuilder()
			.setProvinces(Geofazz.CODES, provinceCodes, ['code', 'name'])
			.setRegencies(Geofazz.IN, regencyCodes, ['code', 'name'])
			.build()
		return apiCall(METHODS.POST, API_URIS.GEO, '', { query })
	},
	getHierarchyFromVillage: (codes) => {
		const query = new GeofazzBuilder()
			.setVillages(Geofazz.CODES, codes, ['code', 'name'])
			.setDistrict(Geofazz.NONE, [], ['code', 'name'])
			.setRegency(Geofazz.NONE, [], ['code', 'name'])
			.setProvince(Geofazz.NONE, [], ['code', 'name'])
			.build()
		return apiCall(METHODS.POST, API_URIS.GEO, '', { query })
	},
	getAllProvinces: () => {
		const query = new GeofazzBuilder()
			.setProvinces(Geofazz.NONE, [], ['code', 'name'])
			.setRegencies(Geofazz.NONE, [], ['code', 'name'])
			.build()
		return apiCall(METHODS.POST, API_URIS.GEO, '', { query })
	},
	getHierarchyFromDistrict: (codes) => {
		const query = new GeofazzBuilder()
			.setDistricts(Geofazz.CODES, codes, ['code', 'name'])
			.setRegency(Geofazz.NONE, [], ['code', 'name'])
			.setProvince(Geofazz.NONE, [], ['code', 'name'])
			.build()
		return apiCall(METHODS.POST, API_URIS.GEO, '', { query })
	},
	getHierarchyFromRegency: (codes) => {
		const query = new GeofazzBuilder()
			.setRegencies(Geofazz.CODES, codes, ['code', 'name'])
			.setProvince(Geofazz.NONE, [], ['code', 'name'])
			.build()
		return apiCall(METHODS.POST, API_URIS.GEO, '', { query })
	},
	getProvinces: (locs) => {
		const query = new GeofazzBuilder()
			.setProvinces(Geofazz.CODES, locs, ['code', 'name'])
			.build()
		return apiCall(METHODS.POST, API_URIS.GEO, '', { query })
	},
	getRegenciesByProvince: (locs) => {
		const query = new GeofazzBuilder()
			.setProvinces(Geofazz.CODES, locs, ['code', 'name'])
			.setRegencies(Geofazz.NONE, [], ['code', 'name'])
			.build()
		return apiCall(METHODS.POST, API_URIS.GEO, '', { query })
	},
	getRegencies: (locs) => {
		const query = new GeofazzBuilder()
			.setRegencies(Geofazz.CODES, locs, ['code', 'name'])
			.build()
		return apiCall(METHODS.POST, API_URIS.GEO, '', { query })
	},
	getDistrictsByProvince: (locs) => {
		const query = new GeofazzBuilder()
			.setProvinces(Geofazz.CODES, locs, ['code', 'name'])
			.setRegencies(Geofazz.NONE, [], ['code', 'name'])
			.setDistricts(Geofazz.NONE, [], ['code', 'name'])
			.build()
		return apiCall(METHODS.POST, API_URIS.GEO, '', { query })
	},
	getDistrictsByRegency: (locs) => {
		const query = new GeofazzBuilder()
			.setRegencies(Geofazz.CODES, locs, ['code', 'name'])
			.setDistricts(Geofazz.NONE, [], ['code', 'name'])
			.build()
		return apiCall(METHODS.POST, API_URIS.GEO, '', { query })
	},
	getVillagesByDistrict: (locs) => {
		const query = new GeofazzBuilder()
			.setDistricts(Geofazz.CODES, locs, ['code', 'name'])
			.setVillages(Geofazz.NONE, [], ['code', 'name'])
			.build()
		return apiCall(METHODS.POST, API_URIS.GEO, '', { query })
	},
	searchVillage: (name) => {
		return apiCall(METHODS.POST, API_URIS.GEO, '/search', {
			'search_name': name,
			'search_scope': 'village'
		})
	},
}
