import React from 'react'

function FallbackImage({ width, height }) {
	return (
		<div
			style={{ width: +width, height: +height, margin: '20px 0px' }}
			className="bg-cf-lightGray-4 flex justify-center items-center text-cf-lightGray-2">
			No Image
		</div>
	)
}

export default FallbackImage
