export function getUniqueObjArrayBy(array, key) {
  if (!Array.isArray(array)) return array;

  const uniqueArr = array.map((o) => o[key]);
  const filtered = array.filter((item, index) => !uniqueArr.includes(item[key], index + 1));
  return filtered;
}

export function flattenArray(array, childKey = 'children') {
  const level = [];
  array.forEach((item) => {
    level.push({ id: item.id, title: item.title, parent: item.parent });
    if (item[childKey]) level.push(...flattenArray(item[childKey]));
  });
  return level;
}

export function filterNull(x) {
  return !!x;
}

export const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    // eslint-disable-next-line no-param-reassign
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    return result;
  }, {});
};
