import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import { Button } from 'components';
import { toTitleCase } from 'utils/formatter/string';
import { TableTitle } from 'components/table';

function DownloadButton({ handleDownload, record }) {
  const [loading, setLoading] = useState(false);
  const sendError = useRollbarNotifier();

  const onClick = async () => {
    try {
      setLoading(true);
      await handleDownload(record);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      sendError(`Error when download report`, error);
    }
  };

  return (
    <Button size="small" loading={loading} onClick={onClick} type="default">
      Download
    </Button>
  );
}

DownloadButton.propTypes = {
  handleDownload: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
};

export const columns = (handleDownload) => [
  {
    title: <TableTitle className="px-4">Report Name</TableTitle>,
    dataIndex: 'name',
    key: 'name',
    width: 400,
    render: (text) => <p className="px-4">{toTitleCase(text)}</p>,
  },
  {
    title: <TableTitle>Action</TableTitle>,
    dataIndex: '',
    key: '',
    render: (_, record) => (
      <DownloadButton record={record} handleDownload={handleDownload}>
        Download
      </DownloadButton>
    ),
  },
];

export default { columns };
