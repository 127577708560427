import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';

import { nFormatter } from 'utils/formatter/string';
import CustomizedLabel from './CustomizedLabel';
import DefaultLegend from './DefaultLegend';
import EmptyChart from '../EmptyChart';

export default function AreaComps({ data, lines: areas, xLabel, yLabel, loading, dataKey, legendOptions }) {
  const { visible: showLegend, component: legendComponent } = legendOptions;
  if (loading) {
    return (
      <div className="flex h-96 justify-center items-center">
        <Spin tip={<p className="mt-2">Loading...</p>} />;
      </div>
    );
  }

  if (!data || data.length === 0) {
    return <EmptyChart aspect={5.0 / 3.0} />;
  }

  return (
    <ResponsiveContainer width="100%" aspect={5.0 / 3.0}>
      <AreaChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 15 }}>
        <CartesianGrid vertical={false} stroke="#E0E0E0" />
        <XAxis
          tick={{ fill: '#1A1A1A' }}
          stroke="#E0E0E0"
          dataKey={dataKey}
          label={{ value: xLabel, position: 'bottom' }}
          padding={{ left: 10, right: 10 }}
        />
        <YAxis
          tick={{ fill: '#1A1A1A' }}
          stroke="#E0E0E0"
          type="number"
          domain={['auto', 'auto']}
          label={{
            value: yLabel,
            fill: '#757575',
            angle: -90,
            position: 'left',
            textAnchor: 'middle',
          }}
          tickFormatter={(value) => nFormatter(value)}
          interval="preserveStartEnd"
        />
        <Tooltip />
        {showLegend && <Legend content={legendComponent || DefaultLegend} />}
        {areas.map((i) => (
          <Area
            key={i.dataKey}
            stackId={1}
            type={i.type}
            dataKey={i.dataKey}
            stroke={i.stroke}
            fillOpacity={0.5}
            fill={i.fill}
            activeDot={{ r: 8 }}
            dot={{
              stroke: i.fill,
              fill: i.fill,
              strokeWidth: 1,
              r: 3,
              strokeDasharray: '',
              stackId: 1,
            }}
          >
            {i.withLabelList && <LabelList content={<CustomizedLabel />} />}
          </Area>
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
}

AreaComps.propTypes = {
  data: PropTypes.array,
  yLabel: PropTypes.string.isRequired,
  xLabel: PropTypes.string.isRequired,
  lines: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  dataKey: PropTypes.string.isRequired,
  legendOptions: PropTypes.object,
};

AreaComps.defaultProps = {
  legendOptions: null,
  data: [],
};
