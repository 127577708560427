/* eslint-disable */
import React from 'react';
import { Form, Input } from 'antd';
import { Button } from 'components';
import AreaFormItem from '../shared/area-form-item';

class CreatePositionAreaForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTerritories: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      values.territories = values.search_area_territories;
      this.props.onSubmit(values);
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item label="Area Name">
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'Please input the position area name',
              },
            ],
          })(<Input placeholder="Positon Area Name" />)}
        </Form.Item>

        <AreaFormItem form={this.props.form} withoutRole />

        <Form.Item>
          <div className="flex">
            <Button className="mr-2 ml-auto" onClick={() => this.props.setVisible(false)}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={this.props.isLoading}>
              Create New Area
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  }
}

const CreatePositionArea = Form.create({ name: 'normal_login' })(CreatePositionAreaForm);

export default CreatePositionArea;
