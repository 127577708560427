import React, { useCallback, useState } from 'react'
import { Input, Icon } from 'antd'
import { withRouter } from 'react-router-dom'
import qs from 'qs'
import { getQueryStringForPagination, parseUrlQueryParams } from 'utils/query'

import { debounce } from 'utils/others'

const InputSearchQuery = ({
	placeholder = 'Search',
	className = '',
	style = {},
	history,
	location,
}) => {
	const initialSearchValue = parseUrlQueryParams(location.search).search
	const [searchValue, setSearch] = useState(initialSearchValue)

	const searchByQuery = (searchText = '', queryParams) => {
		setSearch(searchText)
		const newQuery = {
			...getQueryStringForPagination(queryParams),
			...(searchText && { search: searchText }),
			page: 1,
		}
		if (!searchText) delete newQuery.search
		history.push(`${location.pathname}?${qs.stringify(newQuery)}`)
	}

	const debounceSearchByQuery = useCallback(debounce(searchByQuery, 1000), [])

	const changeKeyword = (searchText) => {
		setSearch(searchText)
		debounceSearchByQuery(searchText, location.search)
	}

	return (
		<div className={`w-full ${className || ''}`}>
			<Input
				allowClear
				size="large"
				placeholder={placeholder}
				className="w-full h-48px text-cf-14"
				style={{ ...style }}
				suffix={<Icon type="search" />}
				value={searchValue}
				onChange={(e) => changeKeyword(e.target.value)}
			/>
		</div>
	)
}

export default withRouter(InputSearchQuery)
