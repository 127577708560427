import React, { useState, useEffect, useMemo } from 'react'
import { withRouter } from 'react-router-dom'

import { Table, Button, InputSearchQuery } from 'components'
import Layout from 'layout/main'

import MENU from 'config/menu'
import { getQueryStringForPagination } from 'utils/query'
import { MerchantApi } from 'api/merchant'
import { IcPlus } from 'assets'

import CreateForm from '../modals/edit-form'
import { columns } from './columns'

const PER_PAGE = 10
const MerchantManagement = ({ history, location }) => {
	const [loading, setLoading] = useState(false)
	const [data, setData] = useState([])
	const [metadata, setMetadata] = useState({})
	const [showCreateFormModal, setCreateFormModal] = useState(false)

	const query = useMemo(() => getQueryStringForPagination(location.search), [
		location.search,
	])

	const goToDetailPage = (roleId) => {
		history.push(`${MENU.MERCHANT_MANAGEMENT_LIST}/${roleId}`)
	}

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)
			const { data } = await MerchantApi.get({
				per_page: PER_PAGE,
				...query,
			})
			setData([...data.data])
			setMetadata(data.metadata)
			setLoading(false)
		}

		fetchData()
	}, [query])

	return (
		<Layout title="Merchant Management">
			<div className="app-content">
				<div className="flex justify-between mb-10 mt-4">
					<InputSearchQuery className="md:w-328px md:mr-5 md:mb-0 mb-5" />
					<Button
						className="md:w-328px w-full md:mr-0 md:mb-0"
						style={{ minWidth: 194 }}
						type="primary"
						onClick={() => setCreateFormModal(true)}>
						<IcPlus />
						Create New Merchant
					</Button>
				</div>
				<Table
					loading={loading}
					columns={columns(goToDetailPage)}
					rowKey="id"
					dataSource={data}
					count={metadata.total_count}
					history={history}
					location={location}
					urlPush={MENU.MERCHANT_MANAGEMENT_LIST}
				/>
			</div>
			<CreateForm
				visible={showCreateFormModal}
				close={() => setCreateFormModal(false)}
			/>
		</Layout>
	)
}

export default withRouter(MerchantManagement)
