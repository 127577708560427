export const MENU = {
  LOGIN: '/',
  DASHBOARD: '/dashboard',
  PROFILE: '/profile',

  ACQUISITION_REVIEW: '/acquisition-review',
  MERCHANT_MANAGEMENT_LIST: '/merchant-management',
  ACCOUNT_MANAGEMENT: '/account-management',
  USER_MANAGEMENT: '/user-management',
  POSITION_MANAGEMENT: '/position-management',
  AREA_PRESET: 'area-presets',
  OTHER_REPORTS: '/other-reports',
  VISITATION_REPORTS: '/visitation-reports',
  SALES_FUNNEL: '/sales-funnel',
  KPI_VISUALIZATION: '/kpi-visualization',
  VISIT_ASSIGNMENT: '/visit-assignment',

  PROFILE_KEY: 'profile',
  ACQUISITION_REVIEW_KEY: 'acquisition-review',
  MERCHANT_MANAGEMENT_LIST_KEY: 'merchant-management',
  ACCOUNT_MANAGEMENT_KEY: 'account-management',
  USER_MANAGEMENT_KEY: 'user-management',
  POSITION_MANAGEMENT_KEY: 'position-management',
  OTHER_REPORTS_KEY: 'other-reports',
  VISITATION_REPORTS_KEY: 'visitation-reports',
  SALES_FUNNEL_KEY: 'sales-funnel',
  KPI_VISUALIZATION_KEY: 'kpi-visualization',
  VISIT_ASSIGNMENT_KEY: 'visit-assignment',
};

export default MENU;
