import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { Checkbox, Input, Row, Col, Card, notification, Modal, Form, Tooltip, Icon } from 'antd';
import Layout from 'layout/main';
import { Breadcrumb, Button } from 'components';
import { MENU } from 'config/menu';

import { AccountApi } from 'api/account';
import { getCookies } from 'utils/cookies';
import { CONFIG_COOKIES } from 'config/cookies';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';

import PermissionTrees from './PermissionTrees';
import './create.scss';

const { TextArea } = Input;
const DESC_MAX_LENGTH = 300;

function CreateAccountManagement({ match, form, history }) {
  const [loading, setLoading] = useState(false);
  const [selectedPermissionIds, setSelectedPermissionIds] = useState({ dashboard: [], mobile: [] });
  const sendError = useRollbarNotifier();
  const { getFieldDecorator } = form;

  // Edit Mode
  const roleId = useMemo(() => match.params.id, [match.params.id]);
  const isEditMode = !!roleId;

  const handleSubmit = async (values) => {
    const callApi = isEditMode ? AccountApi.edit : AccountApi.create;

    const payload = {
      merchant_id: getCookies(CONFIG_COOKIES.MERCHANT_ID),
      role_id: roleId,
      is_admin: values.is_admin,
      area_restricted: values.area_restricted,
      name: values.name,
      description: values.description,
      permission_ids: [...selectedPermissionIds.dashboard, ...selectedPermissionIds.mobile].filter(
        (id) => !id.toString().includes('parentKey___'),
      ),
    };

    try {
      setLoading(true);
      await callApi(payload);
      if (isEditMode) {
        setLoading(true);
        history.push(`${MENU.ACCOUNT_MANAGEMENT}/role/${roleId}`);
        notification.success({
          message: 'Success',
          description: 'Role information has been updated',
        });
      } else if (!isEditMode) {
        setLoading(true);
        history.push(MENU.ACCOUNT_MANAGEMENT);
        notification.success({
          message: 'Success',
          description: 'Create New Role Success',
        });
      }
    } catch (error) {
      setLoading(false);
      sendError(`Error when ${isEditMode ? 'editing' : 'creating new'} role`, error);
    }
  };

  const onClickSubmit = () => {
    form.validateFields(async (err, values) => {
      if (err) return;

      Modal.confirm({
        title: 'Do you want to proceed submit?',
        onOk: () => handleSubmit(values),
      });
    });
  };

  useEffect(() => {
    if (!isEditMode) return;
    const fetchRoleDetail = async () => {
      setLoading(true);
      const { data } = await AccountApi.getDetail({ role_id: roleId });

      const initialPermissionIdsDashboard =
        data?.role_permissions?.dashboard
          ?.map((p) => p.permission_group)
          .reduce((prev, curr) => [...prev, ...curr], [])
          .map((dt) => dt.id) || [];

      const initialPermissionIdsMobile =
        data?.role_permissions?.mobile
          ?.map((p) => p.permission_group)
          .reduce((prev, curr) => [...prev, ...curr], [])
          .map((dt) => dt.id) || [];

      setSelectedPermissionIds({
        dashboard: initialPermissionIdsDashboard,
        mobile: initialPermissionIdsMobile,
      });

      form.setFieldsValue({
        name: data.role.name,
        description: data.role.description,
        is_admin: data.role.is_admin,
        area_restricted: data.role.area_restricted,
      });

      setLoading(false);
    };

    fetchRoleDetail();
  }, []);

  return (
    <Layout title="Role Management">
      <Breadcrumb
        data={[
          { link: MENU.ACCOUNT_MANAGEMENT, title: 'Role Management' },
          { link: null, title: 'Create Account' },
        ]}
      />
      <h2 style={{ marginLeft: 20, marginTop: 20, marginBottom: 0 }}>{isEditMode ? 'Edit Role' : 'Create New Role'}</h2>
      <Card style={{ margin: 20 }} bodyStyle={{ paddingBottom: 40 }} loading={loading}>
        <Row gutter={48}>
          <Col span={8}>
            <Form>
              <Form.Item label="Role Name">
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'Please input role name' }],
                })(<Input placeholder="Role" />)}
              </Form.Item>
              <Form.Item label="Description">
                {getFieldDecorator('description', {
                  rules: [
                    { required: true, message: 'Please input description' },
                    { max: DESC_MAX_LENGTH, message: `Reached max. character of ${DESC_MAX_LENGTH}` },
                  ],
                })(<TextArea placeholder="Description" maxLength={DESC_MAX_LENGTH} />)}
              </Form.Item>
              <p className="-mt-6 text-right">
                {form.getFieldValue('description') ? form.getFieldValue('description').length : 0}/{DESC_MAX_LENGTH}
              </p>

              <Form.Item>
                {getFieldDecorator('is_admin', { valuePropName: 'checked' })(
                  <Checkbox>
                    <div className="inline-flex items-center">
                      Is Admin
                      <Tooltip placement="topLeft" title="The admin will grant access to all features">
                        <Icon type="question-circle" className="pl-2" />
                      </Tooltip>
                    </div>
                  </Checkbox>,
                )}
              </Form.Item>
              <Form.Item style={{ marginTop: -20 }}>
                {getFieldDecorator('area_restricted', { valuePropName: 'checked' })(
                  <Checkbox className="-mt-3">
                    <div className="inline-flex items-center">
                      Area Restricted
                      <Tooltip placement="topLeft" title="Is Area Restricted?">
                        <Icon type="question-circle" className="pl-2" />
                      </Tooltip>
                    </div>
                  </Checkbox>,
                )}
              </Form.Item>
              <br />
            </Form>
          </Col>
          <Col span={16}>
            <PermissionTrees
              selectedPermissionIds={selectedPermissionIds}
              onChange={(newValuesObj) => {
                setSelectedPermissionIds((prev) => ({
                  ...prev,
                  ...newValuesObj,
                }));
              }}
            />
          </Col>
        </Row>
      </Card>

      <Card style={{ margin: 20, marginTop: -40 }} bodyStyle={{ padding: 12 }} className="p-0">
        <div className="flex">
          <Button type="primary" className="ml-auto mr-3" onClick={onClickSubmit} loading={loading}>
            <span>{isEditMode ? 'Save Changes' : 'Create New Role'}</span>
          </Button>
          <Button onClick={() => history.push(MENU.ACCOUNT_MANAGEMENT)}>
            <span>Cancel</span>
          </Button>
        </div>
      </Card>
    </Layout>
  );
}

CreateAccountManagement.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

const WrappedForm = Form.create()(CreateAccountManagement);

export default withRouter(WrappedForm);
