/* eslint-disable class-methods-use-this */
class GeofazzCommon {
	CODES = 'codes'

	IN = 'in'

	EXCEPT = 'except'

	NONE = ''

	/**
	 *
	 * @param snapshot      {string}
	 *
	 * @return {string}
	 */
	buildScope(snapshot) {
		return `{${snapshot}}`
	}

	/**
	 *
	 * @param codes     {array}
	 *
	 * @return {string}
	 */
	mapCodeToString(codes = []) {
		return codes.map((code) => `"${code}"`)
	}

	/**
	 *
	 * @param returns   {array}
	 *
	 * @return {string}
	 */
	mapReturnsToString(returns = []) {
		return returns.toString().replace(',', ' ')
	}
}

export const Geofazz = Object.freeze(new GeofazzCommon())
