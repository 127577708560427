/* eslint-disable no-param-reassign */
export const debounce = (callback, time = 250, interval) => (...args) => {
	clearTimeout(interval)
	interval = setTimeout(() => {
		interval = null
		callback(...args)
	}, time)
}

export const removeDuplicateArrayOfObjects = (arr, field = 'name') =>
	arr.filter((v, i, a) => a.findIndex((t) => t[field] === v[field]) === i)
