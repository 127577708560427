/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState } from 'react'
import { Modal, Icon, Col, Button } from 'antd'

import './ImageViewer.scss'

const ImageViewer = ({ src, imageKey }) => {
	const [visible, setVisible] = useState(false)
	const [imageLoading, setImageLoading] = useState(true)
	const [style, setStyle] = useState({
		rotation: 0,
		dragging: false,
		scaleX: 1,
		scaleY: 1,
		xoff: 0,
		yoff: 0,
		transition: 0,
		start: {
			x: 0,
			y: 0,
		},
	})
	const [displayedImageStyle, setDisplayedImageStyle] = useState({
		rotation: 0,
		transition: 0,
		scale: 0,
	})

	const onClose = () => {
		setVisible(false)
		setTimeout(() => {
			setStyle({
				rotation: 0,
				dragging: false,
				scaleX: 1,
				scaleY: 1,
				xoff: 0,
				yoff: 0,
				transition: 0.3,
				start: {
					x: 0,
					y: 0,
				},
			})
		}, 300)
	}

	const onMouseUp = (e) => {
		e.preventDefault()
		setStyle({
			...style,
			dragging: false,
		})
	}

	const onMouseDown = (e) => {
		e.preventDefault()
		setStyle({
			...style,
			start: { x: e.clientX - style.xoff, y: e.clientY - style.yoff },
			dragging: true,
			transition: 0,
		})
	}

	const onMouseMove = (e) => {
		e.preventDefault()
		if (style.dragging) {
			setStyle({
				...style,
				xoff: e.clientX - style.start.x,
				yoff: e.clientY - style.start.y,
			})
		}
	}

	const onTouchStart = (e) => {
		// e.preventDefault();
		setStyle({
			...style,
			start: {
				x: e.touches[0].clientX - style.xoff,
				y: e.touches[0].clientY - style.yoff,
			},
			dragging: true,
			transition: 0,
		})
	}

	const onTouchMove = (e) => {
		// e.preventDefault();
		if (style.dragging) {
			setStyle({
				...style,
				xoff: e.touches[0].clientX - style.start.x,
				yoff: e.touches[0].clientY - style.start.y,
			})
		}
	}

	const getDisplayedImageScale = (degrees) => {
		const newDegree = degrees + 90
		const container = document.querySelectorAll('.displayed-image')[0]
		const image = document.querySelectorAll(`.displayed-image-${imageKey}`)[0]
		const offsetAngle = Math.atan(image.offsetWidth / container.offsetHeight)
		const originalHeight = container.offsetHeight
		const originalFactor = Math.sqrt(
			container.offsetHeight ** 2 + image.offsetWidth ** 2,
		)

		const radians = (newDegree * Math.PI) / 180
		let sum = 0
		let scale = 1

		if (newDegree < 90) {
			sum = radians - offsetAngle
		} else if (newDegree < 180) {
			sum = radians + offsetAngle
		} else if (newDegree < 270) {
			sum = radians - offsetAngle
		} else {
			sum = radians + offsetAngle
		}
		scale = originalHeight / Math.cos(sum) / originalFactor
		scale = Math.abs(scale) > 1 ? 1 : Math.abs(scale)

		setDisplayedImageStyle({
			...displayedImageStyle,
			rotation: newDegree,
			transition: 0.3,
			scale,
		})
	}

	const rotateLeft = () => {
		setStyle({
			...style,
			rotation: style.rotation - 90,
			transition: 0.3,
		})
	}

	const rotateRight = () => {
		setStyle({
			...style,
			rotation: style.rotation + 90,
			transition: 0.3,
		})
	}

	const flipHorizontal = () => {
		setStyle({
			...style,
			scaleX: style.scaleX * -1,
			transition: 0.3,
		})
	}

	const flipVertical = () => {
		setStyle({
			...style,
			scaleY: style.scaleY * -1,
			transition: 0.3,
		})
	}

	const onWheel = (e) => {
		// take the scale into account with the offset
		const delta = e.wheelDelta ? e.wheelDelta : -e.deltaY
		// get scroll direction & set zoom level
		delta > 0
			? setStyle({
					...style,
					scaleX: style.scaleX * 1.05,
					scaleY: style.scaleY * 1.05,
					transition: 0,
			  })
			: setStyle({
					...style,
					scaleX: style.scaleX / 1.05,
					scaleY: style.scaleY / 1.05,
					transition: 0,
			  })
	}

	const zoomIn = () => {
		setStyle({
			...style,
			scaleX: style.scaleX * 1.05,
			scaleY: style.scaleY * 1.05,
		})
	}

	const zoomOut = () => {
		setStyle({
			...style,
			scaleX: style.scaleX / 1.05,
			scaleY: style.scaleY / 1.05,
		})
	}

	const openImageViewer = () => {
		setDisplayedImageStyle({
			rotation: 0,
			transition: 0,
			scale: 0,
		})
		setVisible(true)
	}

	return (
		<div className="image-viewer">
			<Col>
				<div className="displayed-image">
					<Button
						className="rotate-button"
						onClick={() =>
							getDisplayedImageScale(displayedImageStyle.rotation, imageKey)
						}>
						<Icon type="redo" />
					</Button>
					<img
						style={{
							transition: `${
								displayedImageStyle.transition > 0
									? displayedImageStyle.transition
									: 0
							}s`,
							transform:
								displayedImageStyle.rotation === 0
									? 'none'
									: `${`scale(${displayedImageStyle.scale}) rotate(${displayedImageStyle.rotation}deg)`}`,
							display: imageLoading ? 'none' : 'block',
						}}
						className={`displayed-image__image displayed-image-${imageKey}`}
						onLoad={() => setImageLoading(false)}
						onClick={(event) => openImageViewer(event)}
						src={src}
						alt="displayed_image.jpg"
					/>
					<p
						style={{
							display: imageLoading ? 'block' : 'none',
							margin: 'auto',
							color: 'white',
							height: '300px',
							transform: 'translateY(50%)',
						}}>
						<Icon
							type="loading-3-quarters"
							style={{ color: 'white', fontSize: '20px', marginRight: '10px' }}
							spin
						/>
						Loading Image . .
					</p>
				</div>
			</Col>
			<Modal
				wrapClassName="image-viewer-popup"
				visible={visible}
				onOk={() => setVisible(false)}
				onCancel={(event) => onClose(event)}
				footer={[
					<div key="toolbar-container" className="toolbar">
						<ul key="toolbar" className="image-viewer-toolbar">
							<li
								className="react-viewer-btn"
								key="zoomIn"
								onClick={(event) => zoomIn(event)}>
								<Icon type="zoom-in" />
							</li>
							<li
								className="react-viewer-btn"
								key="zoomOut"
								onClick={(event) => zoomOut(event)}>
								<Icon type="zoom-out" />
							</li>
							<li
								className="react-viewer-btn"
								key="rotateLeft"
								onClick={(event) => rotateLeft(event)}>
								<Icon type="undo" />
							</li>
							<li
								className="react-viewer-btn"
								key="rotateRight"
								onClick={(event) => rotateRight(event)}>
								<Icon type="redo" />
							</li>
							<li
								className="react-viewer-btn"
								key="flipHorizontal"
								onClick={(event) => flipHorizontal(event)}>
								<Icon type="column-width" />
							</li>
							<li
								className="react-viewer-btn"
								key="flipVertical"
								onClick={(event) => flipVertical(event)}>
								<Icon type="column-height" />
							</li>
						</ul>
					</div>,
				]}>
				<img
					style={{
						transition: `${style.transition > 0 ? style.transition : 0}s`,
						transform: `translate(${style.xoff}px, ${style.yoff}px) scaleX(${
							style.scaleX
						}) scaleY(${style.scaleY}) rotate(${
							displayedImageStyle.rotation > 0
								? displayedImageStyle.rotation
								: style.rotation
						}deg)`,
					}}
					className={`lightbox-image-${imageKey}`}
					onWheel={onWheel}
					onMouseDown={onMouseDown}
					onMouseMove={onMouseMove}
					onMouseUp={onMouseUp}
					onTouchStart={onTouchStart}
					onTouchMove={onTouchMove}
					src={src}
					alt="image_viewer.jpg"
				/>
			</Modal>
		</div>
	)
}

export default ImageViewer
