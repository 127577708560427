import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import { Card } from 'antd';
import qs from 'qs';
import { Table } from 'components';

import { visitAssignmentApi } from 'api/visit-assignment';
import { getQueryStringForPagination } from 'utils/query';
import { MENU } from 'config/menu';

import { columns } from './columns';

const PAGE_SIZE = 10;
function VisitationHistory({ location, history, lastUpdate }) {
  const [loading, setLoading] = useState(false);
  const [historyLogs, setHistoryLogs] = useState([]);
  const { visitorId } = useParams();

  const query = getQueryStringForPagination(location.search);
  const { historyPage } = query;
  const [totalData, setTotalData] = useState(null);
  const sendError = useRollbarNotifier();

  const onChangePage = (pageNum) => {
    const pageQuery = { ...query, historyPage: pageNum };
    if (pageNum === 1) delete pageQuery.historyPage;
    history.push(`${MENU.ACQUISITION_REVIEW}/${visitorId}?${qs.stringify(pageQuery)}`);
  };

  const onClickBusinessName = (row) => history.push(`${MENU.VISIT_ASSIGNMENT}/leads/${row?.acquisition_id}`);

  useEffect(() => {
    const fetchHistory = async () => {
      setLoading(true);
      try {
        const res = await visitAssignmentApi.historyListByVisitors(visitorId, {
          page: historyPage,
          per_page: PAGE_SIZE,
        });
        setHistoryLogs(res.data.data);
        setTotalData(res.data.count);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        sendError(`Error when fetch acquisition detail history list options`, error);
      }
    };
    fetchHistory();
  }, [historyPage, lastUpdate]);

  return (
    <>
      <div className="flex gap-4 items-center px-6 -mb-4 mt-8">
        <div className="rounded-full h-3 w-3 bg-cf-gray" />
        <h2 className="text-cf-gray text-3xl">Visitation History</h2>
      </div>
      <Card bordered={false} className="card lastEvent" bodyStyle={{ padding: '24px' }} style={{ marginBottom: 0 }}>
        <Table
          loading={loading}
          dataSource={historyLogs}
          columns={columns(onClickBusinessName)}
          rowKey="updated_date_utc"
          withoutPagination
          pagination={{
            current: +historyPage || 1,
            total: totalData,
            pageSize: PAGE_SIZE,
            onChange: onChangePage,
          }}
        />
      </Card>
    </>
  );
}

VisitationHistory.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  lastUpdate: PropTypes.string.isRequired,
};

export default withRouter(VisitationHistory);
