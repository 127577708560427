import { trimSlashes } from 'utils/formatter/string';
import { call } from './axios';

export const METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export const API_BASE_URL = `${trimSlashes(process.env.REACT_APP_API)}`;

export const API_URIS = {
  AUTH: `${process.env.REACT_APP_API}`,
  TASK: `${process.env.REACT_APP_API}/task`,
  GEO: process.env.REACT_APP_GEOFAZZ_API,
  CANFAZZ: process.env.REACT_APP_CANFAZZ_API,
  CMS: `${process.env.REACT_APP_API}/cms`,
  LEDGER: `${process.env.REACT_APP_API}/ledger`,
  CLIENT: `${process.env.REACT_APP_API}/client`,
  REPORT: `${process.env.REACT_APP_API}/report`,
  CHECK: `${process.env.REACT_APP_API}/check`,
  OTHER_REPORT: `${process.env.REACT_APP_API}/natsproc`,
};

export const apiCall = call;
