import React from 'react';
import { Card } from 'antd';
import Line from 'components/Chart/LineChart';
import useCharts from 'customHooks/useCharts';
import { kpiVisualizationApi } from 'api/reports';
import ChartTitle from '../../../shared/ChartTitle';

function TransactionAmountRepayment() {
  const { data, loading, startDate, endDate } = useCharts({ callApi: kpiVisualizationApi.getTransactionLoanRepayment });

  return (
    <Card className="rounded-2xl">
      <ChartTitle title="Amount of Loan Repayment" startDate={startDate} endDate={endDate} />
      <Line
        data={data}
        dataKey="period"
        loading={loading}
        xLabel="Repayment Date"
        yLabel="Repayment Amount"
        lines={[{ type: 'linear', dataKey: 'amount', stroke: '#DB5555', withLabelList: false }]}
        legendOptions={{ visible: true }}
      />
    </Card>
  );
}

export default TransactionAmountRepayment;
