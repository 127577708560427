import moment from 'moment'

export const getGreetingTime = () => {
	const splitMorning = 6
	const splitAfternoon = 11
	const splitEvening = 15
	const splitNight = 19
	const currentHour = parseFloat(moment().format('HH'))

	if (currentHour >= splitMorning && currentHour <= splitAfternoon) {
		return 'Selamat Pagi'
	}

	if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
		return 'Selamat Siang'
	}

	if (currentHour >= splitEvening && currentHour <= splitNight) {
		return 'Selamat Sore'
	}

	return 'Selamat Malam'
}
