import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Divider, Empty } from 'antd';

import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import { ListTableView, ImageViewer, FallbackImage } from 'components';
import { toTitleCase, trimSlashes } from 'utils/formatter/string';
import { groupBy } from 'utils/array';
import { secondsToHms } from 'utils/formatter/time';
import { visitationReportApi } from 'api/reports';
import { CONTENT_API_URL } from 'config/url';

const groupDataByDate = (data) => {
  const obj = groupBy(data, 'check_in_date');
  return Object.entries(obj).map(([date, children]) => ({ date, children }));
};

function ListSectionItem({ query }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const sendError = useRollbarNotifier();

  useEffect(() => {
    if (!query.start_filter || !query.end_filter || !query.k_sales_id) return;
    const fetchData = async () => {
      const payload = {
        sales_id: query.k_sales_id,
        start_date: query.start_filter,
        end_date: query.end_filter,
        acquisition_type: query.type || '',
      };
      try {
        setData([]);
        setLoading(true);
        const res = await visitationReportApi.get(payload);
        const newData = groupDataByDate(res?.data?.data || []);
        setData(newData);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        sendError('Error fetching data visitation report', error);
      }
    };
    fetchData();
  }, [query]);

  if (data?.length === 0)
    return (
      <Card loading={loading} className="mt-10">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Card>
    );

  return (
    <>
      {data.map((dt) => (
        <div className="mt-8" key={dt.date}>
          <p className="text-cf-gray font-bold text-cf-14 my-3 mx-1">{dt.date}</p>
          <Card>
            {dt.children.map((child, index) => {
              const isNotLast = dt.children.length - 1 !== index;
              return (
                <div key={child.id}>
                  <Row className="">
                    <Col span={24} lg={8}>
                      <ListTableView
                        loading={false}
                        data={{
                          Tipe: toTitleCase(child.visit_type),
                          'Nama Toko': toTitleCase(child.business_name),
                          'Kategory Visit': toTitleCase(child.visit_type),
                          Description: toTitleCase(child.business_name),
                          Duration: secondsToHms(child.duration_minutes * 60),
                        }}
                        className="text-cf-14 mb-4"
                      />
                    </Col>
                    <Col span={24} lg={16}>
                      <div className="flex">
                        <div className="mr-5">
                          <p className="-mb-2 md:pl-2 text-cf-14">Foto Toko</p>

                          {child.image_key || child.image_url ? (
                            <ImageViewer
                              src={
                                child.image_key
                                  ? `${trimSlashes(CONTENT_API_URL)}/${child.image_key}`
                                  : `https://content.payfazz.com/object/${child.image_key}`
                              }
                              imageKey={index}
                            />
                          ) : (
                            <FallbackImage height={300} width={300} />
                          )}
                        </div>
                        <div>
                          <p className="-mb-2 md:pl-2 text-cf-14">Foto Dengan Pemilik Toko</p>
                          {child.owner_image_key || child.owner_image_url ? (
                            <ImageViewer
                              src={
                                child.owner_image_key
                                  ? `${trimSlashes(CONTENT_API_URL)}/${child.owner_image_key}`
                                  : `https://content.payfazz.com/object/${child.owner_image_key}`
                              }
                              imageKey={index}
                            />
                          ) : (
                            <FallbackImage height={300} width={300} />
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {isNotLast && <Divider className="mb-3" />}
                </div>
              );
            })}
          </Card>
        </div>
      ))}
    </>
  );
}

ListSectionItem.propTypes = {
  query: PropTypes.object.isRequired,
};

export default ListSectionItem;
