import React, { useState, useEffect, useMemo } from 'react';

import { Form, Modal, Input, message, Row, Col, notification } from 'antd';
import UploadDrag from 'components/upload';
import { Button } from 'components';
import { MerchantApi } from 'api/merchant';
import { usersApi } from 'api/users';
import useMediaQuery from 'customHooks/useMediaQuery';
import { EMAIL_PATTERN, NAME_PATTERN, PHONE_PATTERN } from 'utils/string-pattern';

const EditForm = Form.create({ name: 'editMerchant' })(({ visible, close, merchant, form, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [imageList, setImageList] = useState([]);

  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [isOwnerExist, setIsOwnerExist] = useState(false);
  const [emailExtra, setEmailExtra] = useState(null);
  const isEditMode = useMemo(() => !!merchant, [merchant]);
  const { getFieldDecorator, setFields } = form;

  // responsive layout
  const isMobile = useMediaQuery('(max-width: 768px)');

  const onTypeEmail = () => {
    setEmailExtra(null);
    setIsEmailChecked(false);
    setIsOwnerExist(false);
    form.setFieldsValue({
      owner_phone: null,
      owner_last_name: null,
      owner_first_name: null,
    });
  };

  const validateEmail = async () => {
    const email = form.getFieldValue('owner_email');
    form.validateFields(['owner_email'], async (err) => {
      if (err) return;
      setLoading(true);
      try {
        setEmailExtra(null);
        const { data } = await usersApi.checkIsUserExist({ email });
        if (!data.is_found) {
          setIsEmailChecked(true);
          setEmailExtra(<span className="text-red-600">silahkan lengkapi data user</span>);
          return;
        }

        setIsOwnerExist(true);
        const ownerFirstName = data?.user?.name?.split(' ')[0];
        const ownerLastName = data?.user?.name?.split(' ').slice(1).join(' ');
        const ownerPhone = data.user.phone;

        form.setFieldsValue({
          owner_phone: ownerPhone,
          owner_first_name: ownerFirstName,
          owner_last_name: ownerLastName,
        });
      } catch (error) {
        console.error('error', error);
      } finally {
        setLoading(false);
      }
      setIsEmailChecked(true);
    });
  };

  const onClose = () => {
    setImageList([]);
    close();
  };

  const handleEdit = async (payload) => {
    try {
      setLoading(true);
      const res = await MerchantApi.update({
        ...payload,
        merchant_id: merchant?.id,
      });
      setLoading(false);
      if (!res) return;
      onClose();
      notification.success({
        message: 'Success',
        description: 'Merchant data has been updated',
      });
      onSuccess();
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCreate = async (payload) => {
    try {
      setLoading(true);
      const res = await MerchantApi.create({ ...payload });
      setLoading(false);
      if (!res) return;
      onClose();
      Modal.success({
        title: 'Success',
        content: `Merchant has been created`,
        onOk: () => window.location.reload(),
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const submit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (err) return;
      const payload = {
        name: values.name,
        logo: imageList.length > 0 ? imageList[0].url : '',
        owner_first_name: values.owner_first_name,
        owner_last_name: values.owner_last_name,
        owner_email: values.owner_email,
        owner_phone: values.owner_phone,
      };
      if (isEditMode) {
        await handleEdit(payload);
        return;
      }
      await handleCreate(payload);
      // return
    });
  };

  useEffect(() => {
    if (!visible) return;
    if (!merchant) return;
    if (!merchant?.logo) return;
    setImageList([
      {
        uid: '-1',
        name: merchant?.name,
        status: 'done',
        url: merchant?.logo,
      },
    ]);
  }, [merchant?.name, merchant?.logo, visible]);

  return (
    <Modal
      width={520}
      destroyOnClose
      maskClosable={false}
      title={isEditMode ? 'Edit Merchant' : 'Create Merchant'}
      footer={null}
      visible={visible}
      onCancel={onClose}
    >
      <Form className="master-field__form" onSubmit={submit}>
        <p style={{ fontSize: 16, fontWeight: 700, marginBottom: 12 }}>Merchant Information</p>
        <Form.Item label="Merchant Name">
          {getFieldDecorator('name', {
            rules: [
              { required: true, message: 'Please input Merchant Name' },
              {
                pattern: NAME_PATTERN,
                message: 'Please input valid merchant name',
              },
            ],
            initialValue: merchant?.name || null,
          })(<Input placeholder="Merchant Name" onChange={(e) => setFields({ name: e })} />)}
        </Form.Item>
        <Form.Item label="Merchant Logo">
          {getFieldDecorator(
            'logo',
            {},
          )(
            <div style={{ margin: '1em 0', position: 'relative' }}>
              <UploadDrag
                listType="picture"
                thumbUrl
                description="Max 10 files"
                fileFormats={['JPG', 'JPEG', 'PNG']}
                uploadedData={imageList}
                multipleUpload={(objectId) => setImageList([objectId])}
                beforeUpload={(file) => {
                  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
                  if (!isJpgOrPng) {
                    message.error('You can only upload JPG/JPEG/PNG file!');
                  }
                  return isJpgOrPng;
                }}
                onRemove={(file) => {
                  setImageList(imageList.filter((item) => item.uid !== file.uid));
                }}
              />
            </div>,
          )}
        </Form.Item>
        <p
          style={{
            fontSize: 16,
            fontWeight: 700,
            marginBottom: 12,
            marginTop: 30,
          }}
        >
          Account Owner Information
        </p>

        <Row gutter={12}>
          <Col span={isMobile ? 16 : 18}>
            <Form.Item label="Email" extra={emailExtra}>
              {getFieldDecorator('owner_email', {
                rules: [
                  { required: true, message: 'Please input Email' },
                  {
                    pattern: EMAIL_PATTERN,
                    message: 'Please input a valid Google email',
                  },
                ],
                initialValue: merchant?.owner_email || null,
              })(<Input disabled={isEditMode} onChange={onTypeEmail} placeholder="Email Address" />)}
            </Form.Item>
          </Col>
          <Col span={isMobile ? 8 : 6}>
            <Button
              disabled={isEditMode}
              loading={loading}
              className="md:mt-9 mt-12"
              style={{ width: '100%', height: 32 }}
              type="primary"
              size="small"
              onClick={validateEmail}
            >
              Check Email
            </Button>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col span={12}>
            <Form.Item label="First Name" hasFeedback={isOwnerExist}>
              {getFieldDecorator('owner_first_name', {
                rules: [
                  { required: true, message: 'Please input First Name' },
                  {
                    pattern: NAME_PATTERN,
                    message: 'Please input valid name',
                  },
                ],
                initialValue: merchant?.owner_first_name || null,
              })(<Input disabled={isEditMode || isOwnerExist} placeholder="First Name" />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Last Name" hasFeedback={isOwnerExist}>
              {getFieldDecorator('owner_last_name', {
                rules: [
                  { required: true, message: 'Please input Last Name' },
                  {
                    pattern: NAME_PATTERN,
                    message: 'Please input valid name',
                  },
                ],
                initialValue: merchant?.owner_last_name || null,
              })(<Input disabled={isEditMode || isOwnerExist} placeholder="Last Name" />)}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Phone Number" hasFeedback={isOwnerExist}>
          {getFieldDecorator('owner_phone', {
            rules: [
              {
                pattern: PHONE_PATTERN,
                message: 'Please input valid phone number',
              },
            ],
            initialValue: merchant?.owner_phone || null,
          })(<Input disabled={isEditMode || isOwnerExist} placeholder="081234567890" />)}
        </Form.Item>
        <Form.Item>
          <div className="flex w-full mt-4 justify-end">
            <Button size="small" style={{ marginRight: '0.5em' }} onClick={onClose}>
              Cancel
            </Button>
            <Button size="small" type="primary" htmlType="submit" loading={loading} disabled={isEditMode ? false : !isEmailChecked}>
              {isEditMode ? 'Save Changes' : 'Create'}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default EditForm;
