import React from 'react';
import { Row, Col } from 'antd';

import SummarySection from './SummarySection';
import VisitationCount from './charts/VisitationCount';
import AverageVisitationDistance from './charts/VisitationDistance';
import AverageVisitationDuration from './charts/VisitationDuration';
import CategorizedDailyVisitation from './charts/VisitationCategorized';
import FilterQuerySection from '../../shared/FilterQuerySection';

function KpiVisualizationVisitation() {
  return (
    <div>
      <FilterQuerySection />
      <SummarySection />
      <Row gutter={[16, 16]}>
        <Col span={24} lg={12}>
          <VisitationCount />
        </Col>
        <Col span={24} lg={12}>
          <CategorizedDailyVisitation />
        </Col>
        <Col span={24} lg={12}>
          <AverageVisitationDuration />
        </Col>
        <Col span={24} lg={12}>
          <AverageVisitationDistance />
        </Col>
      </Row>
    </div>
  );
}

export default KpiVisualizationVisitation;
